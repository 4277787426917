import {
  FluentProvider,
  teamsLightTheme,
  Theme,
} from "@fluentui/react-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  HashRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TeamsContextWrapper } from "./contexts/TeamsContext";
import { useState } from "react";
import { useTeamsContextStore } from "./store/teamsContextStore";
import { useStore } from "zustand";
import OrganizationService from "./services/organization";
import InitialSetup from "./pages/InitialSetup";
import { SetupSteps } from "./components/InitialSetupWizard/SetupSteps";
import Layout from "./components/Layout/Layout";
import { useInitialSetupOrganizationStore } from "./store/initialSetupOrganizationStore";
import Absences from "./pages/Absences";
import Attendance from "./pages/Attendance";
import EmployeesManagement from "./pages/EmployeesManagement";
import Reports from "./pages/Reports";
import Home from "./pages/Home";
import { useUserStore } from "./store/userStore";
import Error from "./pages/Error";
import SimpleLayout from "./components/SimpleLayout/SimpleLayout";
import ContactUs from "./pages/ContactUs";
import SettingsLayout from "./components/Settings/Layout";
import FullScreenSpinner from "./components/FullScreenSpinner";
import GeneralSettings from "./pages/Settings/General";
import PersonioSettings from "./pages/Settings/Integrations/Personio";
import MicrosoftGraphApiSettings from "./pages/Settings/Integrations/MicrosoftGraphApi";
import RolesAndPermissionsSettings from "./pages/Settings/RolesAndPermissions";
import ManageUsersSettings from "./pages/Settings/ManageUsers";
import OrganigramSettings from "./pages/Settings/Organigram";
import { useTranslation } from "react-i18next";
import Config from "./pages/Config";
import { ApiConsentModal } from "./components/AADConsent/ApiConsentModal";
import { InitialSSOFallbackConsentModal } from "./components/AADConsent/InitialSSOFallbackConsentModal";
import { AuthSuccess } from "./pages/AuthSuccess";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import helpers from "./helpers/helpers";

const queryClient = new QueryClient();
const noSSOConsentRoute = "/no-consent";
const configRoute = "/config";

const customLightTheme: Theme = {
  ...teamsLightTheme,
  colorCompoundBrandStroke: "#3AB3DB",
  colorBrandBackground: "#1877A2",
  colorBrandBackgroundHover: "#1b6183",
  colorCompoundBrandBackground: "#3AB3DB",
  colorCompoundBrandBackgroundHover: "#1a95c0",
  colorCompoundBrandBackgroundPressed: "#1877a2",
  colorCompoundBrandForeground1: "#3AB3DB",
  colorCompoundBrandForeground1Hover: "#1a95c0",
  colorCompoundBrandForeground1Pressed: "#1877a2",
};

export default function App() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const teamsContextStore = useStore(useTeamsContextStore);
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );
  const userStore = useStore(useUserStore);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [
    isInitialSSOFallbackConformationPopupVisible,
    setIsInitialSSOFallbackConformationPopupVisible,
  ] = useState<boolean>(false);
  const [
    isAPIConsentConformationPopupVisible,
    setIsAPIConsentConformationPopupVisible,
  ] = useState<boolean>(false);

  const checkOrganization = async () => {
    try {
      const result = await OrganizationService.checkOrganization();
      if ("status" in result && result?.status === "error") {
        setHasError(true);
        setErrorMessage(t("app.errors.notAllowed"));
      } else if ("organizationExists" in result) {
        initialSetupOrganizationStore.setSetupCompleted(
          result?.organizationExists
        );
        if ("hrSystem" in result) {
          initialSetupOrganizationStore.setHrSystem(result?.hrSystem);
        }
        const user = await OrganizationService.getCurrentUser(result?.hrSystem);
        userStore.setUser(user);
        setHasError(false);
        setErrorMessage("");
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(t("app.errors.general"));
    } finally {
      setLoading(false);
    }
  };

  if (hasError) return <Error message={errorMessage} />;

  const renderRoutes = () => (
    <Routes>
      <Route path={configRoute} element={<Config />} />
      <Route path="/auth-success" element={<AuthSuccess />} />
      <Route
        path={noSSOConsentRoute}
        element={<Error message="error" />}
      ></Route>
      <Route
        path="/*"
        element={
          <>
            {isInitialSSOFallbackConformationPopupVisible && (
              <InitialSSOFallbackConsentModal
                closeInitialSSOConsentModal={() => {
                  return setIsInitialSSOFallbackConformationPopupVisible(false);
                }}
                afterInitialSSOConsented={async () => {
                  setIsAPIConsentConformationPopupVisible(true);
                }}
              />
            )}
            {isAPIConsentConformationPopupVisible && (
              <ApiConsentModal
                closeApiConsentModal={() => {
                  return setIsAPIConsentConformationPopupVisible(false);
                }}
              />
            )}
            {loading ? (
              <FullScreenSpinner />
            ) : (
              <Routes>
                {!initialSetupOrganizationStore.setupCompleted ? (
                  <>
                    <Route
                      path="*"
                      element={<Navigate to={"/welcome"} />}
                    ></Route>
                    <Route path="/welcome" element={<InitialSetup />} />
                    <Route path="/setupSteps" element={<SetupSteps />} />
                  </>
                ) : (
                  <>
                    {userStore?.user?.Role &&
                    userStore?.user?.Role[0]?.Type === "builtIn" &&
                    userStore?.user?.Role[0]?.Id ? (
                      <>
                        <Route path="dashboard" element={<Layout />}>
                          {helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Attendances",
                            "Can Read"
                          ) ? (
                            <Route
                              path="attendanceOwnHr"
                              element={<Attendance />}
                            />
                          ) : null}
                          {helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Absences",
                            "Can Read"
                          ) ? (
                            <Route
                              path="absencesOwnHr"
                              element={<Absences />}
                            />
                          ) : null}
                          {helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Employees Management",
                            "Can Read"
                          ) ? (
                            <Route
                              path="employeesManagement"
                              element={<EmployeesManagement />}
                            />
                          ) : null}
                          {helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Reports",
                            "Can Read"
                          )
                            ? null
                            : null}
                          <Route path="reports" element={<Reports />} />
                        </Route>
                        <Route path="/" element={<Home />} />

                        <Route path="*" element={<Navigate to={"/"} />}></Route>
                        <Route path="menu" element={<SimpleLayout />}>
                          <Route path="contact-us" element={<ContactUs />} />
                        </Route>
                        <Route path="menu" element={<SimpleLayout />}>
                          <Route path="about" element={<About />} />
                        </Route>
                        <Route path="menu" element={<SimpleLayout />}>
                          <Route path="privacy" element={<PrivacyPolicy />} />
                        </Route>
                        <Route path="menu" element={<SimpleLayout />}>
                          <Route
                            path="termsofuse"
                            element={<TermsAndConditions />}
                          />
                        </Route>
                        {helpers.checkHasPermissions(
                          userStore?.user?.Permissions,
                          "Settings General",
                          "Can Read"
                        ) ? (
                          <Route path="menu" element={<SettingsLayout />}>
                            <Route
                              path="settings"
                              element={<GeneralSettings />}
                            />
                          </Route>
                        ) : null}
                        {helpers.checkHasPermissions(
                          userStore?.user?.Permissions,
                          "Settings Integrations",
                          "Can Read"
                        ) ? (
                          <>
                            <Route path="menu" element={<SettingsLayout />}>
                              <Route
                                path="integrations/personio"
                                element={<PersonioSettings />}
                              />
                            </Route>
                            <Route path="menu" element={<SettingsLayout />}>
                              <Route
                                path="integrations/microsoft"
                                element={<MicrosoftGraphApiSettings />}
                              />
                            </Route>
                          </>
                        ) : null}
                        {helpers.checkHasPermissions(
                          userStore?.user?.Permissions,
                          "Roles and Permissions",
                          "Can Read"
                        ) ? (
                          <Route path="menu" element={<SettingsLayout />}>
                            <Route
                              path="settings/roles"
                              element={<RolesAndPermissionsSettings />}
                            />
                          </Route>
                        ) : null}
                        {helpers.checkHasPermissions(
                          userStore?.user?.Permissions,
                          "Manage Users",
                          "Can Read"
                        ) ? (
                          <Route path="menu" element={<SettingsLayout />}>
                            <Route
                              path="settings/manage"
                              element={<ManageUsersSettings />}
                            />
                          </Route>
                        ) : null}
                        {helpers.checkHasPermissions(
                          userStore?.user?.Permissions,
                          "Organigram",
                          "Can Read"
                        ) ? (
                          <Route path="menu" element={<SettingsLayout />}>
                            <Route
                              path="settings/organigram"
                              element={<OrganigramSettings />}
                            />
                          </Route>
                        ) : null}
                      </>
                    ) : (
                      <Route
                        path="*"
                        element={
                          <Error message="You are not authorized to use this app" />
                        }
                      ></Route>
                    )}
                  </>
                )}
              </Routes>
            )}
          </>
        }
      ></Route>
    </Routes>
  );

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <TeamsContextWrapper
          {...teamsContextStore}
          openInitialSSOFallbackConsent={async () => {
            setIsInitialSSOFallbackConformationPopupVisible(true);
          }}
          noSSOConsentRoute={noSSOConsentRoute}
          excludedRoutes={["/auth-success"]}
          openAADApiConsentModal={async () => {
            setIsAPIConsentConformationPopupVisible(true);
          }}
          checkOrganization={checkOrganization}
          configRoute={configRoute}
        >
          <FluentProvider theme={customLightTheme}>
            {renderRoutes()}
            <ToastContainer />
          </FluentProvider>
        </TeamsContextWrapper>
      </QueryClientProvider>
    </Router>
  );
}
