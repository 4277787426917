import { useState } from "react";
import "../components/SimpleLayout/SimpleNavbar.css";
import SimpleNavbar from "../components/SimpleLayout/SimpleNavbar";
import { useLocation } from "react-router-dom";
import "./Home.css";
import { Image } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { useQuery } from "@tanstack/react-query";
import Error from "../components/Error";
import { useHomeStore } from "../store/homeStore";
import timeOffService from "../services/timeOff";
import { useTimeOffsStore } from "../store/timeOffsStore";
import FullScreenSpinner from "../components/FullScreenSpinner";
import { useInitialSetupOrganizationStore } from "../store/initialSetupOrganizationStore";
import HomePageModal from "../components/HomeModal";

export default function Home() {
  const { t } = useTranslation();
  const homeStore = useStore(useHomeStore);
  const timeOffsStore = useStore(useTimeOffsStore);
  const location = useLocation();
  const [searchField, setSearchField] = useState<string>("");
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );
  const [openHomeModal, setOpenHomeModal] = useState<boolean>(false);

  const getInitialData = async () => {
    const result = await timeOffService.getTimeOffsData(
      initialSetupOrganizationStore?.hrSystem ?? 1,
      false
    );
    homeStore.setOwnChartData({
      AnnualDays: result?.annualDays,
      TakenDays: result?.balanceDays,
    });
    homeStore.setTeamChartData({
      AnnualDays: result?.teamAnnualDays,
      TakenDays: result?.teamBalanceDays,
    });
    timeOffsStore.setUserBalance(result?.userBalance);
    timeOffsStore.setTimeOffs(result?.timeOffs);
    timeOffsStore.setTimeOffTypes(result?.timeOffTypes);
    return result;
  };

  const { isPending, error, isFetching } = useQuery({
    queryKey: ["homeData"],
    queryFn: getInitialData,
  });
  if (isFetching || isPending) return <FullScreenSpinner />;
  if (error) return <Error message={t("home.generalError")} />;

  return (
    <div className="simpleLayout">
      <SimpleNavbar searchField={searchField} setSearchField={setSearchField} />
      <div
        className={`contentSimpleLayout ${
          location.pathname === "/menu/about" ? "about" : ""
        }`}
      >
        <div className="homePageContainer">
          <div className="homePageHeader">
            <div className="homePageHeaderText">
              <div className="homePageHeaderTextTitle">
                {t("home.welcomeToTargo")}
              </div>
              <div className="homePageHeaderTextContent">
                {t("home.yourCompleteHRManagementSolutionInMicrosoftTeams")}
              </div>
            </div>
            <div className="homePageHeaderImage">
              <Image
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/home/Avatar.svg`}
                alt="logo"
              />
            </div>
          </div>
          <div className="homeBottomHalf">
            <div
              className="homeCardContainer"
              style={{ cursor: "pointer" }}
              onClick={() => setOpenHomeModal(true)}
            >
              <Image
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/home/statistics.png`}
                className="homeContactUsCardImage"
                alt=""
              />
              <div className="homeCardInfoContainer">
                <span className="homeCardTitle">{t("home.statistics")}</span>
                <div className="homeLinksContainer">
                  <div className="homeCardLink">{t("home.seeStatistics")}</div>
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/web.svg`}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="homeCardContainer">
              <Image
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/image1.png`}
                className="homeContactUsCardImage"
                alt=""
              />
              <div className="homeCardInfoContainer">
                <span className="homeCardTitle">
                  {t("contactUs.moreActions.visitWebiste")}
                </span>
                <div className="homeLinksContainer">
                  <a
                    className="homeCardLink"
                    href="https://code-mosaic.com/"
                    target="_blank"
                  >
                    CodeMosaic.com
                  </a>
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/web.svg`}
                    alt=""
                  />
                </div>
                {/* <div className="homeTimeOffTableContainer">
                  <Table
                    ref={tableRef}
                    sortable
                    {...columnSizing_unstable.getTableProps()}
                    noNativeElements={true}
                    className="homeTimeOffTable"
                  >
                    <TableBody>
                      {items.map((item) => (
                        <TableRow key={item?.Id} className="homePageTableRow">
                          <Image
                            className="acceptedStatus"
                            src={getImageForAction(item.RequestStatus)}
                            alt="status"
                          />
                          <TableCell
                            {...columnSizing_unstable.getTableCellProps(
                              "EmployeeName"
                            )}
                          >
                            <TableCellLayout
                              truncate
                              media={<Avatar name={item.EmployeeName} />}
                            >
                              <div className="homeTimeOffNameContainer">
                                <div className="homeTimeOffLabelSection">
                                  {item?.EmployeeName}
                                </div>
                                <div className="homeTimeOffDepartmentSection">
                                  {item.DepartmentName}
                                </div>
                              </div>
                            </TableCellLayout>
                          </TableCell>
                          <TableCell
                            {...columnSizing_unstable.getTableCellProps(
                              "StartDate"
                            )}
                          >
                            <TableCellLayout truncate>
                              <div className="homeTimeOffPeriodSection">
                                <div className="homeTimeOffLabelSection">{`${moment(
                                  item?.StartDate
                                ).format("DD/MM/YYYY")} - ${moment(
                                  item?.EndDate
                                ).format("DD/MM/YYYY")}`}</div>
                                <div className="homeTimeOffDaysContainer">
                                  {item?.DaysNumber}{" "}
                                  {item?.DaysNumber === 1
                                    ? t("home.day")
                                    : t("home.days")}
                                </div>
                              </div>
                            </TableCellLayout>
                          </TableCell>
                          <TableCell
                            {...columnSizing_unstable.getTableCellProps(
                              "actions"
                            )}
                          >
                            <TableCellLayout className="absencesActionsCell">
                              <div className="absenceTableActionsSection">
                                {item?.RequestStatus === "pending" &&
                                userStore?.user?.EmployeeId !==
                                  item?.EmployeeId &&
                                helpers.checkHasPermissions(
                                  userStore?.user?.Permissions,
                                  "Absences",
                                  "Can Approve/Reject"
                                ) ? (
                                  <>
                                    <Tooltip
                                      content="Check action"
                                      withArrow
                                      positioning="below"
                                      appearance="inverted"
                                      relationship={"label"}
                                    >
                                      <Image
                                        className="actionIcon"
                                        src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/emptyCheck.svg`}
                                        alt="emptyCheck"
                                        onClick={() => acceptRequest(item.Id)}
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      content="Close action"
                                      withArrow
                                      positioning="below"
                                      appearance="inverted"
                                      relationship={"label"}
                                    >
                                      <Image
                                        className="actionIcon"
                                        src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/emptyClose.svg`}
                                        alt="emptyClose"
                                        onClick={() => denyRequest(item.Id)}
                                      />
                                    </Tooltip>
                                  </>
                                ) : null}
                                {helpers.checkHasPermissions(
                                  userStore?.user?.Permissions,
                                  "Absences",
                                  "Can Edit"
                                ) ? (
                                  <Menu>
                                    <MenuTrigger disableButtonEnhancement>
                                      <Tooltip
                                        content="Menu action"
                                        withArrow
                                        positioning="below"
                                        appearance="inverted"
                                        relationship={"label"}
                                      >
                                        <Image
                                          className="actionIcon"
                                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/horizontalMenu.svg`}
                                          alt="horizontalMenu"
                                        />
                                      </Tooltip>
                                    </MenuTrigger>

                                    <MenuPopover>
                                      <MenuList>
                                        <MenuItem
                                          onClick={() =>
                                            editEmployeeOpenModal(item?.Id)
                                          }
                                        >
                                          <span>
                                            {t("absencesOwnHr.table.edit")}
                                          </span>
                                        </MenuItem>
                                      </MenuList>
                                    </MenuPopover>
                                  </Menu>
                                ) : null}
                              </div>
                            </TableCellLayout>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {initialSetupOrganizationStore.hrSystem === 2 ? (
                    <AddEmployeePersonioAbsence
                      isModalOpen={editEmployeeModalOpen}
                      closeModal={editEmployeeCloseModal}
                      timeOffId={selectedEditTimeOff}
                    />
                  ) : (
                    <AddEmployeeAbsence
                      isModalOpen={editEmployeeModalOpen}
                      closeModal={editEmployeeCloseModal}
                      timeOffId={selectedEditTimeOff}
                    />
                  )}
                </div> */}
              </div>
            </div>
            <div className="homeCardContainer">
              <Image
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/image2.png`}
                className="homeContactUsCardImage"
                alt=""
              />
              <div className="homeCardInfoContainer">
                <span className="homeCardTitle">
                  {t("contactUs.moreActions.sendEmail")}
                </span>
                <div className="homeLinksContainer">
                  <a
                    className="homeCardLink"
                    href="https://code-mosaic.com/contact/"
                    target="_blank"
                  >
                    {t("contactUs.moreActions.clickToSend")}
                  </a>
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/contactUs/email.svg`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <HomePageModal
            isModalOpen={openHomeModal}
            closeModal={() => setOpenHomeModal(false)}
          />
        </div>
      </div>
    </div>
  );
}
