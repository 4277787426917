import React, { useEffect, useState } from "react";
import {
  Button,
  Image,
  Input,
  Label,
  Radio,
  RadioGroup,
  RadioGroupOnChangeData,
} from "@fluentui/react-components";
import "./Step1.css";
import OwnHR from "./OwnHR";
import { useTranslation } from "react-i18next";
import OrganizationService from "../../../../services/organization";
import { HrSystem } from "../../../../types";
import Support from "../../Support";

export function Step1(props: {
  setCurrentStep: (value: number) => void;
  setIsError: (value: boolean) => void;
  setErrorMessage: (value: string) => void;
  setIsNextDisabled: (value: boolean) => void;
  setSelectedHrSystem: (value: string) => void;
}) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [otherHrValue, setOtherHrValue] = useState<string>("");
  const [hrSystems, setHrSystems] = useState<HrSystem[]>([]);

  useEffect(() => {
    const getHrSystems = async () => {
      const result = await OrganizationService.getHrSystems();
      setHrSystems(result?.hrSystems);
      setSelectedOption(
        result?.existingHrSystemId ? result?.existingHrSystemId.toString() : ""
      );
    };
    getHrSystems();
  }, []);

  useEffect(() => {
    props.setSelectedHrSystem(selectedOption);
    props.setIsNextDisabled(true);
    // props.setIsNextDisabled(
    //   selectedOption && selectedOption !== "2" ? true : false
    // );
  }, [selectedOption]);

  const handleOptionChange = (
    ev: React.FormEvent<HTMLDivElement>,
    data: RadioGroupOnChangeData
  ) => {
    setSelectedOption(data.value as string);
    props.setIsNextDisabled(true);
  };

  const handleOtherHrInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOtherHrValue(event.target.value);
  };

  const handleSendRequestClick = async () => {
    try {
      await OrganizationService.setOtherHrSystem(otherHrValue);
      props?.setCurrentStep(2);
    } catch (e) {
      props.setIsError(true);
      props.setErrorMessage(
        t("initialSetup.errors.errorSendingOtherHrComment")
      );
    }
  };

  let contentToShow = null;

  switch (selectedOption) {
    case "1":
      contentToShow = (
        <div>
          <OwnHR />
        </div>
      );
      break;
    case "2":
      contentToShow = (
        <p style={{ fontSize: "20px", fontWeight: 600 }}>
          Personio integration is coming soon! Stay tuned for updates as we work
          to bring this feature to you.
        </p>
      );
      break;
    case "otherHr":
      contentToShow = (
        <div className="otherHrContainer">
          <div className="inputContainer">
            <Label className="otherHrLabel">
              {t("initialSetup.steps.step1.otherHr.nameHr")}
            </Label>
            <Input
              className="otherHrInput"
              contentAfter={
                otherHrValue ? (
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/initialSetup/inputCheck.svg`}
                    alt="My SVG"
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : null
              }
              value={otherHrValue}
              onChange={handleOtherHrInputChange}
            />
          </div>

          <Button
            appearance="primary"
            className="sendRequestButton"
            onClick={handleSendRequestClick}
          >
            <span className="sendRequestMessage">
              {" "}
              {t("initialSetup.steps.step1.otherHr.sendRequest")}{" "}
            </span>
          </Button>
        </div>
      );
      break;
    default:
      contentToShow = null;
  }

  return (
    <>
      <div className="stepContainer">
        <div className="step1Container">
          <div className="titleSection">
            <h2 className="step1Title">
              {t("initialSetup.steps.step1.customSurvey")}
            </h2>
            <p className="step1Subtitle">
              {t("initialSetup.steps.step1.optionalDescription")}
            </p>
          </div>

          <div className="hrSystem">
            <div className="hrSystemDetails">
              <h3 className="hrSystemTitle">
                {t("initialSetup.steps.step1.hrSystem")}
              </h3>
            </div>

            <RadioGroup
              {...props}
              onChange={handleOptionChange}
              defaultValue={selectedOption}
            >
              {hrSystems.map((hrSystem) => (
                <Radio
                  value={hrSystem?.Id.toString()}
                  key={hrSystem?.Id}
                  label={hrSystem?.Name}
                />
              ))}
              <Radio value="otherHr" label="Other HR Solution" />
            </RadioGroup>
          </div>
        </div>
        <Support />
      </div>
      {contentToShow}
    </>
  );
}
