import { ErrorMessage, Form, Formik } from "formik";
import Modal from "../../Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Button, Image, Input, Select } from "@fluentui/react-components";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "./AddEmployee.css";
import { useEffect, useState } from "react";
import { useStore } from "zustand";
import { useEmployeeStore } from "../../../store/ownEmployeeManagementStore";
import employeeManagementService from "../../../services/employeesManagement";
import moment from "moment";
import { useUserStore } from "../../../store/userStore";
import { useInitialSetupOrganizationStore } from "../../../store/initialSetupOrganizationStore";

interface IAddEmployee {
  isModalOpen: boolean;
  closeModal: () => void;
  employeeId: any | null;
}
const AddEmployee: React.FC<IAddEmployee> = ({
  isModalOpen,
  closeModal,
  employeeId,
}) => {
  const { t } = useTranslation();
  const userStore = useStore(useUserStore);
  const employeeManagementStore = useStore(useEmployeeStore);
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    position: "",
    contractType: "",
    managerUserId: "",
    status: "",
    worksite: "",
    hireDate: "",
    terminationDate: "",
    dailyHours: "",
    annualBalance: 0,
    annualLeaveRemained: 0,
    annual: 0,
    sickBalance: 0,
    parentalBalance: 0,
    unpaidBalance: 0,
    bereavementBalance: 0,
  });
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  useEffect(() => {
    if (employeeId) {
      const employeeData = employeeManagementStore?.employees?.find(
        (employee) => employee?.Id === employeeId
      );
      setInitialValues({
        name: employeeData?.Name ? employeeData.Name : "",
        position: employeeData?.Position ? employeeData.Position : "",
        contractType: employeeData?.ContractType
          ? employeeData.ContractType[0].toUpperCase() +
            employeeData.ContractType.slice(1)
          : "",
        managerUserId: employeeManagementStore.managers.filter(
          (manager) =>
            manager?.Id ===
            employeeManagementStore?.employees?.find(
              (employee) => employee?.Id === employeeId
            )?.SupervisorId
        )[0]?.Id,
        status: employeeData?.Status ? employeeData.Status : "",
        worksite: employeeData?.Worksite ? employeeData.Worksite : "",
        hireDate: employeeData?.HireDate ? employeeData.HireDate : null,
        terminationDate: employeeData?.TerminationDate
          ? employeeData.TerminationDate
          : null,
        dailyHours: employeeData?.DailyHours ? employeeData.DailyHours : "",
        annualBalance: employeeData?.AnnualBalance
          ? employeeData.AnnualBalance
          : 0,
        annualLeaveRemained:
          (employeeData?.Annual ?? 0) - (employeeData?.AnnualBalance ?? 0),
        annual: employeeData?.Annual ? employeeData.Annual : 0,
        sickBalance: employeeData?.SickBalance ? employeeData.SickBalance : 0,
        parentalBalance: employeeData?.ParentalBalance
          ? employeeData.ParentalBalance
          : 0,
        unpaidBalance: employeeData?.UnpaidBalance
          ? employeeData.UnpaidBalance
          : 0,
        bereavementBalance: employeeData?.BereavementBalance
          ? employeeData.BereavementBalance
          : 0,
      });
    }
  }, [employeeId]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t("employeesManagementOwnHr.addEmployee.requiredField")
    ),
    dailyHours: Yup.string().matches(
      /^\d+$|^\d+:\d{2}$/,
      t("employeesManagementOwnHr.addEmployee.invalidDailyHoursFormat")
    ),
  });

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    try {
      const result = await employeeManagementService.editEmployee(
        employeeId,
        values?.annual,
        values?.contractType,
        values?.dailyHours,
        values?.hireDate,
        values?.managerUserId,
        values?.name,
        values?.position,
        values?.status,
        values?.terminationDate,
        values?.worksite,
        initialSetupOrganizationStore?.hrSystem ?? 1
      );
      if ("employee" in result) {
        employeeManagementStore.updateEmployee(result?.employee);
        if (userStore?.user) {
          employeeManagementStore.checkManager(userStore?.user);
        }
        toast.success(t("employeesManagementOwnHr.success"), {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error(t("employeesManagementOwnHr.modals.generalError"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSubmitting(false);
      closeModal();
      resetForm({
        name: "",
        position: "",
        contractType: "",
        managerUserId: "",
        status: "",
        worksite: "",
        hireDate: "",
        terminationDate: "",
        dailyHours: "",
        annualBalance: 0,
        annualLeaveRemained: 0,
        annual: 0,
        sickBalance: 0,
        parentalBalance: 0,
        unpaidBalance: 0,
        bereavementBalance: 0,
      });
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={t("employeesManagementOwnHr.addEmployee.title")}
        subtitle={t("employeesManagementOwnHr.addEmployee.subTitle")}
        contentTitle={t(
          "employeesManagementOwnHr.addEmployee.editInformations"
        )}
        headerStyle="addEmployeeModalHeader"
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, handleChange, handleBlur, values }) => (
            <Form className="addEmployeeForm">
              <div className="formFirstLine">
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.name")}
                  </label>
                  <Input
                    name="name"
                    value={values.name}
                    placeholder={t("employeesManagementOwnHr.addEmployee.name")}
                    className="addEmployeeManagementInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.position")}
                  </label>
                  <Input
                    name="position"
                    value={values.position}
                    className="addEmployeeManagementInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    name="position"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.contractType")}
                  </label>
                  <Input
                    name="contractType"
                    value={values.contractType}
                    className="addEmployeeManagementInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="contractType"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="formSecondLine">
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.managerName")}
                  </label>
                  <Select
                    name="managerUserId"
                    value={values.managerUserId}
                    className="addEmployeeManagementInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>
                      {t(
                        "employeesManagementOwnHr.addEmployee.selectManagerName"
                      )}
                    </option>
                    {employeeManagementStore?.managers?.map((manager) => {
                      return (
                        <option value={manager?.Id} key={manager?.Id}>
                          {manager?.FirstName} {manager?.LastName}
                        </option>
                      );
                    })}
                  </Select>
                  <ErrorMessage
                    name="managerUserId"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.status")}
                  </label>
                  <Select
                    name="status"
                    value={values.status}
                    className="addEmployeeManagementInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>
                      {t("employeesManagementOwnHr.addEmployee.selectStatus")}
                    </option>
                    <option value="active">
                      {t("employeesManagementOwnHr.addEmployee.active")}
                    </option>
                    <option value="inactive">
                      {t("employeesManagementOwnHr.addEmployee.inactive")}
                    </option>
                  </Select>
                  <ErrorMessage
                    name="status"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.worksite")}
                  </label>
                  <Select
                    name="worksite"
                    value={values.worksite}
                    className="addEmployeeManagementInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>
                      {t("employeesManagementOwnHr.addEmployee.selectWorksite")}
                    </option>
                    <option value="intern">
                      {t("employeesManagementOwnHr.addEmployee.intern")}
                    </option>
                    <option value="extern">
                      {t("employeesManagementOwnHr.addEmployee.extern")}
                    </option>
                  </Select>
                  <ErrorMessage
                    name="worksite"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="formThirdLine">
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.hireDate")}
                  </label>
                  <DatePicker
                    name="hireDate"
                    value={
                      values.hireDate
                        ? moment(values.hireDate).format("DD/MM/YYYY")
                        : ""
                    }
                    isClearable
                    className="addEmployeeManagementDatePickerInput addEmployeeManagementInputStyle"
                    onChange={(date) => {
                      handleChange({
                        target: { name: "hireDate", value: date },
                      });
                    }}
                  />
                  <ErrorMessage
                    name="hireDate"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.terminationDate")}
                  </label>
                  <DatePicker
                    name="terminationDate"
                    value={
                      values.terminationDate
                        ? moment(values.terminationDate).format("DD/MM/YYYY")
                        : ""
                    }
                    isClearable
                    className="addEmployeeManagementDatePickerInput addEmployeeManagementInputStyle"
                    onChange={(date) => {
                      handleChange({
                        target: { name: "terminationDate", value: date },
                      });
                    }}
                  />
                  <ErrorMessage
                    name="terminationDate"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.dailyHours")}
                  </label>
                  <div className="datePickerContainer">
                    <Input
                      name="dailyHours"
                      value={values?.dailyHours}
                      placeholder="e.g. 7:30"
                      className="addEmployeeManagementInputStyle addEmployeeManagementDateInput"
                      appearance="filled-lighter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <Image
                      className="inputClock"
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
                      alt="inputClock"
                    />
                  </div>
                  <ErrorMessage
                    name="dailyHours"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="formFourthLine">
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.annual")}
                  </label>
                  <Input
                    type="number"
                    name="annual"
                    value={values.annual}
                    className="addEmployeeManagementNumberInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    name="annual"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.annualBalance")}
                  </label>
                  <Input
                    disabled
                    type="number"
                    name="annualBalance"
                    value={values.annualBalance}
                    className="addEmployeeManagementNumberInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    name="annualBalance"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t(
                      "employeesManagementOwnHr.addEmployee.annualLeaveRemained"
                    )}
                  </label>
                  <Input
                    disabled
                    type="number"
                    name="annualLeaveRemained"
                    value={values.annualLeaveRemained}
                    className="addEmployeeManagementNumberInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    name="annualLeaveRemained"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="formFifthLine">
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.sickBalance")}
                  </label>
                  <Input
                    disabled
                    type="number"
                    name="sickBalance"
                    value={values.sickBalance}
                    className="addEmployeeManagementNumberInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    name="sickBalance"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.parentalBalance")}
                  </label>
                  <Input
                    disabled
                    type="number"
                    name="parentalBalance"
                    value={values.parentalBalance}
                    className="addEmployeeManagementNumberInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    name="parentalBalance"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t("employeesManagementOwnHr.addEmployee.unpaidBalance")}
                  </label>
                  <Input
                    disabled
                    type="number"
                    name="unpaidBalance"
                    value={values.unpaidBalance}
                    className="addEmployeeManagementNumberInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    name="unpaidBalance"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainer">
                  <label className="addEmployeeInputLabel">
                    {t(
                      "employeesManagementOwnHr.addEmployee.bereavementBalance"
                    )}
                  </label>
                  <Input
                    disabled
                    type="number"
                    name="bereavementBalance"
                    value={values.bereavementBalance}
                    className="addEmployeeManagementNumberInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    name="bereavementBalance"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="formSixthLine">
                <Button
                  appearance="primary"
                  className="addEmployeeAttendanceButton"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {t("employeesManagementOwnHr.addEmployee.save")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default AddEmployee;
