import "./SideNav.css";
import { useTranslation } from "react-i18next";
import { Image } from "@fluentui/react-components";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import helpers from "../../helpers/helpers";
import { useStore } from "zustand";
import { useUserStore } from "../../store/userStore";

const SideNav: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState<string>(
    location.pathname === "/menu/settings"
      ? "General"
      : location.pathname === "/menu/integrations/personio"
      ? "Personio"
      : location.pathname === "/menu/integrations/microsoft"
      ? "Microsoft"
      : location.pathname === "/menu/settings/roles"
      ? "Roles"
      : location.pathname === "/menu/settings/manage"
      ? "Manage"
      : location.pathname === "/menu/settings/organigram"
      ? "Organigram"
      : "General"
  );
  const [integrationsOpen, setIntegrationsOpen] = useState(false);
  const userStore = useStore(useUserStore);

  return (
    <div className={`settingsSideNav ${isOpen ? "settingsSideNavOpen" : ""}`}>
      <ul className="settingsSideNavMenuList">
        {helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Settings General",
          "Can Read"
        ) ? (
          <li
            className={`settingsSideNavMenuItem ${
              activeItem === "General" ? "active" : ""
            }`}
            onClick={() => {
              navigate("/menu/settings");
              setActiveItem("General");
            }}
          >
            {t("settings.sidenav.general")}
          </li>
        ) : null}
        {helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Settings Integrations",
          "Can Read"
        ) ? (
          <>
            <li
              className="settingsSideNavMenuItem"
              onClick={() => setIntegrationsOpen(!integrationsOpen)}
            >
              {integrationsOpen ? (
                <div className="expandButton">
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/settings/CaretDown.svg`}
                    alt="arrow-down"
                  />
                </div>
              ) : (
                <div className="expandButton">
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/settings/CaretRight.svg`}
                    alt="arrow-right"
                  />
                </div>
              )}
              {t("settings.sidenav.integrations")}
            </li>
            {integrationsOpen ? (
              <>
                <li
                  className={`settingsSideNavMenuItem ${
                    activeItem === "Personio" ? "active" : ""
                  }`}
                  onClick={() => {
                    navigate("/menu/integrations/personio");
                    setActiveItem("Personio");
                  }}
                >
                  {t("settings.sidenav.personio")}
                </li>
                <li
                  className={`settingsSideNavMenuItem ${
                    activeItem === "Microsoft" ? "active" : ""
                  }`}
                  onClick={() => {
                    navigate("/menu/integrations/microsoft");
                    setActiveItem("Microsoft");
                  }}
                >
                  {t("settings.sidenav.microsoftGraphAPI")}
                </li>
              </>
            ) : null}
          </>
        ) : null}
        {helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Roles and Permissions",
          "Can Read"
        ) ? (
          <li
            className={`settingsSideNavMenuItem ${
              activeItem === "Roles" ? "active" : ""
            }`}
            onClick={() => {
              navigate("/menu/settings/roles");
              setActiveItem("Roles");
            }}
          >
            {t("settings.sidenav.rolesAndPermissions")}
          </li>
        ) : null}
        {helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Manage Users",
          "Can Read"
        ) ? (
          <li
            className={`settingsSideNavMenuItem ${
              activeItem === "Manage" ? "active" : ""
            }`}
            onClick={() => {
              navigate("/menu/settings/manage");
              setActiveItem("Manage");
            }}
          >
            {t("settings.sidenav.manageUsers")}
          </li>
        ) : null}
        {helpers.checkHasPermissions(
          userStore?.user?.Permissions,
          "Organigram",
          "Can Read"
        ) ? (
          <li
            className={`settingsSideNavMenuItem ${
              activeItem === "Organigram" ? "active" : ""
            }`}
            onClick={() => {
              navigate("/menu/settings/organigram");
              setActiveItem("Organigram");
            }}
          >
            {t("settings.sidenav.organigram")}
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default SideNav;
