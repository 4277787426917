import moment, { Moment } from "moment";
import {
  Attendance,
  AttendanceSummarized,
  PersonioProject,
  User,
} from "../types";
import httpService from "./http";

const getInitialStartAttendance = async (hrSystemId: number) => {
  const response = await httpService.get(`/attendance/start/${hrSystemId}`);
  return response.data;
};

const startAttendance = async (
  location: string,
  type: string,
  date: Date,
  hrSystemId: number
) => {
  const response = await httpService.post(`/attendance/start/${hrSystemId}`, {
    location,
    type,
    date,
  });
  return response.data;
};

const endAttendance = async (attendanceId: number, hrSystemId: number) => {
  const response = await httpService.post(`/attendance/end/${hrSystemId}`, {
    attendanceId,
  });
  if ("status" in response?.data && response?.data?.status === "error") {
    return response?.data;
  }
  let duration = formatDuration(
    hrSystemId === 2
      ? response?.data?.attendance?.AttendanceWithoutBreaks * 60
      : response?.data?.attendance?.AttendanceDuration
  );
  const attendance: Attendance = {
    Id: response?.data?.attendance?.AttendanceId,
    EmployeeId: response?.data?.attendance?.EmployeeId,
    EmployeeName:
      hrSystemId === 2
        ? response?.data?.attendance?.PersonioEmployee?.EmployeeName
        : response?.data?.attendance?.OwnEmployee?.EmployeeName,
    DepartmentName:
      hrSystemId === 2
        ? response?.data?.attendance?.PersonioEmployee?.PersonioDepartment
            ?.DepartmentName
        : response?.data?.attendance?.OwnEmployee?.OwnDepartment
            ?.DepartmentName,
    SupervisorId: response?.data?.attendance?.SupervisorId,
    SupervisorName:
      response?.data?.attendance?.SupervisorName !== "" &&
      response?.data?.attendance?.SupervisorName !== null
        ? response?.data?.attendance?.SupervisorName
        : "Unassigned",
    Date: moment(response?.data?.attendance?.AttendanceDate),
    Type:
      response?.data?.attendance?.AttendanceType === "break" ? "Break" : "Work",
    Duration: duration,
    Location:
      response?.data?.attendance?.AttendanceLocation === "remote"
        ? "Remote"
        : "Office",
    StartTime: moment(response?.data?.attendance?.AttendanceStartTime),
    EndTime: moment(response?.data?.attendance?.AttendanceEndTime),
  };
  if (hrSystemId === 2) {
    attendance["BreakDuration"] = formatDuration(
      response?.data?.attendance?.AttendanceBreakInMinutes * 60
    );
    attendance["DurationWithBreak"] = formatDuration(
      response?.data?.attendance?.AttendanceWithBreaks * 60
    );
    attendance["ProjectId"] = response?.data?.attendance?.ProjectId;
    attendance["Comment"] = response?.data?.attendance?.AttendanceComment;
    attendance["BreakInMinutes"] =
      response?.data?.attendance?.AttendanceBreakInMinutes;
  }
  return { attendance };
};

const getAttendancesData = async (hrSystemId: number, isRefresh: boolean) => {
  const response = await httpService.get(
    `/attendance/${hrSystemId}/${isRefresh}`
  );
  const attendances: Attendance[] = [];
  response?.data?.attendances?.forEach((att: any) => {
    let duration = formatDuration(
      hrSystemId === 2
        ? att?.AttendanceWithoutBreaks * 60
        : att?.AttendanceDuration
    );
    let attendance: Attendance = {
      Id: att?.AttendanceId,
      EmployeeId:
        hrSystemId === 2
          ? att?.PersonioEmployee?.EmployeeId
          : att?.OwnEmployee?.EmployeeId,
      EmployeeName:
        hrSystemId === 2
          ? att?.PersonioEmployee?.EmployeeName
          : att?.OwnEmployee?.EmployeeName,
      DepartmentName:
        hrSystemId === 2
          ? att?.PersonioEmployee?.PersonioDepartment?.DepartmentName
          : att?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: att?.SupervisorId,
      SupervisorName:
        att?.SupervisorName !== "" && att?.SupervisorName !== null
          ? att?.SupervisorName
          : "Unassigned",
      Date: moment(att?.AttendanceDate),
      Type: att?.AttendanceType === "break" ? "Break" : "Work",
      Duration: duration,
      Location: att?.AttendanceLocation === "remote" ? "Remote" : "Office",
      StartTime: moment(att?.AttendanceStartTime),
      EndTime: moment(att?.AttendanceEndTime),
    };
    if (hrSystemId === 2) {
      attendance["BreakDuration"] = formatDuration(
        att?.AttendanceBreakInMinutes * 60
      );
      attendance["DurationWithBreak"] = formatDuration(
        att?.AttendanceWithBreaks * 60
      );
      attendance["ProjectId"] = att?.ProjectId;
      attendance["Comment"] = att?.AttendanceComment;
      attendance["BreakInMinutes"] = att?.AttendanceBreakInMinutes;
    }

    attendances.push(attendance);
  });
  const users: User[] = [];
  response?.data?.users?.forEach((user: any) => {
    users.push({
      Id: user?.UserId,
      Email: user?.Email,
      FirstName: user?.FirstName,
      LastName: user?.LastName,
      EmployeeId:
        hrSystemId === 2
          ? user?.PersonioEmployee?.EmployeeId
          : user?.Employee?.EmployeeId,
    });
  });
  const projects: PersonioProject[] = [];
  if ("projects" in response?.data) {
    response?.data?.projects?.forEach((project: any) => {
      projects.push({
        Id: project?.ProjectId,
        Name: project?.ProjectName,
        Active: project?.ProjectActive,
        PersonioId: project?.ProjectPersonioId,
      });
    });
  }
  return { attendances, users, projects };
};

const formatDuration = (duration: number): string => {
  const seconds = Math.floor(duration % 60);
  const minutes = Math.floor((duration % 3600) / 60);
  const hours = Math.floor(duration / 3600);
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;
};

const sendTracks = async (tracks: any[], hrSystemId: number) => {
  const response = await httpService.post(`/attendance/tracks/${hrSystemId}`, {
    tracks,
  });
  if ("status" in response.data && response?.data?.status === "error") {
    return response.data;
  }
  const attendances: Attendance[] = [];
  response?.data?.attendances?.forEach((att: any) => {
    let duration = formatDuration(
      hrSystemId === 2
        ? att?.AttendanceWithoutBreaks * 60
        : att?.AttendanceDuration
    );
    let attendance: Attendance = {
      Id: att?.AttendanceId,
      EmployeeId: att?.EmployeeId,
      EmployeeName:
        hrSystemId === 2
          ? att?.PersonioEmployee?.EmployeeName
          : att?.OwnEmployee?.EmployeeName,
      DepartmentName:
        hrSystemId === 2
          ? att?.PersonioEmployee?.EmployeeName
          : att?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: att?.SupervisorId,
      SupervisorName:
        att?.SupervisorName !== "" && att?.SupervisorName !== null
          ? att?.SupervisorName
          : "Unassigned",
      Date: moment(att?.AttendanceDate),
      Type: att?.AttendanceType === "break" ? "Break" : "Work",
      Duration: duration,
      Location: att?.AttendanceLocation === "remote" ? "Remote" : "Office",
      StartTime: moment(att?.AttendanceStartTime),
      EndTime: moment(att?.AttendanceEndTime),
    };
    if (hrSystemId === 2) {
      attendance["BreakDuration"] = formatDuration(
        att?.AttendanceBreakInMinutes * 60
      );
      attendance["DurationWithBreak"] = formatDuration(
        att?.AttendanceWithBreaks * 60
      );
      attendance["ProjectId"] = att?.ProjectId;
      attendance["Comment"] = att?.AttendanceComment;
      attendance["BreakInMinutes"] = att?.AttendanceBreakInMinutes;
    }
    attendances.push(attendance);
  });
  return { attendances };
};

const getSummarizedAttendances = async (hrSystemId: number) => {
  const response = await httpService.get(
    `/attendance/summarized/${hrSystemId}`
  );
  let attendances: AttendanceSummarized[] = [];
  response?.data?.attendances?.forEach((att: any) => {
    attendances.push({
      EmployeeId: att?.EmployeeId,
      EmployeeName:
        hrSystemId === 2
          ? att?.PersonioEmployee?.EmployeeName
          : att?.OwnEmployee?.EmployeeName,
      DepartmentName:
        hrSystemId === 2
          ? att?.PersonioEmployee?.PersonioDepartment?.DepartmentName
          : att?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: att?.SupervisorId,
      SupervisorName:
        att?.SupervisorName !== "" && att?.SupervisorName !== null
          ? att?.SupervisorName
          : "Unassigned",
      Date: moment(att?.AttendanceDate),
      WorkTime: formatDuration(att?.totalWorkTime),
      BreakTime: formatDuration(att?.totalBreakTime),
      Location:
        att?.AttendanceLocation === "remote"
          ? "Remote"
          : att?.AttendanceLocation === "office"
          ? "Office"
          : "",
    });
  });
  return { attendances };
};

const addAttendance = async (
  date: string,
  type: string,
  location: string,
  userId: number,
  startTime: Date,
  endTime: Date,
  hrSystemId: number,
  project?: number,
  comment?: string,
  breakInMinutes?: number
) => {
  const response = await httpService.post(`/attendance/add/${hrSystemId}`, {
    date,
    type,
    location,
    userId,
    startTime,
    endTime,
    project,
    comment,
    breakInMinutes,
  });
  if ("attendance" in response.data) {
    let duration = formatDuration(
      hrSystemId === 2
        ? response?.data?.attendance?.AttendanceWithoutBreaks * 60
        : response?.data?.attendance?.AttendanceDuration
    );
    let attendance: Attendance = {
      Id: response?.data?.attendance?.AttendanceId,
      EmployeeId: response?.data?.attendance?.EmployeeId,
      EmployeeName:
        hrSystemId === 2
          ? response?.data?.attendance?.PersonioEmployee?.EmployeeName
          : response?.data?.attendance?.OwnEmployee?.EmployeeName,
      DepartmentName:
        hrSystemId === 2
          ? response?.data?.attendance?.PersonioEmployee?.PersonioDepartment
              ?.DepartmentName
          : response?.data?.attendance?.OwnEmployee?.OwnDepartment
              ?.DepartmentName,
      SupervisorId: response?.data?.attendance?.SupervisorId,
      SupervisorName:
        response?.data?.attendance?.SupervisorName !== "" &&
        response?.data?.attendance?.SupervisorName !== null
          ? response?.data?.attendance?.SupervisorName
          : "Unassigned",
      Date: moment(response?.data?.attendance?.AttendanceDate),
      Type:
        response?.data?.attendance?.AttendanceType === "break"
          ? "Break"
          : "Work",
      Duration: duration,
      Location:
        response?.data?.attendance?.AttendanceLocation === "remote"
          ? "Remote"
          : "Office",
      StartTime: moment(response?.data?.attendance?.AttendanceStartTime),
      EndTime: moment(response?.data?.attendance?.AttendanceEndTime),
    };
    if (hrSystemId === 2) {
      attendance["BreakDuration"] = formatDuration(
        response?.data?.attendance?.AttendanceBreakInMinutes * 60
      );
      attendance["DurationWithBreak"] = formatDuration(
        response?.data?.attendance?.AttendanceWithBreaks * 60
      );
      attendance["ProjectId"] = response?.data?.attendance?.ProjectId;
      attendance["Comment"] = response?.data?.attendance?.AttendanceComment;
      attendance["BreakInMinutes"] =
        response?.data?.attendance?.AttendanceBreakInMinutes;
    }
    return { attendance };
  }
  return response.data;
};

const editAttendance = async (
  attendanceId: number,
  date: string,
  type: string,
  location: string,
  userId: string,
  startTime: Date,
  endTime: Date,
  hrSystemId: number,
  project?: number,
  comment?: string,
  breakInMinutes?: number
) => {
  const response = await httpService.patch(`/attendance/edit/${hrSystemId}`, {
    attendanceId,
    date,
    type,
    location,
    userId,
    startTime,
    endTime,
    project,
    comment,
    breakInMinutes,
  });
  if ("attendance" in response.data) {
    let duration = formatDuration(
      hrSystemId === 2
        ? response?.data?.attendance?.AttendanceWithoutBreaks * 60
        : response?.data?.attendance?.AttendanceDuration
    );
    let attendance: Attendance = {
      Id: response?.data?.attendance?.AttendanceId,
      EmployeeId: response?.data?.attendance?.EmployeeId,
      EmployeeName:
        hrSystemId === 2
          ? response?.data?.attendance?.PersonioEmployee?.EmployeeName
          : response?.data?.attendance?.OwnEmployee?.EmployeeName,
      DepartmentName:
        hrSystemId === 2
          ? response?.data?.attendance?.PersonioEmployee?.PersonioDepartment
              ?.DepartmentName
          : response?.data?.attendance?.OwnEmployee?.OwnDepartment
              ?.DepartmentName,
      SupervisorId: response?.data?.attendance?.SupervisorId,
      SupervisorName:
        response?.data?.attendance?.SupervisorName !== "" &&
        response?.data?.attendance?.SupervisorName !== null
          ? response?.data?.attendance?.SupervisorName
          : "Unassigned",
      Date: moment(response?.data?.attendance?.AttendanceDate),
      Type:
        response?.data?.attendance?.AttendanceType === "break"
          ? "Break"
          : "Work",
      Duration: duration,
      Location:
        response?.data?.attendance?.AttendanceLocation === "remote"
          ? "Remote"
          : "Office",
      StartTime: moment(response?.data?.attendance?.AttendanceStartTime),
      EndTime: moment(response?.data?.attendance?.AttendanceEndTime),
    };
    if (hrSystemId === 2) {
      attendance["BreakDuration"] = formatDuration(
        response?.data?.attendance?.AttendanceBreakInMinutes * 60
      );
      attendance["DurationWithBreak"] = formatDuration(
        response?.data?.attendance?.AttendanceWithBreaks * 60
      );
      attendance["ProjectId"] = response?.data?.attendance?.ProjectId;
      attendance["Comment"] = response?.data?.attendance?.AttendanceComment;
      attendance["BreakInMinutes"] =
        response?.data?.attendance?.AttendanceBreakInMinutes;
    }
    return { attendance };
  }
  return response.data;
};

const importCsvFile = async (csvData: any) => {
  const response = await httpService.post("/attendance/import", { csvData });
  if ("attendances" in response?.data) {
    const attendances: Attendance[] = [];
    response?.data?.attendances?.forEach((att: any) => {
      let duration = formatDuration(att?.AttendanceDuration);
      attendances.push({
        Id: att?.AttendanceId,
        EmployeeId: att?.EmployeeId,
        EmployeeName: att?.OwnEmployee?.EmployeeName,
        DepartmentName: att?.OwnEmployee?.OwnDepartment?.DepartmentName,
        SupervisorId: att?.SupervisorId,
        SupervisorName:
          att?.SupervisorName !== "" && att?.SupervisorName !== null
            ? att?.SupervisorName
            : "Unassigned",
        Date: moment(att?.AttendanceDate),
        Type: att?.AttendanceType === "break" ? "Break" : "Work",
        Duration: duration,
        Location: att?.AttendanceLocation === "remote" ? "Remote" : "Office",
        StartTime: moment(att?.AttendanceStartTime),
        EndTime: moment(att?.AttendanceEndTime),
      });
    });
    return { attendances };
  }
  return response.data;
};

const deleteAttendance = async (attendanceId: number, hrSystemId: number) => {
  const response = await httpService.post(`/attendance/delete/${hrSystemId}`, {
    attendanceId,
  });
  return response.data;
};

const deleteSumAttendances = async (
  date: string,
  employeeId: number,
  location: string,
  hrSystemId: number
) => {
  const response = await httpService.post(
    `/attendance/summarized/delete/${hrSystemId}`,
    {
      date,
      employeeId,
      location,
    }
  );
  return response.data;
};

const attendanceService = {
  startAttendance,
  getInitialStartAttendance,
  endAttendance,
  getAttendancesData,
  sendTracks,
  getSummarizedAttendances,
  addAttendance,
  editAttendance,
  importCsvFile,
  deleteAttendance,
  deleteSumAttendances,
};
export default attendanceService;
