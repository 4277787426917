import { toast } from "react-toastify";
import AbsenceRequest from "../components/AbsencesOwnHr/AbsencesComponents/AbsenceRequest";
import { AbsenceTable } from "../components/AbsencesOwnHr/AbsencesComponents/AbsenceTable";
import AnualLeave from "../components/AbsencesOwnHr/AbsencesComponents/AnualLeave";
import TakenLeave from "../components/AbsencesOwnHr/AbsencesComponents/TakenLeave";
import PersonioLeave from "../components/AbsencesOwnHr/AbsencesComponents/PersonioLeave";
import "./Absences.css";
import timeOffService from "../services/timeOff";
import { useQuery } from "@tanstack/react-query";
import Error from "../components/Error";
import { useTranslation } from "react-i18next";
import { useTimeOffsStore } from "../store/timeOffsStore";
import { useStore } from "zustand";
import { useHomeStore } from "../store/homeStore";
import FullScreenSpinner from "../components/FullScreenSpinner";
import { useInitialSetupOrganizationStore } from "../store/initialSetupOrganizationStore";

export default function Absences() {
  const { t } = useTranslation();
  const timeOffsStore = useStore(useTimeOffsStore);
  const homeStore = useStore(useHomeStore);
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );
  const getTimeOffData = async () => {
    const result = await timeOffService.getTimeOffsData(
      initialSetupOrganizationStore?.hrSystem ?? 1,
      false
    );
    timeOffsStore.setUserBalance(result?.userBalance);
    if (initialSetupOrganizationStore.hrSystem === 2) {
      timeOffsStore.setEntitlements(result?.personioEntitlements);
      timeOffsStore.setPersonioBalances(result?.personioBalances);
    }
    timeOffsStore.setTimeOffs(result?.timeOffs);
    timeOffsStore.setTimeOffTypes(result?.timeOffTypes);
    return result;
  };

  const { isPending, error, isFetching } = useQuery({
    queryKey: ["absencesData"],
    queryFn: getTimeOffData,
  });

  if (isFetching || isPending) return <FullScreenSpinner />;
  if (error) return <Error message={t("absencesOwnHr.generalError")} />;

  const acceptRequest = async (timeOffId: number) => {
    try {
      const result = await timeOffService.approveTimeOff(
        timeOffId,
        initialSetupOrganizationStore?.hrSystem ?? 1
      );
      if (result?.status === "success") {
        timeOffsStore.setTimeOffStatus("approved", timeOffId);
        toast.success(t("absencesOwnHr.approve.success"), {
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(t("absencesOwnHr.approve.generalError"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    }
  };

  const denyRequest = async (timeOffId: number) => {
    try {
      const result = await timeOffService.denyTimeOff(
        timeOffId,
        initialSetupOrganizationStore?.hrSystem ?? 1
      );
      if (result?.status === "success") {
        timeOffsStore.setTimeOffStatus("rejected", timeOffId);
        homeStore.setOwnChartData({
          AnnualDays: result?.annualDays,
          TakenDays: result?.balanceDays,
        });
        homeStore.setTeamChartData({
          AnnualDays: result?.teamAnnualDays,
          TakenDays: result?.teamBalanceDays,
        });
        toast.success(t("absencesOwnHr.approve.success"), {
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(t("absencesOwnHr.deny.generalError"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    }
  };

  return (
    <div className="absencesPageContainer">
      {initialSetupOrganizationStore?.hrSystem === 2 ? (
        <PersonioLeave />
      ) : (
        <>
          <AnualLeave />
          <TakenLeave />
        </>
      )}
      <AbsenceRequest />
      <AbsenceTable acceptRequest={acceptRequest} denyRequest={denyRequest} />
    </div>
  );
}
