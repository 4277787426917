import { Image, Input } from "@fluentui/react-components";
import { Formik, Form } from "formik";
import "./TrackLogHourComponent.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { useAttendancesStore } from "../../../store/attendancesStore";

export default function TrackLogHourComponentPersonio({
  data,
  index,
  onDelete,
}: any) {
  const { t } = useTranslation();
  const attendanceStore = useStore(useAttendancesStore);

  const initialValues = {
    date: data?.date || "",
    fromHour: data?.fromHour || "",
    toHour: data?.toHour || "",
    break: data?.break || "",
    project: data?.project
      ? attendanceStore?.projects.find((el) => el.PersonioId === data?.project)
          ?.Name
      : "",
    comment: data?.comment || "",
    duration: data?.duration || "",
  };

  const handleSubmit = (values: any, { setSubmitting }: any) => {
    console.log("");
  };

  const handleDelete = () => {
    onDelete(data?.id);
  };

  const formatTime = (date: Date) => {
    const formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  return (
    <div>
      <Formik key={index} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, touched, isSubmitting, handleChange, handleBlur }) => (
          <Form className="trackLogFormContainer">
            <div className="trackLogContainer">
              <span className="trackLogTitle">
                {t("attendanceOwnHr.attendanceLogHours.track")} {index + 1}
              </span>
              <div className="trackLogDetails">
                <div className="trackLogInputContainer">
                  <label className="trackLogLabel">
                    {t("attendanceOwnHr.attendanceLogHours.date")}
                  </label>
                  <div className="datePickerContainer">
                    <Input
                      name="date"
                      value={
                        values.date
                          ? new Date(values.date).toLocaleDateString()
                          : ""
                      }
                      className="trackLogInput"
                      appearance="filled-lighter"
                      disabled
                      contentAfter={
                        <Image
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/calendar.svg`}
                          alt="calendar"
                        />
                      }
                    />
                  </div>
                </div>
                <div className="trackLogInputContainer">
                  <label className="trackLogLabel">
                    {t("attendanceOwnHr.attendanceLogHours.from")}
                  </label>
                  <div className="datePickerContainer">
                    <DatePicker
                      name="fromHour"
                      value={formatTime(values.fromHour)}
                      className="trackLogDateInput"
                      selected={values.fromHour}
                      onChange={(date) => {}}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      disabled
                    />
                    <Image
                      className="inputClock"
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
                      alt="inputClock"
                    />
                  </div>
                </div>
                <div className="trackLogInputContainer">
                  <label className="trackLogLabel">
                    {t("attendanceOwnHr.attendanceLogHours.to")}
                  </label>
                  <div className="datePickerContainer">
                    <DatePicker
                      name="toHour"
                      value={formatTime(values.toHour)}
                      className="trackLogDateInput"
                      selected={values.toHour}
                      onChange={(date) => {}}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      disabled
                    />
                    <Image
                      className="inputClock"
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
                      alt="inputClock"
                    />
                  </div>
                </div>
                <div className="trackLogInputContainer">
                  <div className="hourDifference">
                    <span className="hourDifferenceText">
                      {values.duration}
                    </span>
                  </div>
                </div>
                <div className="trackLogInputContainer">
                  <label
                    className="trackLogLabel"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {t("personio.attendances.request.breakInMinutes")}
                  </label>
                  <Input
                    name="break"
                    value={values.break}
                    className="trackLogInput"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: "80px" }}
                    disabled
                  />
                </div>
                <div className="trackLogInputContainer">
                  <label className="trackLogLabel">
                    {t("personio.attendances.request.comment")}
                  </label>
                  <Input
                    name="comment"
                    value={values.comment}
                    className="trackLogInput"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                </div>
                <div className="trackLogInputContainer">
                  <label className="trackLogLabel">
                    {t("personio.attendances.request.project")}
                  </label>
                  <Input
                    name="project"
                    value={values.project}
                    className="trackLogInput"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                </div>

                <div className="trackLogInputContainer">
                  <div
                    className="deleteTrackLogContainer"
                    onClick={handleDelete}
                  >
                    <Image
                      className="delete"
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/delete.svg`}
                      alt="delete"
                    />
                    <span className="deleteTextTrackLog">
                      {t("attendanceOwnHr.attendanceLogHours.delete")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
