import httpService from "./http";
import {
  TimeOffType,
  UserBalance,
  TimeOff,
  User,
  OwnDepartment,
  PersonioEntitlements,
  PersonioBalances,
} from "../types";

const getTimeOffTypes = async () => {
  const response = await httpService.get("/time-off/types");
  const timeOffTypes: TimeOffType[] = [];
  response.data.forEach((timeOffTypeData: any) => {
    timeOffTypes.push({
      Id: timeOffTypeData?.TimeOffTypeId,
      Name: timeOffTypeData?.TimeOffTypeName,
    });
  });
  return timeOffTypes;
};

const importCsvFile = async (csvData: any) => {
  const response = await httpService.post("/time-off/import", { csvData });
  const timeOffTypes: TimeOffType[] = [];
  if ("errors" in response.data) {
    return response.data;
  }
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    timeOffTypes.push({
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
      Description: timeOffType?.TimeOffTypeDescription,
    });
  });

  const timeOffs: TimeOff[] = [];
  response?.data?.timeOffs?.forEach((timeOff: any) => {
    let tO: TimeOff = {
      Id: timeOff?.TimeOffId,
      EmployeeId: timeOff?.EmployeeId,
      EmployeeName: timeOff?.OwnEmployee?.EmployeeName,
      DepartmentName: timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: timeOff?.SupervisorId,
      SupervisorName:
        timeOff?.SupervisorName !== "" && timeOff?.SupervisorName !== null
          ? timeOff?.SupervisorName
          : "Unassigned",
      StartDate: timeOff?.TimeOffStartDate,
      EndDate: timeOff?.TimeOffEndDate,
      DaysNumber: timeOff?.TimeOffDaysCount,
      RequestStatus: timeOff?.TimeOffStatus,
      LeaveType: timeOffTypes.find(
        (el: TimeOffType) => el?.Id === timeOff?.TimeOffTypeId
      ),
      RequestDate: timeOff?.RequestedDate,
      UpdatedDate: timeOff?.UpdatedDate,
      Comment: timeOff?.TimeOffComment,
      Overlaps: [],
    };
    if (timeOff?.Overlaps && timeOff?.Overlaps.length > 0) {
      timeOff?.Overlaps?.forEach((overlap: any) => {
        tO?.Overlaps?.push({
          Id: overlap?.overlapId,
          EmployeeId: overlap?.EmployeeId,
          EmployeeName: overlap?.OwnEmployee?.EmployeeName,
          DepartmentName: overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
          SupervisorId: overlap?.SupervisorId,
          SupervisorName:
            overlap?.SupervisorName !== "" && overlap?.SupervisorName !== null
              ? overlap?.SupervisorName
              : "Unassigned",
          StartDate: overlap?.TimeOffStartDate,
          EndDate: overlap?.TimeOffEndDate,
          DaysNumber: overlap?.TimeOffDaysCount,
          RequestStatus: overlap?.TimeOffStatus,
          LeaveType: timeOffTypes.find(
            (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
          ),
          RequestDate: overlap?.RequestedDate,
          UpdatedDate: overlap?.UpdatedDate,
          Comment: overlap?.TimeOffComment,
        });
      });
    }
    timeOffs.push(tO);
  });
  return { timeOffs };
};

const getTimeOffsData = async (hrSystemId: number, isRefresh: boolean) => {
  const response = await httpService.get(
    `/time-off/${hrSystemId}/${isRefresh}`
  );
  const timeOffTypes: TimeOffType[] = [];
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    let tot: TimeOffType = {
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
    };
    if (hrSystemId === 2) {
      tot["TimeOffTypeCategory"] = timeOffType?.TimeOffTypeCategory;
      tot["TimeOffTypeApprovalRequired"] =
        timeOffType?.TimeOffTypeApprovalRequired;
      tot["TimeOffTypeCertificationRequired"] =
        timeOffType?.TimeOffTypeCertificationRequired;
      tot["TimeOffTypeCertificationSubmissionTimeFrame"] =
        timeOffType?.TimeOffTypeCertificationSubmissionTimeFrame;
      tot["TimeOffTypeHalfDayRequestsEnabled"] =
        timeOffType?.TimeOffTypeHalfDayRequestsEnabled;
      tot["TimeOffTypeLegacyCategory"] = timeOffType?.TimeOffTypeLegacyCategory;
      tot["TimeOffTypePersonioId"] = timeOffType?.TimeOffTypePersonioId;
      tot["TimeOffTypeSubstituteOption"] =
        timeOffType?.TimeOffTypeSubstituteOption;
      tot["TimeOffTypeUnit"] = timeOffType?.TimeOffTypeUnit;
    } else {
      tot["Description"] = timeOffType?.TimeOffTypeDescription;
    }
    timeOffTypes.push(tot);
  });
  let userBalance: UserBalance = {};
  let personioEntitlements: PersonioEntitlements[] = [];
  let personioBalances: PersonioBalances[] = [];
  if (hrSystemId === 2) {
    response?.data?.entitlements?.forEach((ent: any) => {
      personioEntitlements.push({
        TimeOffTypeId: ent?.TimeOffTypeId,
        TimeOffTypeName: ent?.TimeOffTypeName,
        TimeOffBalance: ent?.TimeOffBalance,
      });
    });
    response?.data?.balancesPersonio?.forEach((bln: any) => {
      personioBalances.push({
        TimeOffTypeId: bln?.TimeOffTypeId,
        TimeOffTypeName: bln?.TimeOffTypeName,
        TimeOffBalance: bln?.TimeOffBalance,
      });
    });
  } else {
    userBalance = {
      AnnualDays: response?.data?.user?.Employee?.AnnualDays,
      AnnualBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 1
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      BereavementBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 4
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      ParentalBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 3
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      SickBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 2
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      UnpaidBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 5
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    };
  }
  const timeOffs: TimeOff[] = [];
  response?.data?.timeOffs?.forEach((timeOff: any) => {
    let tO: TimeOff = {
      Id: timeOff?.TimeOffId,
      EmployeeId: timeOff?.EmployeeId,
      EmployeeName:
        hrSystemId === 2
          ? timeOff?.PersonioEmployee?.EmployeeName
          : timeOff?.OwnEmployee?.EmployeeName,
      DepartmentName:
        hrSystemId === 2
          ? timeOff?.PersonioEmployee?.PersonioDepartment?.DepartmentName
          : timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
      SupervisorId: timeOff?.SupervisorId,
      SupervisorName:
        timeOff?.SupervisorName !== "" && timeOff?.SupervisorName !== null
          ? timeOff?.SupervisorName
          : "Unassigned",
      StartDate: timeOff?.TimeOffStartDate,
      EndDate: timeOff?.TimeOffEndDate,
      DaysNumber: timeOff?.TimeOffDaysCount,
      RequestStatus:
        hrSystemId === 2 && timeOff?.TimeOffStatus === "requested"
          ? "pending"
          : timeOff?.TimeOffStatus,
      LeaveType: timeOffTypes.find(
        (el: TimeOffType) => el?.Id === timeOff?.TimeOffTypeId
      ),
      RequestDate:
        hrSystemId === 2 ? timeOff?.CreatedAt : timeOff?.RequestedDate,
      UpdatedDate: hrSystemId === 2 ? timeOff?.UpdatedAt : timeOff?.UpdatedDate,
      Comment: timeOff?.TimeOffComment,
      Overlaps: [],
    };
    if (hrSystemId === 2) {
      tO["TimeOffHalfDayStart"] = timeOff?.TimeOffHalfDayStart;
      tO["TimeOffHalfDayEnd"] = timeOff?.TimeOffHalfDayEnd;
      tO["TimeOffPersonioId"] = timeOff?.TimeOffPersonioId;
      tO["TimeOffSkipApproval"] = timeOff?.TimeOffSkipApproval;
      tO["TimeOffTypeCategory"] = timeOff?.TimeOffTypeCategory;
      tO["TimeOffTypeName"] = timeOff?.TimeOffTypeName;
    }
    if (timeOff?.Overlaps && timeOff?.Overlaps.length > 0) {
      timeOff?.Overlaps?.forEach((overlap: any) => {
        let ovlp: TimeOff = {
          Id: overlap?.overlapId,
          EmployeeId: overlap?.EmployeeId,
          EmployeeName:
            hrSystemId === 2
              ? overlap?.PersonioEmployee?.EmployeeName
              : overlap?.OwnEmployee?.EmployeeName,
          DepartmentName:
            hrSystemId === 2
              ? overlap?.PersonioEmployee?.PersonioDepartment?.DepartmentName
              : overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
          SupervisorId: overlap?.SupervisorId,
          SupervisorName:
            overlap?.SupervisorName !== "" && overlap?.SupervisorName !== null
              ? overlap?.SupervisorName
              : "Unassigned",
          StartDate: overlap?.TimeOffStartDate,
          EndDate: overlap?.TimeOffEndDate,
          DaysNumber: overlap?.TimeOffDaysCount,
          RequestStatus:
            hrSystemId === 2 && overlap?.TimeOffStatus === "requested"
              ? "pending"
              : overlap?.TimeOffStatus,
          LeaveType: timeOffTypes.find(
            (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
          ),
          RequestDate:
            hrSystemId === 2 ? overlap?.CreatedAt : overlap?.RequestedDate,
          UpdatedDate:
            hrSystemId === 2 ? overlap?.UpdatedAt : overlap?.UpdatedDate,
          Comment: overlap?.TimeOffComment,
        };
        if (hrSystemId === 2) {
          ovlp["TimeOffHalfDayStart"] = overlap?.TimeOffHalfDayStart;
          ovlp["TimeOffHalfDayEnd"] = overlap?.TimeOffHalfDayEnd;
          ovlp["TimeOffPersonioId"] = overlap?.TimeOffPersonioId;
          ovlp["TimeOffSkipApproval"] = overlap?.TimeOffSkipApproval;
          ovlp["TimeOffTypeCategory"] = overlap?.TimeOffTypeCategory;
          ovlp["TimeOffTypeName"] = overlap?.TimeOffTypeName;
        }
        tO?.Overlaps?.push(ovlp);
      });
    }
    timeOffs.push(tO);
  });
  return {
    annualDays: response?.data?.annualDays,
    personioEntitlements,
    personioBalances,
    balanceDays: response?.data?.balanceDays,
    teamAnnualDays: response?.data?.teamAnnualDays,
    teamBalanceDays: response?.data?.teamBalanceDays,
    timeOffTypes,
    userBalance,
    timeOffs,
  };
};

const addTimeOff = async (
  startDate: any,
  endDate: any,
  typeId: number,
  comment: string,
  partOfDay: string,
  firstDay: string,
  lastDay: string,
  hrSystemId: number
) => {
  const response = await httpService.post(`/time-off/add/${hrSystemId}`, {
    startDate,
    endDate,
    typeId,
    comment,
    partOfDay,
    firstDay,
    lastDay,
  });
  if ("status" in response?.data && response?.data?.status === "error") {
    return response.data;
  }
  const timeOffTypes: TimeOffType[] = [];
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    let tot: TimeOffType = {
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
    };
    if (hrSystemId === 2) {
      tot["TimeOffTypeCategory"] = timeOffType?.TimeOffTypeCategory;
      tot["TimeOffTypeApprovalRequired"] =
        timeOffType?.TimeOffTypeApprovalRequired;
      tot["TimeOffTypeCertificationRequired"] =
        timeOffType?.TimeOffTypeCertificationRequired;
      tot["TimeOffTypeCertificationSubmissionTimeFrame"] =
        timeOffType?.TimeOffTypeCertificationSubmissionTimeFrame;
      tot["TimeOffTypeHalfDayRequestsEnabled"] =
        timeOffType?.TimeOffTypeHalfDayRequestsEnabled;
      tot["TimeOffTypeLegacyCategory"] = timeOffType?.TimeOffTypeLegacyCategory;
      tot["TimeOffTypePersonioId"] = timeOffType?.TimeOffTypePersonioId;
      tot["TimeOffTypeSubstituteOption"] =
        timeOffType?.TimeOffTypeSubstituteOption;
      tot["TimeOffTypeUnit"] = timeOffType?.TimeOffTypeUnit;
    } else {
      tot["Description"] = timeOffType?.TimeOffTypeDescription;
    }
    timeOffTypes.push(tot);
  });
  let timeOff: TimeOff = {
    Id: response?.data?.timeOff?.TimeOffId,
    EmployeeId: response?.data?.timeOff?.EmployeeId,
    EmployeeName:
      hrSystemId === 2
        ? response?.data?.timeOff?.PersonioEmployee?.EmployeeName
        : response?.data?.timeOff?.OwnEmployee?.EmployeeName,
    DepartmentName:
      hrSystemId === 2
        ? response?.data?.timeOff?.PersonioEmployee?.PersonioDepartment
            ?.DepartmentName
        : response?.data?.timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
    SupervisorId: response?.data?.timeOff?.SupervisorId,
    SupervisorName:
      response?.data?.timeOff?.SupervisorName !== "" &&
      response?.data?.timeOff?.SupervisorName !== null
        ? response?.data?.timeOff?.SupervisorName
        : "Unassigned",
    StartDate: response?.data?.timeOff?.TimeOffStartDate,
    EndDate: response?.data?.timeOff?.TimeOffEndDate,
    DaysNumber: response?.data?.timeOff?.TimeOffDaysCount,
    RequestStatus:
      hrSystemId === 2 && response?.data?.timeOff?.TimeOffStatus === "requested"
        ? "pending"
        : response?.data?.timeOff?.TimeOffStatus,
    LeaveType: timeOffTypes.find(
      (el: TimeOffType) => el?.Id === response?.data?.timeOff?.TimeOffTypeId
    ),
    RequestDate:
      hrSystemId === 2
        ? response?.data?.timeOff?.CreatedAt
        : response?.data?.timeOff?.RequestedDate,
    UpdatedDate:
      hrSystemId === 2
        ? response?.data?.timeOff?.UpdatedAt
        : response?.data?.timeOff?.UpdatedDate,
    Comment: response?.data?.timeOff?.TimeOffComment,
    Overlaps: [],
  };
  if (hrSystemId === 2) {
    timeOff["TimeOffHalfDayStart"] =
      response?.data?.timeOff?.TimeOffHalfDayStart;
    timeOff["TimeOffHalfDayEnd"] = response?.data?.timeOff?.TimeOffHalfDayEnd;
    timeOff["TimeOffPersonioId"] = response?.data?.timeOff?.TimeOffPersonioId;
    timeOff["TimeOffSkipApproval"] =
      response?.data?.timeOff?.TimeOffSkipApproval;
    timeOff["TimeOffTypeCategory"] =
      response?.data?.timeOff?.TimeOffTypeCategory;
    timeOff["TimeOffTypeName"] = response?.data?.timeOff?.TimeOffTypeName;
  }
  if (
    response?.data?.timeOff?.Overlaps &&
    response?.data?.timeOff?.Overlaps.length > 0
  ) {
    response?.data?.timeOff?.Overlaps?.forEach((overlap: any) => {
      let ovlp: TimeOff = {
        Id: overlap?.overlapId,
        EmployeeId: overlap?.EmployeeId,
        EmployeeName:
          hrSystemId === 2
            ? overlap?.PersonioEmployee?.EmployeeName
            : overlap?.OwnEmployee?.EmployeeName,
        DepartmentName:
          hrSystemId === 2
            ? overlap?.PersonioEmployee?.PersonioDepartment?.DepartmentName
            : overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
        SupervisorId: overlap?.SupervisorId,
        SupervisorName:
          overlap?.SupervisorName !== "" && overlap?.SupervisorName != null
            ? overlap?.SupervisorName
            : "Unassigned",
        StartDate: overlap?.TimeOffStartDate,
        EndDate: overlap?.TimeOffEndDate,
        DaysNumber: overlap?.TimeOffDaysCount,
        RequestStatus:
          hrSystemId === 2 && overlap?.TimeOffStatus === "requested"
            ? "pending"
            : overlap?.TimeOffStatus,
        LeaveType: timeOffTypes.find(
          (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
        ),
        RequestDate:
          hrSystemId === 2 ? overlap?.CreatedAt : overlap?.RequestedDate,
        UpdatedDate:
          hrSystemId === 2 ? overlap?.UpdatedAt : overlap?.UpdatedDate,
        Comment: overlap?.TimeOffComment,
      };
      if (hrSystemId === 2) {
        ovlp["TimeOffHalfDayStart"] = overlap?.TimeOffHalfDayStart;
        ovlp["TimeOffHalfDayEnd"] = overlap?.TimeOffHalfDayEnd;
        ovlp["TimeOffPersonioId"] = overlap?.TimeOffPersonioId;
        ovlp["TimeOffSkipApproval"] = overlap?.TimeOffSkipApproval;
        ovlp["TimeOffTypeCategory"] = overlap?.TimeOffTypeCategory;
        ovlp["TimeOffTypeName"] = overlap?.TimeOffTypeName;
      }
      timeOff?.Overlaps?.push(ovlp);
    });
  }
  let userBalance: UserBalance = {};
  let personioEntitlements: PersonioEntitlements[] = [];
  let personioBalances: PersonioBalances[] = [];
  if (hrSystemId === 2) {
    response?.data?.entitlements?.forEach((ent: any) => {
      personioEntitlements.push({
        TimeOffTypeId: ent?.TimeOffTypeId,
        TimeOffTypeName: ent?.TimeOffTypeName,
        TimeOffBalance: ent?.TimeOffBalance,
      });
    });
    response?.data?.balancesPersonio?.forEach((bln: any) => {
      personioBalances.push({
        TimeOffTypeId: bln?.TimeOffTypeId,
        TimeOffTypeName: bln?.TimeOffTypeName,
        TimeOffBalance: bln?.TimeOffBalance,
      });
    });
  } else {
    userBalance = {
      AnnualDays: response?.data?.user?.Employee?.AnnualDays,
      AnnualBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 1
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      BereavementBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 4
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      ParentalBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 3
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      SickBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 2
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      UnpaidBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 5
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    };
  }

  return { timeOff, userBalance, personioEntitlements, personioBalances };
};

const approveTimeOff = async (timeOffId: number, hrSystemId: number) => {
  const response = await httpService.patch(`/time-off/approve/${hrSystemId}`, {
    timeOffId,
  });
  return response.data;
};

const denyTimeOff = async (timeOffId: number, hrSystemId: number) => {
  const response = await httpService.patch(`/time-off/deny/${hrSystemId}`, {
    timeOffId,
  });
  return response.data;
};

const modalsData = async (hrSystemId: number) => {
  const response = await httpService.get(`/time-off/modals-data/${hrSystemId}`);
  const users: User[] = [];
  response?.data?.users?.forEach((user: any) => {
    let usr: User = {
      Id: user?.UserId,
      FirstName: user?.FirstName,
      LastName: user?.LastName,
      Email: user?.Email,
      Role: [
        {
          Type: "builtIn",
          Id: user?.Roles[0]?.RoleId,
          RoleName: user?.Roles[0]?.RoleName,
        },
      ],
    };
    if (hrSystemId === 2) {
      usr["EmployeeId"] = user?.PersonioEmployee?.EmployeeId;
      usr["Department"] = user?.PersonioEmployee?.PersonioDepartment
        ? {
            Id: user?.PersonioEmployee?.PersonioDepartment?.DepartmentId,
            Name: user?.PersonioEmployee?.PersonioDepartment?.DepartmentName,
          }
        : null;
    } else {
      usr["EmployeeId"] = user?.Employee?.EmployeeId;
      usr["Department"] = user?.OwnDepartment
        ? {
            Id: user?.OwnDepartment?.DepartmentId,
            Name: user?.OwnDepartment?.DepartmentName,
          }
        : null;
    }
    users.push(usr);
  });
  const departments: OwnDepartment[] = [];
  response?.data?.departments?.forEach((department: any) => {
    departments.push({
      Id: department?.DepartmentId,
      Name: department?.DepartmentName,
    });
  });
  const managers: User[] = [];
  response?.data?.managers?.forEach((manager: any) => {
    managers.push({
      Id: manager?.UserId,
      EmployeeId: manager?.Employee?.EmployeeId,
      FirstName: manager?.FirstName,
      LastName: manager?.LastName,
      Email: manager?.Email,
      Role: [
        {
          Type: "builtIn",
          Id: manager?.Roles[0]?.RoleId,
          RoleName: manager?.Roles[0]?.RoleName,
        },
      ],
    });
  });
  return { users, departments, managers };
};

const addEmployeeTimeOff = async (
  startDate: any,
  endDate: any,
  typeId: number,
  status: string,
  userId: number,
  departmentId: number,
  comment: string,
  hrSystemId: number,
  partOfDay?: string,
  firstDay?: string,
  lastDay?: string
) => {
  const response = await httpService.post(
    `/time-off/employee/add/${hrSystemId}`,
    {
      startDate,
      endDate,
      typeId,
      status,
      userId,
      departmentId,
      comment,
      partOfDay,
      firstDay,
      lastDay,
    }
  );
  if ("status" in response?.data && response?.data?.status === "error") {
    return response?.data;
  }
  const timeOffTypes: TimeOffType[] = [];
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    let tot: TimeOffType = {
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
    };
    if (hrSystemId === 2) {
      tot["TimeOffTypeCategory"] = timeOffType?.TimeOffTypeCategory;
      tot["TimeOffTypeApprovalRequired"] =
        timeOffType?.TimeOffTypeApprovalRequired;
      tot["TimeOffTypeCertificationRequired"] =
        timeOffType?.TimeOffTypeCertificationRequired;
      tot["TimeOffTypeCertificationSubmissionTimeFrame"] =
        timeOffType?.TimeOffTypeCertificationSubmissionTimeFrame;
      tot["TimeOffTypeHalfDayRequestsEnabled"] =
        timeOffType?.TimeOffTypeHalfDayRequestsEnabled;
      tot["TimeOffTypeLegacyCategory"] = timeOffType?.TimeOffTypeLegacyCategory;
      tot["TimeOffTypePersonioId"] = timeOffType?.TimeOffTypePersonioId;
      tot["TimeOffTypeSubstituteOption"] =
        timeOffType?.TimeOffTypeSubstituteOption;
      tot["TimeOffTypeUnit"] = timeOffType?.TimeOffTypeUnit;
    } else {
      tot["Description"] = timeOffType?.TimeOffTypeDescription;
    }
    timeOffTypes.push(tot);
  });
  let timeOff: TimeOff = {
    Id: response?.data?.timeOff?.TimeOffId,
    EmployeeId: response?.data?.timeOff?.EmployeeId,
    EmployeeName:
      hrSystemId === 2
        ? response?.data?.timeOff?.PersonioEmployee?.EmployeeName
        : response?.data?.timeOff?.OwnEmployee?.EmployeeName,
    DepartmentName:
      hrSystemId === 2
        ? response?.data?.timeOff?.PersonioEmployee?.PersonioDepartment
            ?.DepartmentName
        : response?.data?.timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
    SupervisorId: response?.data?.timeOff?.SupervisorId,
    SupervisorName:
      response?.data?.timeOff?.SupervisorName !== "" &&
      response?.data?.timeOff?.SupervisorName !== null
        ? response?.data?.timeOff?.SupervisorName
        : "Unassigned",
    StartDate: response?.data?.timeOff?.TimeOffStartDate,
    EndDate: response?.data?.timeOff?.TimeOffEndDate,
    DaysNumber: response?.data?.timeOff?.TimeOffDaysCount,
    RequestStatus:
      hrSystemId === 2 && response?.data?.timeOff?.TimeOffStatus === "requested"
        ? "pending"
        : response?.data?.timeOff?.TimeOffStatus,
    LeaveType: timeOffTypes.find(
      (el: TimeOffType) => el?.Id === response?.data?.timeOff?.TimeOffTypeId
    ),
    RequestDate:
      hrSystemId === 2
        ? response?.data?.timeOff?.CreatedAt
        : response?.data?.timeOff?.RequestedDate,
    UpdatedDate:
      hrSystemId === 2
        ? response?.data?.timeOff?.UpdatedAt
        : response?.data?.timeOff?.UpdatedDate,
    Comment: response?.data?.timeOff?.TimeOffComment,
    Overlaps: [],
  };
  if (hrSystemId === 2) {
    timeOff["TimeOffHalfDayStart"] =
      response?.data?.timeOff?.TimeOffHalfDayStart;
    timeOff["TimeOffHalfDayEnd"] = response?.data?.timeOff?.TimeOffHalfDayEnd;
    timeOff["TimeOffPersonioId"] = response?.data?.timeOff?.TimeOffPersonioId;
    timeOff["TimeOffSkipApproval"] =
      response?.data?.timeOff?.TimeOffSkipApproval;
    timeOff["TimeOffTypeCategory"] =
      response?.data?.timeOff?.TimeOffTypeCategory;
    timeOff["TimeOffTypeName"] = response?.data?.timeOff?.TimeOffTypeName;
  }
  if (
    response?.data?.timeOff?.Overlaps &&
    response?.data?.timeOff?.Overlaps.length > 0
  ) {
    response?.data?.timeOff?.Overlaps?.forEach((overlap: any) => {
      let ovlp: TimeOff = {
        Id: overlap?.overlapId,
        EmployeeId: overlap?.EmployeeId,
        EmployeeName:
          hrSystemId === 2
            ? overlap?.PersonioEmployee?.EmployeeName
            : overlap?.OwnEmployee?.EmployeeName,
        DepartmentName:
          hrSystemId === 2
            ? overlap?.PersonioEmployee?.PersonioDepartment?.DepartmentName
            : overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
        SupervisorId: overlap?.SupervisorId,
        SupervisorName:
          overlap?.SupervisorName !== "" && overlap?.SupervisorName != null
            ? overlap?.SupervisorName
            : "Unassigned",
        StartDate: overlap?.TimeOffStartDate,
        EndDate: overlap?.TimeOffEndDate,
        DaysNumber: overlap?.TimeOffDaysCount,
        RequestStatus:
          hrSystemId === 2 && overlap?.TimeOffStatus === "requested"
            ? "pending"
            : overlap?.TimeOffStatus,
        LeaveType: timeOffTypes.find(
          (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
        ),
        RequestDate:
          hrSystemId === 2 ? overlap?.CreatedAt : overlap?.RequestedDate,
        UpdatedDate:
          hrSystemId === 2 ? overlap?.UpdatedAt : overlap?.UpdatedDate,
        Comment: overlap?.TimeOffComment,
      };
      if (hrSystemId === 2) {
        ovlp["TimeOffHalfDayStart"] = overlap?.TimeOffHalfDayStart;
        ovlp["TimeOffHalfDayEnd"] = overlap?.TimeOffHalfDayEnd;
        ovlp["TimeOffPersonioId"] = overlap?.TimeOffPersonioId;
        ovlp["TimeOffSkipApproval"] = overlap?.TimeOffSkipApproval;
        ovlp["TimeOffTypeCategory"] = overlap?.TimeOffTypeCategory;
        ovlp["TimeOffTypeName"] = overlap?.TimeOffTypeName;
      }
      timeOff?.Overlaps?.push(ovlp);
    });
  }

  let userBalance: UserBalance = {};
  let personioEntitlements: PersonioEntitlements[] = [];
  let personioBalances: PersonioBalances[] = [];
  if (hrSystemId === 2) {
    response?.data?.entitlements?.forEach((ent: any) => {
      personioEntitlements.push({
        TimeOffTypeId: ent?.TimeOffTypeId,
        TimeOffTypeName: ent?.TimeOffTypeName,
        TimeOffBalance: ent?.TimeOffBalance,
      });
    });
    response?.data?.balancesPersonio?.forEach((bln: any) => {
      personioBalances.push({
        TimeOffTypeId: bln?.TimeOffTypeId,
        TimeOffTypeName: bln?.TimeOffTypeName,
        TimeOffBalance: bln?.TimeOffBalance,
      });
    });
  } else {
    userBalance = {
      AnnualDays: response?.data?.user?.Employee?.AnnualDays,
      AnnualBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 1
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      BereavementBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 4
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      ParentalBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 3
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      SickBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 2
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      UnpaidBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 5
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    };
  }

  return { timeOff, userBalance, personioEntitlements, personioBalances };
};

const editEmployeeTimeOff = async (
  timeOffId: number,
  startDate: any,
  endDate: any,
  typeId: number,
  status: string,
  userId: number,
  departmentId: number,
  comment: string,
  hrSystemId: number,
  partOfDay?: string,
  firstDay?: string,
  lastDay?: string
) => {
  const response = await httpService.patch(
    `/time-off/employee/edit/${hrSystemId}`,
    {
      timeOffId,
      startDate,
      endDate,
      typeId,
      status,
      userId,
      departmentId,
      comment,
      partOfDay,
      firstDay,
      lastDay,
    }
  );
  if ("status" in response?.data && response?.data?.status === "error") {
    return response?.data;
  }
  const timeOffTypes: TimeOffType[] = [];
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    let tot: TimeOffType = {
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
    };
    if (hrSystemId === 2) {
      tot["TimeOffTypeCategory"] = timeOffType?.TimeOffTypeCategory;
      tot["TimeOffTypeApprovalRequired"] =
        timeOffType?.TimeOffTypeApprovalRequired;
      tot["TimeOffTypeCertificationRequired"] =
        timeOffType?.TimeOffTypeCertificationRequired;
      tot["TimeOffTypeCertificationSubmissionTimeFrame"] =
        timeOffType?.TimeOffTypeCertificationSubmissionTimeFrame;
      tot["TimeOffTypeHalfDayRequestsEnabled"] =
        timeOffType?.TimeOffTypeHalfDayRequestsEnabled;
      tot["TimeOffTypeLegacyCategory"] = timeOffType?.TimeOffTypeLegacyCategory;
      tot["TimeOffTypePersonioId"] = timeOffType?.TimeOffTypePersonioId;
      tot["TimeOffTypeSubstituteOption"] =
        timeOffType?.TimeOffTypeSubstituteOption;
      tot["TimeOffTypeUnit"] = timeOffType?.TimeOffTypeUnit;
    } else {
      tot["Description"] = timeOffType?.TimeOffTypeDescription;
    }
    timeOffTypes.push(tot);
  });
  let timeOff: TimeOff = {
    Id: response?.data?.timeOff?.TimeOffId,
    EmployeeId: response?.data?.timeOff?.EmployeeId,
    EmployeeName:
      hrSystemId === 2
        ? response?.data?.timeOff?.PersonioEmployee?.EmployeeName
        : response?.data?.timeOff?.OwnEmployee?.EmployeeName,
    DepartmentName:
      hrSystemId === 2
        ? response?.data?.timeOff?.PersonioEmployee?.PersonioDepartment
            ?.DepartmentName
        : response?.data?.timeOff?.OwnEmployee?.OwnDepartment?.DepartmentName,
    SupervisorId: response?.data?.timeOff?.SupervisorId,
    SupervisorName:
      response?.data?.timeOff?.SupervisorName !== "" &&
      response?.data?.timeOff?.SupervisorName !== null
        ? response?.data?.timeOff?.SupervisorName
        : "Unassigned",
    StartDate: response?.data?.timeOff?.TimeOffStartDate,
    EndDate: response?.data?.timeOff?.TimeOffEndDate,
    DaysNumber: response?.data?.timeOff?.TimeOffDaysCount,
    RequestStatus:
      hrSystemId === 2 && response?.data?.timeOff?.TimeOffStatus === "requested"
        ? "pending"
        : response?.data?.timeOff?.TimeOffStatus,
    LeaveType: timeOffTypes.find(
      (el: TimeOffType) => el?.Id === response?.data?.timeOff?.TimeOffTypeId
    ),
    RequestDate:
      hrSystemId === 2
        ? response?.data?.timeOff?.CreatedAt
        : response?.data?.timeOff?.RequestedDate,
    UpdatedDate:
      hrSystemId === 2
        ? response?.data?.timeOff?.UpdatedAt
        : response?.data?.timeOff?.UpdatedDate,
    Comment: response?.data?.timeOff?.TimeOffComment,
    Overlaps: [],
  };
  if (hrSystemId === 2) {
    timeOff["TimeOffHalfDayStart"] =
      response?.data?.timeOff?.TimeOffHalfDayStart;
    timeOff["TimeOffHalfDayEnd"] = response?.data?.timeOff?.TimeOffHalfDayEnd;
    timeOff["TimeOffPersonioId"] = response?.data?.timeOff?.TimeOffPersonioId;
    timeOff["TimeOffSkipApproval"] =
      response?.data?.timeOff?.TimeOffSkipApproval;
    timeOff["TimeOffTypeCategory"] =
      response?.data?.timeOff?.TimeOffTypeCategory;
    timeOff["TimeOffTypeName"] = response?.data?.timeOff?.TimeOffTypeName;
  }
  if (
    response?.data?.timeOff?.Overlaps &&
    response?.data?.timeOff?.Overlaps.length > 0
  ) {
    response?.data?.timeOff?.Overlaps?.forEach((overlap: any) => {
      let ovlp: TimeOff = {
        Id: overlap?.overlapId,
        EmployeeId: overlap?.EmployeeId,
        EmployeeName:
          hrSystemId === 2
            ? overlap?.PersonioEmployee?.EmployeeName
            : overlap?.OwnEmployee?.EmployeeName,
        DepartmentName:
          hrSystemId === 2
            ? overlap?.PersonioEmployee?.PersonioDepartment?.DepartmentName
            : overlap?.OwnEmployee?.OwnDepartment?.DepartmentName,
        SupervisorId: overlap?.SupervisorId,
        SupervisorName:
          overlap?.SupervisorName !== "" && overlap?.SupervisorName != null
            ? overlap?.SupervisorName
            : "Unassigned",
        StartDate: overlap?.TimeOffStartDate,
        EndDate: overlap?.TimeOffEndDate,
        DaysNumber: overlap?.TimeOffDaysCount,
        RequestStatus:
          hrSystemId === 2 && overlap?.TimeOffStatus === "requested"
            ? "pending"
            : overlap?.TimeOffStatus,
        LeaveType: timeOffTypes.find(
          (el: TimeOffType) => el?.Id === overlap?.TimeOffTypeId
        ),
        RequestDate:
          hrSystemId === 2 ? overlap?.CreatedAt : overlap?.RequestedDate,
        UpdatedDate:
          hrSystemId === 2 ? overlap?.UpdatedAt : overlap?.UpdatedDate,
        Comment: overlap?.TimeOffComment,
      };
      if (hrSystemId === 2) {
        ovlp["TimeOffHalfDayStart"] = overlap?.TimeOffHalfDayStart;
        ovlp["TimeOffHalfDayEnd"] = overlap?.TimeOffHalfDayEnd;
        ovlp["TimeOffPersonioId"] = overlap?.TimeOffPersonioId;
        ovlp["TimeOffSkipApproval"] = overlap?.TimeOffSkipApproval;
        ovlp["TimeOffTypeCategory"] = overlap?.TimeOffTypeCategory;
        ovlp["TimeOffTypeName"] = overlap?.TimeOffTypeName;
      }
      timeOff?.Overlaps?.push(ovlp);
    });
  }

  let userBalance: UserBalance = {};
  let personioEntitlements: PersonioEntitlements[] = [];
  let personioBalances: PersonioBalances[] = [];
  let takenDays = 0;
  if (hrSystemId === 2) {
    takenDays = response?.data?.balanceDays;
    response?.data?.entitlements?.forEach((ent: any) => {
      personioEntitlements.push({
        TimeOffTypeId: ent?.TimeOffTypeId,
        TimeOffTypeName: ent?.TimeOffTypeName,
        TimeOffBalance: ent?.TimeOffBalance,
      });
    });
    response?.data?.balancesPersonio?.forEach((bln: any) => {
      personioBalances.push({
        TimeOffTypeId: bln?.TimeOffTypeId,
        TimeOffTypeName: bln?.TimeOffTypeName,
        TimeOffBalance: bln?.TimeOffBalance,
      });
    });
  } else {
    takenDays =
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 1
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0;
    userBalance = {
      AnnualDays: response?.data?.user?.Employee?.AnnualDays,
      AnnualBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 1
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      BereavementBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 4
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      ParentalBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 3
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      SickBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 2
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
      UnpaidBalance:
        response?.data?.user?.Employee?.OwnTimeOffTypes.find(
          (el: any) => el?.TimeOffTypeId === 5
        )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    };
  }

  return {
    takenDays,
    annualDays: response?.data?.annualDays,
    balanceDays: response?.data?.balanceDays,
    teamAnnualDays: response?.data?.teamAnnualDays,
    teamBalanceDays: response?.data?.teamBalanceDays,
    timeOff,
    userBalance,
    personioEntitlements,
    personioBalances,
  };
};

const deleteTimeOff = async (timeOffId: number, hrSystemId: number) => {
  const response = await httpService.post(`/time-off/delete/${hrSystemId}`, {
    timeOffId,
  });
  if ("status" in response.data && response.data.status === "error") {
    return response.data;
  }
  const userBalance: UserBalance = {
    AnnualDays: response?.data?.user?.Employee?.AnnualDays,
    AnnualBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 1
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    BereavementBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 4
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    ParentalBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 3
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    SickBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 2
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
    UnpaidBalance:
      response?.data?.user?.Employee?.OwnTimeOffTypes.find(
        (el: any) => el?.TimeOffTypeId === 5
      )?.OwnTimeOffBalance?.TimeOffBalance ?? 0,
  };
  return { userBalance };
};

const timeOffService = {
  getTimeOffTypes,
  importCsvFile,
  getTimeOffsData,
  addTimeOff,
  approveTimeOff,
  denyTimeOff,
  modalsData,
  addEmployeeTimeOff,
  editEmployeeTimeOff,
  deleteTimeOff,
};
export default timeOffService;
