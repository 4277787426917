import { ErrorMessage, Form, Formik } from "formik";
import Modal from "../../../Modal";
import * as Yup from "yup";
import DatePickerComponent from "../DatePicker";
import { toast } from "react-toastify";
import {
  Button,
  Image,
  Input,
  Select,
  Textarea,
  Spinner,
} from "@fluentui/react-components";
import Error from "../../../Error";
import "./AddEmployeePersonioAbsence.css";
import { useEffect, useState } from "react";
import { useTimeOffsStore } from "../../../../store/timeOffsStore";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";
import timeOffService from "../../../../services/timeOff";
import { useQuery } from "@tanstack/react-query";
import { useUserStore } from "../../../../store/userStore";
import { AddEmployeeAbsenceType } from "../../../../types";
import moment from "moment";
import { useHomeStore } from "../../../../store/homeStore";
import DatePicker from "react-datepicker";
import { useInitialSetupOrganizationStore } from "../../../../store/initialSetupOrganizationStore";

const AddEmployeePersonioAbsence: React.FC<AddEmployeeAbsenceType> = ({
  isModalOpen,
  closeModal,
  timeOffId,
}) => {
  const { t } = useTranslation();
  const userStore = useStore(useUserStore);
  const timeOffStore = useStore(useTimeOffsStore);
  const homeStore = useStore(useHomeStore);
  const [dateRangeRequestedDate, setDateRangeRequestedDate] = useState<any>([
    null,
    null,
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [initialValues, setInitialValues] = useState<any>({
    assignedTo: "",
    manager: "",
    department: "",
    leaveType: "",
    requestedDate: [],
    firstDay: "",
    lastDay: "",
    partOfDay: "",
    updatedDate: null,
    overlap: "",
    requestStatus: "",
    comment: "",
  });
  const [formError, setFormError] = useState<string>("");
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );
  const [numberOfDays, setNumberOfDays] = useState<number>(0);

  useEffect(() => {
    if (timeOffId) {
      const timeOff = timeOffStore?.timeOffs?.find(
        (tO) => tO?.Id === timeOffId
      );
      setInitialValues({
        requestedDate: [timeOff?.StartDate, timeOff?.EndDate],
        leaveType: timeOff?.LeaveType?.Id,
        updatedDate: timeOff?.UpdatedDate,
        overlap: timeOff?.Overlaps?.length
          ? timeOff?.Overlaps.length.toString()
          : "",
        requestStatus: timeOff?.RequestStatus,
        assignedTo: data?.users?.find(
          (user) => user?.EmployeeId === timeOff?.EmployeeId
        )?.Id,
        department: data?.users?.find(
          (user) => user?.EmployeeId === timeOff?.EmployeeId
        )?.Department?.Id,
        manager: "",
        comment: timeOff?.Comment,
        partOfDay: timeOff?.TimeOffHalfDayStart
          ? "first-part"
          : timeOff?.TimeOffHalfDayEnd
          ? "second-part"
          : "all-day",
        firstDay: timeOff?.TimeOffHalfDayStart ? "second-part" : "all-day",
        lastDay: timeOff?.TimeOffHalfDayEnd ? "first-part" : "all-day",
      });
      setDateRangeRequestedDate([timeOff?.StartDate, timeOff?.EndDate]);
    } else {
      setInitialValues({
        assignedTo: "",
        manager: "",
        department: "",
        leaveType: "",
        requestedDate: [],
        firstDay: "",
        lastDay: "",
        partOfDay: "",
        updatedDate: null,
        overlap: "",
        requestStatus: "",
        comment: "",
      });
      setDateRangeRequestedDate([null, null]);
    }
  }, [timeOffId]);

  useEffect(() => {
    if (dateRangeRequestedDate.length === 2) {
      const startDate = moment(dateRangeRequestedDate[0]);
      const endDate = moment(dateRangeRequestedDate[1]);
      let overlapDuration = 0;
      let currentDate = startDate.clone();

      while (currentDate.isSameOrBefore(endDate, "day")) {
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          overlapDuration++;
        }
        currentDate.add(1, "day");
      }
      setNumberOfDays(overlapDuration);
    } else {
      setNumberOfDays(0);
    }
  }, [dateRangeRequestedDate]);

  const getModalsData = async () => {
    const result = await timeOffService.modalsData(
      initialSetupOrganizationStore?.hrSystem ?? 1
    );
    return result;
  };

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ["modalsData"],
    queryFn: getModalsData,
  });

  if (isFetching || isPending) return <Spinner style={{ margin: 100 }} />;
  if (error) return <Error message={t("absencesOwnHr.generalError")} />;

  const getImageForAction = (selectedStatus: string) => {
    switch (selectedStatus) {
      case "approved":
        return `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/acceptedStatus.svg`;
      case "pending":
        return `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/pendingStatus.svg`;
      case "rejected":
        return `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/canceledStatus.svg`;
      default:
        return "";
    }
  };

  const handleChangeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    requestedDate: Yup.array()
      .of(
        Yup.date().required(
          t("absencesOwnHr.modals.validations.requestedDateIsRequired")
        )
      )
      .length(
        2,
        t("absencesOwnHr.modals.validations.aValidDateIntervalIsRequired")
      )
      .required(
        t("absencesOwnHr.modals.validations.requestedDateRangeIsRequired")
      ),
    leaveType: Yup.string().required(
      t("absencesOwnHr.modals.validations.leaveTypeIsRequired")
    ),
    requestStatus: Yup.string().required(
      t("absencesOwnHr.modals.validations.requestStatusIsRequired")
    ),
    assignedTo: Yup.string().required(
      t("absencesOwnHr.modals.validations.assignedToIsRequired")
    ),
    partOfDay: Yup.string().when(["numberOfDays"], {
      is: () =>
        initialSetupOrganizationStore.hrSystem === 2 &&
        numberOfDays &&
        numberOfDays === 1,
      then: (schema) =>
        schema.required(t("personio.absences.request.required")),
    }),
    firstDay: Yup.string().when(["numberOfDays"], {
      is: () =>
        initialSetupOrganizationStore.hrSystem === 2 &&
        numberOfDays &&
        numberOfDays > 1,
      then: (schema) =>
        schema.required(t("personio.absences.request.required")),
    }),
    lastDay: Yup.string().when(["numberOfDays"], {
      is: () =>
        initialSetupOrganizationStore.hrSystem === 2 &&
        numberOfDays &&
        numberOfDays > 1,
      then: (schema) =>
        schema.required(t("personio.absences.request.required")),
    }),
  });

  const handleSubmit = async (
    values: any,
    { setSubmitting, resetForm }: any
  ) => {
    try {
      if (timeOffId) {
        const result = await timeOffService.editEmployeeTimeOff(
          timeOffId,
          moment(values?.requestedDate[0]).format("YYYY-MM-DD"),
          moment(values?.requestedDate[1]).format("YYYY-MM-DD"),
          values?.leaveType,
          values?.requestStatus,
          values?.assignedTo,
          values?.department,
          values?.comment,
          initialSetupOrganizationStore.hrSystem ?? 1,
          values?.partOfDay,
          values?.firstDay,
          values?.lastDay
        );
        if (
          "status" in result &&
          result?.status === "error" &&
          result?.message === "timeOffOverlapsWithExisting"
        ) {
          toast.error(t(`absencesOwnHr.modals.errors.${result?.message}`), {
            position: "bottom-right",
            progressStyle: { color: "rgb(196, 49, 75)" },
          });
        } else if ("status" in result && result?.status === "error") {
          if (result?.message === "userNotAuthorized") {
            toast.error(t(`absencesOwnHr.modals.errors.${result?.message}`), {
              position: "bottom-right",
              progressStyle: { color: "rgb(196, 49, 75)" },
            });
          } else {
            setFormError(
              t("absencesOwnHr.modals.notEnoughDays") + (result?.daysLeft ?? 0)
            );
          }
        } else {
          timeOffStore.replaceTimeOffById(result?.timeOff);
          timeOffStore.setUserBalance(result?.userBalance);
          if (initialSetupOrganizationStore.hrSystem === 2) {
            timeOffStore.setEntitlements(result?.personioEntitlements);
            timeOffStore.setPersonioBalances(result?.personioBalances);
          }
          homeStore.setOwnChartData({
            AnnualDays: result?.annualDays,
            TakenDays: result?.takenDays,
          });
          homeStore.setTeamChartData({
            AnnualDays: result?.teamAnnualDays,
            TakenDays: result?.teamBalanceDays,
          });
          setSubmitting(false);
          setSelectedStatus("");
          closeModal();
          setFormError("");
          setDateRangeRequestedDate([null, null]);
          resetForm({
            values: {
              requestedDate: [],
              leaveType: "",
              updatedDate: null,
              overlap: "",
              requestStatus: "",
              assignedTo: "",
              department: "",
              manager: "",
              comment: "",
            },
          });
          toast.success(t("absencesOwnHr.modals.requestSent"), {
            position: "bottom-right",
          });
        }
      } else {
        const result = await timeOffService.addEmployeeTimeOff(
          moment(values?.requestedDate[0]).format("YYYY-MM-DD"),
          moment(values?.requestedDate[1]).format("YYYY-MM-DD"),
          values?.leaveType,
          values?.requestStatus,
          values?.assignedTo,
          values?.department,
          values?.comment,
          initialSetupOrganizationStore?.hrSystem ?? 1,
          values?.partOfDay,
          values?.firstDay,
          values?.lastDay
        );
        if (
          "status" in result &&
          result?.status === "error" &&
          result?.message === "timeOffOverlapsWithExisting"
        ) {
          toast.error(t(`absencesOwnHr.modals.errors.${result?.message}`), {
            position: "bottom-right",
            progressStyle: { color: "rgb(196, 49, 75)" },
          });
        } else if ("status" in result && result?.status === "error") {
          if (result?.message === "userNotAuthorized") {
            toast.error(t(`absencesOwnHr.modals.errors.${result?.message}`), {
              position: "bottom-right",
              progressStyle: { color: "rgb(196, 49, 75)" },
            });
          } else {
            setFormError(
              t("absencesOwnHr.modals.notEnoughDays") + (result?.daysLeft ?? 0)
            );
          }
        } else {
          timeOffStore.addTimeOff(result?.timeOff);
          timeOffStore.setUserBalance(result?.userBalance);
          if (initialSetupOrganizationStore.hrSystem === 2) {
            timeOffStore.setEntitlements(result?.personioEntitlements);
            timeOffStore.setPersonioBalances(result?.personioBalances);
          }
          setSubmitting(false);
          setSelectedStatus("");
          closeModal();
          setFormError("");
          setDateRangeRequestedDate([null, null]);
          resetForm({
            values: {
              requestedDate: [],
              leaveType: "",
              updatedDate: null,
              overlap: "",
              requestStatus: "",
              assignedTo: "",
              department: "",
              manager: "",
              comment: "",
            },
          });
          toast.success(t("absencesOwnHr.modals.requestSent"), {
            position: "bottom-right",
          });
        }
      }
    } catch (e) {
      toast.error(t("absencesOwnHr.modals.generalError"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
      setSubmitting(false);
      setSelectedStatus("");
      closeModal();
      setFormError("");
      setDateRangeRequestedDate([null, null]);
      resetForm({
        values: {
          requestedDate: [],
          leaveType: "",
          updatedDate: null,
          overlap: "",
          requestStatus: "",
          assignedTo: "",
          department: "",
          manager: "",
          comment: "",
        },
      });
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Targo"
        subtitle={t("absencesOwnHr.modals.absences")}
        contentTitle={
          timeOffId
            ? t("absencesOwnHr.modals.editEmployeeAbsence")
            : t("absencesOwnHr.modals.addEmployeeAbsence")
        }
        headerStyle="addEmployeeModalHeader"
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
          }) => (
            <Form className="addEmployeeForm">
              <div className="addEmployeeFormFirstLinePersonio">
                <div className="addEmployeeInputContainerPersonio">
                  <label className="addEmployeeInputLabel">
                    {t("absencesOwnHr.modals.assignedTo")}
                  </label>
                  <Select
                    disabled={
                      timeOffId
                        ? timeOffStore?.timeOffs?.find(
                            (tO) => tO?.Id === timeOffId
                          )?.EmployeeId === userStore?.user?.EmployeeId &&
                          userStore?.user?.Role &&
                          userStore?.user?.Role[0]?.Type === "builtIn" &&
                          userStore?.user?.Role[0]?.Id !== 2
                        : false
                    }
                    name="assignedTo"
                    value={values.assignedTo}
                    className="addEmployeeThirdLineInputs addEmployeeInputStyle"
                    appearance="filled-lighter"
                    onChange={(e) => {
                      handleChange(e);

                      const selectedUser = data?.users?.find(
                        (user) => user?.Id === parseInt(e.target.value)
                      );
                      if (selectedUser) {
                        const userDepartmentId = selectedUser?.Department?.Id;
                        if (userDepartmentId) {
                          setFieldValue("department", userDepartmentId);
                        }
                      }
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled hidden>
                      {t("absencesOwnHr.modals.assignedTo")}
                    </option>
                    {data?.users?.map((user) => (
                      <option value={user?.Id} key={user?.Id}>
                        {user?.FirstName} {user?.LastName}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    name="assignedTo"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainerPersonio">
                  <label className="addEmployeeInputLabel">
                    {t("absencesOwnHr.modals.manager")}
                  </label>
                  <Select
                    disabled
                    name="manager"
                    value={values.manager}
                    className="addEmployeeThirdLineInputs addEmployeeInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {data?.managers
                      ?.filter(
                        (el) =>
                          el?.Id ===
                          timeOffStore?.timeOffs?.find(
                            (tO) => tO?.Id === timeOffId
                          )?.SupervisorId
                      )
                      .map((manager) => (
                        <option key={manager?.Id} value={manager?.Id}>
                          {manager?.FirstName} {manager.LastName}
                        </option>
                      ))}
                  </Select>
                  <ErrorMessage
                    name="manager"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="addEmployeeFormSecondLinePersonio">
                <div className="addEmployeeInputContainerPersonio">
                  <label className="addEmployeeInputLabel">
                    {t("absencesOwnHr.modals.department")}
                  </label>
                  <Select
                    disabled
                    name="department"
                    value={values.department}
                    className="addEmployeeThirdLineInputs addEmployeeInputStyle"
                    appearance="filled-lighter"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled hidden>
                      {t("absencesOwnHr.modals.selectDepartment")}
                    </option>
                    {data?.departments?.map((department) => (
                      <option key={department?.Id} value={department?.Id}>
                        {department?.Name}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    name="department"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainerPersonio">
                  <label className="addEmployeeInputLabel">
                    {t("absencesOwnHr.modals.type")}
                  </label>
                  <Select
                    name="leaveType"
                    value={values.leaveType}
                    className="addEmployeeSelectTypeInput addEmployeeInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled hidden>
                      {t("absencesOwnHr.modals.selectType")}
                    </option>
                    {timeOffStore?.timeOffTypes?.map((timeOffType) => (
                      <option value={timeOffType?.Id} key={timeOffType?.Id}>
                        {timeOffType.Name}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    name="leaveType"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="addEmployeeFormThirdLinePersonio">
                <div className="addEmployeeInputContainerPersonio">
                  <label
                    className="addEmployeeInputLabel"
                    htmlFor="requestedDate"
                  >
                    {t("absencesOwnHr.modals.requested")}
                  </label>
                  <DatePickerComponent
                    name="requestedDate"
                    value={values.requestedDate}
                    styleProps="addEmployeeDatePickerInput addEmployeeInputStyle"
                    handleChange={(update: any) => {
                      setDateRangeRequestedDate(update);
                      handleChange({
                        target: { name: "requestedDate", value: update },
                      });
                    }}
                    onBlur={handleBlur}
                    dateRange={dateRangeRequestedDate}
                  />
                  <ErrorMessage
                    className="error-message"
                    name="requestedDate"
                    component="div"
                  />
                </div>
                {numberOfDays === 1 ? (
                  <div className="addEmployeeInputContainerPersonio">
                    <label className="addEmployeeInputLabel">
                      {t("personio.absences.table.modal.partOfDay")}
                    </label>
                    <div>
                      <Select
                        name="partOfDay"
                        value={values.partOfDay}
                        className={`addEmployeeStatusInput ${selectedStatus} addEmployeeInputStyle`}
                        appearance="filled-lighter"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled hidden>
                          {t("personio.absences.request.selectDayPart")}
                        </option>
                        <option value="all-day">
                          {t("personio.absences.table.allDay")}
                        </option>
                        <option value="first-part">
                          {t("personio.absences.table.firstPart")}
                        </option>
                        <option value="second-part">
                          {t("personio.absences.table.secondPart")}
                        </option>
                      </Select>
                    </div>
                    <ErrorMessage
                      name="partOfDay"
                      component="div"
                      className="error-message"
                    />
                  </div>
                ) : (
                  <>
                    <div className="addEmployeeInputContainerPersonio addEmployeeInputContainerPersonio3x">
                      <label className="addEmployeeInputLabel">
                        {t("personio.absences.table.modal.firstDay")}
                      </label>
                      <div>
                        <Select
                          name="firstDay"
                          value={values.firstDay}
                          className={`addEmployeeStatusInput ${selectedStatus} addEmployeeInputStyle`}
                          appearance="filled-lighter"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="" disabled hidden>
                            {t("personio.absences.request.selectDayPart")}
                          </option>
                          <option value="all-day">
                            {t("personio.absences.table.allDay")}
                          </option>
                          <option value="second-part">
                            {t("personio.absences.table.secondPart")}
                          </option>
                        </Select>
                      </div>
                      <ErrorMessage
                        name="firstDay"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="addEmployeeInputContainerPersonio addEmployeeInputContainerPersonio3x">
                      <label className="addEmployeeInputLabel">
                        {t("personio.absences.table.modal.lastDay")}
                      </label>
                      <div>
                        <Select
                          name="lastDay"
                          value={values.lastDay}
                          className={`addEmployeeStatusInput ${selectedStatus} addEmployeeInputStyle`}
                          appearance="filled-lighter"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                        >
                          <option value="" disabled hidden>
                            {t("personio.absences.request.selectDayPart")}
                          </option>
                          <option value="all-day">
                            {t("personio.absences.table.allDay")}
                          </option>
                          <option value="first-part">
                            {t("personio.absences.table.firstPart")}
                          </option>
                        </Select>
                      </div>
                      <ErrorMessage
                        name="lastDay"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="addEmployeeFormFourthLinePersonio">
                <div className="addEmployeeInputContainerPersonio">
                  <label
                    className="addEmployeeInputLabel"
                    htmlFor="updatedDate"
                  >
                    {t("absencesOwnHr.modals.updatedDate")}
                  </label>
                  <DatePicker
                    name="updatedDate"
                    selected={values?.updatedDate}
                    onChange={() => {}}
                    className="addEmployeeDatePickerInput addEmployeeInputStyle"
                    dateFormat={values?.updatedDate ? "dd/MM/yyyy" : undefined}
                    disabled
                  />
                  <ErrorMessage
                    className="error-message"
                    name="updatedDate"
                    component="div"
                  />
                </div>
                <div className="addEmployeeInputContainerPersonio addEmployeeInputContainerPersonio3x">
                  <label className="addEmployeeInputLabel">
                    {t("absencesOwnHr.modals.overlap")}
                  </label>
                  <Input
                    disabled
                    name="overlap"
                    value={values.overlap}
                    className="addEmployeeOverlapInput addEmployeeInputStyle"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="overlap"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeInputContainerPersonio addEmployeeInputContainerPersonio3x">
                  <label className="addEmployeeInputLabel">
                    {t("absencesOwnHr.modals.requestStatus")}
                  </label>
                  <div className="inputWithImage">
                    {selectedStatus === "Select status" ||
                    selectedStatus === "" ? (
                      ""
                    ) : (
                      <Image
                        className="acceptedStatus"
                        src={getImageForAction(selectedStatus)}
                        alt=""
                      />
                    )}

                    <Select
                      disabled={
                        timeOffId
                          ? timeOffStore?.timeOffs?.find(
                              (tO) => tO?.Id === timeOffId
                            )?.EmployeeId === userStore?.user?.EmployeeId &&
                            userStore?.user?.Role &&
                            userStore?.user?.Role[0]?.Type === "builtIn" &&
                            userStore?.user?.Role[0]?.Id !== 2
                          : false
                      }
                      name="requestStatus"
                      value={values.requestStatus}
                      className={`addEmployeeStatusInput ${selectedStatus} addEmployeeInputStyle`}
                      appearance="filled-lighter"
                      onChange={(e) => {
                        handleChange(e);
                        handleChangeStatus(e);
                      }}
                      onBlur={handleBlur}
                    >
                      <option value="" disabled hidden>
                        {t("absencesOwnHr.modals.selectedStatus")}
                      </option>
                      <option value="approved">
                        {t("absencesOwnHr.modals.accepted")}
                      </option>
                      <option value="pending">
                        {t("absencesOwnHr.modals.pending")}
                      </option>
                      <option value="rejected">
                        {t("absencesOwnHr.modals.canceled")}
                      </option>
                    </Select>
                  </div>
                  <ErrorMessage
                    name="requestStatus"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="addEmployeeFormFifthLinePersonio">
                <div className="addEmployeeInputContainerComment">
                  <label className="addEmployeeInputLabel">
                    {t("absencesOwnHr.modals.comment")}
                  </label>
                  <Textarea
                    name="comment"
                    value={values.comment}
                    className="addEmployeeCommentInput addEmployeeInputStyle"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="comment"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              {formError !== "" ? (
                <div className="addEmployeeFormGeneralError">{formError}</div>
              ) : null}
              <div className="addEmployeeFormSixthLinePersonio">
                {timeOffId ? (
                  <Button
                    appearance="primary"
                    className="addEmployeeButton"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("absencesOwnHr.modals.saveEdit")}
                  </Button>
                ) : (
                  <Button
                    appearance="primary"
                    className="addEmployeeButton"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("absencesOwnHr.modals.add")}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default AddEmployeePersonioAbsence;
