import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
  TableHeaderCell,
} from "@fluentui/react-components";
import "./AnualOrTakenLeave.css";
import { useTimeOffsStore } from "../../../store/timeOffsStore";
import { useStore } from "zustand";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const PersonioLeave = () => {
  const { t } = useTranslation();
  const timeOffStore = useStore(useTimeOffsStore);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);

  return (
    <>
      <div className="anualOrTakenLeaveContainer">
        <h1 className="anualOrTakenLeaveTitle">
          {t("absencesOwnHr.annualLeave.annualLeave")}
        </h1>
        {screenWidth <= 768 ? (
          <>
            {timeOffStore.entitlements.map((entitlement) => (
              <div
                key={entitlement.TimeOffTypeId}
                className="statisticsContainer"
              >
                <div className="statisticsMobRow">
                  <div className="statisticsItem">
                    {entitlement.TimeOffTypeName}
                  </div>
                  <div className="statisticsValue">
                    {entitlement.TimeOffBalance ?? 0}
                  </div>
                </div>
                <div className="statisticsMobRow">
                  <div className="statisticsItem">
                    {t("absencesOwnHr.annualLeave.takenDays")}
                  </div>
                  <div className="statisticsValue">
                    {timeOffStore.personioBalances.find(
                      (el) => el?.TimeOffTypeId === entitlement?.TimeOffTypeId
                    )?.TimeOffBalance ?? 0}
                  </div>
                </div>
                <div className="statisticsMobRow">
                  <div className="statisticsItem">
                    {t("absencesOwnHr.annualLeave.remainingDays")}
                  </div>
                  <div className="statisticsValue">
                    {(entitlement.TimeOffBalance ?? 0) -
                      (timeOffStore.personioBalances.find(
                        (el) => el?.TimeOffTypeId === entitlement?.TimeOffTypeId
                      )?.TimeOffBalance ?? 0)}
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="statisticsContainer">
            {timeOffStore.entitlements.map((entitlement) => (
              <Table
                key={entitlement.TimeOffTypeId}
                aria-label="Anual Leave table"
              >
                <TableHeader className="statisticsHeader">
                  <TableRow className="statisticsRow">
                    <TableHeaderCell
                      key={entitlement.TimeOffTypeId}
                      className="statisticsItem"
                    >
                      {entitlement.TimeOffTypeName}
                    </TableHeaderCell>
                    <TableHeaderCell className="statisticsItem">
                      {t("absencesOwnHr.annualLeave.takenDays")}
                    </TableHeaderCell>
                    <TableHeaderCell className="statisticsItem">
                      {t("absencesOwnHr.annualLeave.remainingDays")}
                    </TableHeaderCell>
                    <TableHeaderCell className="statisticsItem"></TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow className="statisticsValuesContainer">
                    <TableCell className="statisticsValue">
                      {entitlement.TimeOffBalance ?? 0}
                    </TableCell>
                    <TableCell className="statisticsValue">
                      {timeOffStore.personioBalances.find(
                        (el) => el?.TimeOffTypeId === entitlement?.TimeOffTypeId
                      )?.TimeOffBalance ?? 0}
                    </TableCell>
                    <TableCell className="statisticsValue">
                      {(entitlement.TimeOffBalance ?? 0) -
                        (timeOffStore.personioBalances.find(
                          (el) =>
                            el?.TimeOffTypeId === entitlement?.TimeOffTypeId
                        )?.TimeOffBalance ?? 0)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </div>
        )}
      </div>
      <div className="anualOrTakenLeaveContainer">
        <h1 className="anualOrTakenLeaveTitle">
          {t("absencesOwnHr.taken.takenLeave")}
        </h1>
        {screenWidth <= 768 ? (
          <div className="statisticsContainer">
            {timeOffStore.personioBalances
              .filter((balance) => {
                let excludedIds = timeOffStore.entitlements.map(
                  (item) => item.TimeOffTypeId
                );
                return !excludedIds.includes(balance.TimeOffTypeId);
              })
              .map((balance) => (
                <div key={balance.TimeOffTypeId} className="statisticsMobRow">
                  <div className="statisticsItem">
                    {balance?.TimeOffTypeName}
                  </div>
                  <div className="statisticsValue">
                    {balance?.TimeOffBalance ?? 0}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="statisticsContainer">
            <Table aria-label="Anual Leave table">
              <TableHeader className="statisticsHeader">
                <TableRow className="statisticsRow">
                  {timeOffStore.personioBalances
                    .filter((balance) => {
                      let excludedIds = timeOffStore.entitlements.map(
                        (item) => item.TimeOffTypeId
                      );
                      return !excludedIds.includes(balance.TimeOffTypeId);
                    })
                    .map((balance) => (
                      <TableHeaderCell
                        key={balance.TimeOffTypeId}
                        className="statisticsItem"
                      >
                        {balance?.TimeOffTypeName}
                      </TableHeaderCell>
                    ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow className="statisticsValuesContainer">
                  {timeOffStore.personioBalances
                    .filter((balance) => {
                      let excludedIds = timeOffStore.entitlements.map(
                        (item) => item.TimeOffTypeId
                      );
                      return !excludedIds.includes(balance.TimeOffTypeId);
                    })
                    .map((balance) => (
                      <TableCell
                        key={balance.TimeOffTypeId}
                        className="statisticsValue"
                      >
                        {balance?.TimeOffBalance ?? 0}
                      </TableCell>
                    ))}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};

export default PersonioLeave;
