import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableHeader,
  TableHeaderCell,
  TableRow,
  createTableColumn,
  useTableColumnSizing_unstable,
  useTableFeatures,
  Avatar,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Image,
  Spinner,
  useTableSort,
  TableColumnId,
  Tooltip,
} from "@fluentui/react-components";
import "./EmployeesTable.css";
import Pagination from "../InitialSetupWizard/Pagination";
import AddEmployee from "./Modals/AddEmployee";
import AddEmployeePersonio from "./Modals/AddEmployeePersonio";
import { useEmployeeStore } from "../../store/ownEmployeeManagementStore";
import employeeManagementService from "../../services/employeesManagement";
import { useStore } from "zustand";
import { EmployeeManagement } from "../../types";
import moment from "moment";
import { useUserStore } from "../../store/userStore";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSearchField } from "../../contexts/SearchContext";
import { useFiltersContext } from "../../contexts/FiltersContext";
import helpers from "../../helpers/helpers";
import { useInitialSetupOrganizationStore } from "../../store/initialSetupOrganizationStore";

const columnSizingOptions = {
  name: {
    minWidth: 256,
    defaultWidth: 256,
  },
  position: {
    minWidth: 280,
    defaultWidth: 280,
  },
  contractType: {
    minWidth: 120,
    defaultWidth: 120,
  },
  status: {
    minWidth: 81,
    defaultWidth: 81,
  },
  worksite: {
    minWidth: 95,
    defaultWidth: 95,
  },
  hireDate: {
    minWidth: 104,
    defaultWidth: 104,
  },
  terminationDate: {
    minWidth: 146,
    defaultWidth: 146,
  },
  dailyHours: {
    minWidth: 130,
    defaultWidth: 130,
  },
  annualBalance: {
    minWidth: 140,
    defaultWidth: 140,
  },
  annualLeaveUsed: {
    minWidth: 130,
    defaultWidth: 130,
  },
  annualLeaveRemained: {
    minWidth: 150,
    defaultWidth: 150,
  },
  sickBalance: {
    minWidth: 113,
    defaultWidth: 113,
  },
  parentalBalance: {
    minWidth: 135,
    defaultWidth: 135,
  },
  unpaidBalance: {
    minWidth: 131,
    defaultWidth: 131,
  },
  bereavementBalance: {
    minWidth: 162,
    defaultWidth: 162,
  },
  actions: {
    minWidth: 44,
    defaultWidth: 44,
  },
};

export const EmployeesTable = () => {
  const searchField = useSearchField();
  const { t } = useTranslation();
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );
  const employeeManagementStore = useStore(useEmployeeStore);
  const columnsDef: TableColumnDefinition<EmployeeManagement>[] = [
    createTableColumn<EmployeeManagement>({
      columnId: "name",
      compare: (a, b) => a.Name.localeCompare(b.Name),
      renderHeaderCell: () => (
        <>{t("employeesManagementOwnHr.employeesTable.name")}</>
      ),
    }),
    createTableColumn<EmployeeManagement>({
      columnId: "position",
      compare: (a, b) => {
        if (!a?.Position) return -1;
        if (!b?.Position) return 1;
        return a.Position.localeCompare(b.Position);
      },
      renderHeaderCell: () => (
        <>{t("employeesManagementOwnHr.employeesTable.position")}</>
      ),
    }),
    createTableColumn<EmployeeManagement>({
      columnId: "contractType",
      compare: (a, b) => {
        if (!a?.ContractType) return -1;
        if (!b?.ContractType) return 1;
        return a.ContractType.localeCompare(b.ContractType);
      },
      renderHeaderCell: () => (
        <>{t("employeesManagementOwnHr.employeesTable.contractType")}</>
      ),
    }),
    createTableColumn<EmployeeManagement>({
      columnId: "status",
      compare: (a, b) => a.Status.localeCompare(b.Status),
      renderHeaderCell: () => (
        <>{t("employeesManagementOwnHr.employeesTable.status")}</>
      ),
    }),
    createTableColumn<EmployeeManagement>({
      columnId: "worksite",
      compare: (a, b) => a.Worksite.localeCompare(b.Worksite),
      renderHeaderCell: () => (
        <>{t("employeesManagementOwnHr.employeesTable.worksite")}</>
      ),
    }),
    createTableColumn<EmployeeManagement>({
      columnId: "hireDate",
      compare: (a, b) => {
        if (!a?.HireDate) return -1;
        if (!b?.HireDate) return 1;
        return moment(a?.HireDate).isAfter(moment(b?.HireDate)) ? 1 : -1;
      },
      renderHeaderCell: () => (
        <>{t("employeesManagementOwnHr.employeesTable.hireDate")}</>
      ),
    }),
    createTableColumn<EmployeeManagement>({
      columnId: "terminationDate",
      compare: (a, b) => {
        if (!a?.TerminationDate) return -1;
        if (!b?.TerminationDate) return 1;
        return moment(a?.TerminationDate).isAfter(moment(b?.HireDate)) ? 1 : 1;
      },
      renderHeaderCell: () => (
        <>{t("employeesManagementOwnHr.employeesTable.terminationDate")}</>
      ),
    }),
    createTableColumn<EmployeeManagement>({
      columnId: "dailyHours",
      compare: (a, b) => {
        if (
          typeof a.DailyHours === "number" &&
          typeof b.DailyHours === "number"
        ) {
          return (a.DailyHours ?? 0) - (b.DailyHours ?? 0);
        }
        const aTimeString = a?.DailyHours;
        const bTimeString = b?.DailyHours;
        if (aTimeString && bTimeString) {
          const aTimeSplit = aTimeString.split(":");
          const bTimeSplit = bTimeString.split(":");
          const aTime = parseInt(aTimeSplit[0]) * 60 + parseInt(aTimeSplit[1]);
          const bTime = parseInt(bTimeSplit[0]) * 60 + parseInt(bTimeSplit[1]);
          return bTime - aTime;
        }
        if (!aTimeString && bTimeString) return 1;
        if (aTimeString && !bTimeString) return -1;
        return 0;
      },
      renderHeaderCell: () => (
        <>{t("employeesManagementOwnHr.employeesTable.dailyHours")}</>
      ),
    }),
    createTableColumn<EmployeeManagement>({
      columnId: "actions",
      renderHeaderCell: () => <></>,
    }),
  ];
  if (initialSetupOrganizationStore?.hrSystem === 2) {
    let i = 8;
    employeeManagementStore.employees.forEach((employee) => {
      employee.personioBalances?.forEach((personioBalance) => {
        if (
          employee.personioEntitlements?.some(
            (personioEntitlement) =>
              personioEntitlement.TimeOffTypeId ===
              personioBalance.TimeOffTypeId
          )
        ) {
          if (
            !columnsDef.some(
              (col) =>
                col.columnId ===
                `${
                  employee.personioEntitlements?.find(
                    (personioEntitlement) =>
                      personioEntitlement.TimeOffTypeId ===
                      personioBalance.TimeOffTypeId
                  )?.TimeOffTypeName
                } balance`
            )
          ) {
            columnsDef.splice(
              i,
              0,
              createTableColumn<EmployeeManagement>({
                columnId: `${
                  employee.personioEntitlements?.find(
                    (personioEntitlement) =>
                      personioEntitlement.TimeOffTypeId ===
                      personioBalance.TimeOffTypeId
                  )?.TimeOffTypeName
                } balance`,
                compare: (a, b) =>
                  a?.personioEntitlements?.find(
                    (personioEntitlement) =>
                      personioEntitlement.TimeOffTypeId ===
                      personioBalance.TimeOffTypeId
                  )?.TimeOffBalance ??
                  0 -
                    (b?.personioEntitlements?.find(
                      (personioEntitlement) =>
                        personioEntitlement.TimeOffTypeId ===
                        personioBalance.TimeOffTypeId
                    )?.TimeOffBalance ?? 0),
                renderHeaderCell: () => (
                  <>{`${
                    employee.personioEntitlements?.find(
                      (personioEntitlement) =>
                        personioEntitlement.TimeOffTypeId ===
                        personioBalance.TimeOffTypeId
                    )?.TimeOffTypeName
                  } balance`}</>
                ),
              })
            );
            i++;
          }
        }
        if (
          personioBalance.TimeOffTypeName &&
          !columnsDef.some(
            (col) => col.columnId === `${personioBalance.TimeOffTypeName} taken`
          )
        ) {
          columnsDef.splice(
            i,
            0,
            createTableColumn<EmployeeManagement>({
              columnId: `${personioBalance.TimeOffTypeName} taken`,
              compare: (a, b) =>
                (a?.personioBalances?.find(
                  (pB) => pB?.TimeOffTypeId === personioBalance.TimeOffTypeId
                )?.TimeOffBalance ?? 0) -
                (b?.personioBalances?.find(
                  (pB) => pB?.TimeOffTypeId === personioBalance.TimeOffTypeId
                )?.TimeOffBalance ?? 0),
              renderHeaderCell: () => (
                <>{`${personioBalance.TimeOffTypeName} taken`}</>
              ),
            })
          );
          i++;
        }
      });
    });
  } else {
    if (!columnsDef.some((col) => col.columnId === "annualBalance")) {
      columnsDef.splice(
        8,
        0,
        createTableColumn<EmployeeManagement>({
          columnId: "annualBalance",
          compare: (a, b) => a.Annual - b.Annual,
          renderHeaderCell: () => (
            <>{t("employeesManagementOwnHr.employeesTable.annualBalance")}</>
          ),
        })
      );
    }
    if (!columnsDef.some((col) => col.columnId === "annualLeaveUsed")) {
      columnsDef.splice(
        9,
        0,
        createTableColumn<EmployeeManagement>({
          columnId: "annualLeaveUsed",
          compare: (a, b) => a.AnnualBalance - b.AnnualBalance,
          renderHeaderCell: () => (
            <>{t("employeesManagementOwnHr.employeesTable.annualLeaveUsed")}</>
          ),
        })
      );
    }
    if (!columnsDef.some((col) => col.columnId === "annualLeaveRemained")) {
      columnsDef.splice(
        10,
        0,
        createTableColumn<EmployeeManagement>({
          columnId: "annualLeaveRemained",
          compare: (a, b) =>
            (a.Annual ?? 0) -
            (a.AnnualBalance ?? 0) -
            ((b.Annual ?? 0) - (b.AnnualBalance ?? 0)),
          renderHeaderCell: () => (
            <>
              {t("employeesManagementOwnHr.employeesTable.annualLeaveRemained")}
            </>
          ),
        })
      );
    }
    if (!columnsDef.some((col) => col.columnId === "sickBalance")) {
      columnsDef.splice(
        11,
        0,
        createTableColumn<EmployeeManagement>({
          columnId: "sickBalance",
          compare: (a, b) => a.SickBalance - b.SickBalance,
          renderHeaderCell: () => (
            <>{t("employeesManagementOwnHr.employeesTable.sickBalance")}</>
          ),
        })
      );
    }
    if (!columnsDef.some((col) => col.columnId === "parentalBalance")) {
      columnsDef.splice(
        12,
        0,
        createTableColumn<EmployeeManagement>({
          columnId: "parentalBalance",
          compare: (a, b) => a.ParentalBalance - b.ParentalBalance,
          renderHeaderCell: () => (
            <>{t("employeesManagementOwnHr.employeesTable.parentalBalance")}</>
          ),
        })
      );
    }
    if (!columnsDef.some((col) => col.columnId === "unpaidBalance")) {
      columnsDef.splice(
        13,
        0,
        createTableColumn<EmployeeManagement>({
          columnId: "unpaidBalance",
          compare: (a, b) => a.UnpaidBalance - b.UnpaidBalance,
          renderHeaderCell: () => (
            <>{t("employeesManagementOwnHr.employeesTable.unpaidBalance")}</>
          ),
        })
      );
    }
    if (!columnsDef.some((col) => col.columnId === "bereavementBalance")) {
      columnsDef.splice(
        14,
        0,
        createTableColumn<EmployeeManagement>({
          columnId: "bereavementBalance",
          compare: (a, b) => a.BereavementBalance - b.BereavementBalance,
          renderHeaderCell: () => (
            <>
              {t("employeesManagementOwnHr.employeesTable.bereavementBalance")}
            </>
          ),
        })
      );
    }
  }

  const [loading, setLoading] = useState(false);
  const [columns] =
    useState<TableColumnDefinition<EmployeeManagement>[]>(columnsDef);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [addEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
  const [editEmployeeModalOpen, setEditEmployeeModalOpen] = useState(false);
  const [selectedEditEmployee, setSelectedEditEmployee] = useState<
    number | null
  >(null);
  const [items, setItems] = useState<EmployeeManagement[]>([]);
  const totalPages = Math.ceil(items?.length / itemsPerPage);
  const userStore = useStore(useUserStore);
  const {
    isSelectingFilters,
    selectedNameFilters,
    selectedDepartmentFilters,
    selectedEmployeePositionFilters,
    selectedEmployeeContractTypeFilters,
    selectedEmployeeStatusFilters,
    selectedEmployeeWorksiteFilters,
    hireDateRangeFilter,
    terminationDateRangeFilter,
  } = useFiltersContext();

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      if (addEmployeeModalOpen || editEmployeeModalOpen) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }, [addEmployeeModalOpen, editEmployeeModalOpen]);

  useEffect(() => {
    if (!isSelectingFilters) {
      let filteredItems = employeeManagementStore.employees;
      if (searchField !== "") {
        filteredItems = filteredItems.filter(
          (emp) =>
            emp?.Name?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
            emp?.ContractType?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            ) ||
            emp?.Position?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            ) ||
            emp?.Status?.toLowerCase()?.includes(searchField?.toLowerCase()) ||
            emp?.Worksite?.toLowerCase()?.includes(searchField?.toLowerCase())
        );
      }
      if (selectedNameFilters.length > 0) {
        filteredItems = filteredItems.filter((emp) =>
          selectedNameFilters.includes(emp?.Name)
        );
      }
      if (selectedDepartmentFilters.length > 0) {
        filteredItems = filteredItems.filter((emp) =>
          selectedDepartmentFilters.includes(emp?.DepartmentName)
        );
      }
      if (selectedEmployeePositionFilters.length > 0) {
        filteredItems = filteredItems.filter((emp) =>
          selectedEmployeePositionFilters.includes(emp?.Position)
        );
      }
      if (selectedEmployeeContractTypeFilters.length > 0) {
        filteredItems = filteredItems.filter((emp) => {
          if (emp?.ContractType) {
            let contractType =
              emp?.ContractType[0].toUpperCase() + emp?.ContractType.slice(1);
            return selectedEmployeeContractTypeFilters.includes(contractType);
          }
          return false;
        });
      }
      if (selectedEmployeeStatusFilters.length > 0) {
        filteredItems = filteredItems.filter((emp) => {
          if (emp?.Status) {
            let status = emp?.Status[0].toUpperCase() + emp?.Status.slice(1);
            return selectedEmployeeStatusFilters.includes(status);
          }
          return false;
        });
      }
      if (selectedEmployeeWorksiteFilters.length > 0) {
        filteredItems = filteredItems.filter((emp) => {
          if (emp?.Worksite) {
            let worksite =
              emp?.Worksite[0].toUpperCase() + emp?.Worksite.slice(1);
            return selectedEmployeeWorksiteFilters.includes(worksite);
          }
          return false;
        });
      }
      if (hireDateRangeFilter.length === 2) {
        if (hireDateRangeFilter[0] && hireDateRangeFilter[1]) {
          const startDate = moment(hireDateRangeFilter[0]);
          const endDate = moment(hireDateRangeFilter[1]);
          filteredItems = filteredItems.filter((emp) => {
            if (!emp?.HireDate) return false;
            return (
              moment(emp?.HireDate).isSameOrAfter(startDate) &&
              moment(emp?.HireDate).isSameOrBefore(endDate)
            );
          });
        }
      }
      if (terminationDateRangeFilter.length === 2) {
        if (terminationDateRangeFilter[0] && terminationDateRangeFilter[1]) {
          const startDate = moment(terminationDateRangeFilter[0]);
          const endDate = moment(terminationDateRangeFilter[1]);
          filteredItems = filteredItems.filter((emp) => {
            if (!emp?.TerminationDate) return false;
            return (
              moment(emp?.TerminationDate).isSameOrAfter(startDate) &&
              moment(emp?.TerminationDate).isSameOrBefore(endDate)
            );
          });
        }
      }
      setItems(filteredItems);
      setCurrentPage(1);
    }
  }, [searchField, isSelectingFilters, employeeManagementStore.employees]);

  const closeAddEmployeeModal = () => {
    setAddEmployeeModalOpen(!addEmployeeModalOpen);
  };

  //Edit attendance modal
  const editEmployeeModal = (employeeId: number) => {
    setSelectedEditEmployee(employeeId);
    setEditEmployeeModalOpen(!editEmployeeModalOpen);
  };

  const closeEditEmployeeModal = () => {
    setEditEmployeeModalOpen(!editEmployeeModalOpen);
    setSelectedEditEmployee(null);
  };

  const onRefresh = async () => {
    setLoading(true);
    try {
      const result = await employeeManagementService.getEmployeesManagementData(
        initialSetupOrganizationStore?.hrSystem ?? 1,
        true
      );
      employeeManagementStore.setManagers(result?.managers);
      employeeManagementStore.setEmployees(result?.employees);
      employeeManagementStore.setTimeOffTypes(result?.timeOffTypes);
      employeeManagementStore.setOffices(result?.offices);
      employeeManagementStore.setTeams(result?.teams);
    } catch (e) {
      toast.error(t("employeesManagementOwnHr.errors.refresh"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };
  const {
    getRows,
    columnSizing_unstable,
    tableRef,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: false,
      }),
      useTableSort({
        defaultSortState: { sortColumn: "file", sortDirection: "ascending" },
      }),
    ]
  );

  const currentPageRows = sort(
    getRows((row) => {
      return {
        ...row,
      };
    })
  );
  const getCurrentPageRows = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return currentPageRows.slice(startIndex, endIndex);
  };

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  return (
    <>
      <div className="employeeInfoContainer">
        <span className="title">{t("employeesManagementOwnHr.title")}</span>
        <div className="detailsContainer">
          <span className="details">
            {t("employeesManagementOwnHr.details")}
          </span>

          <div className="employeesTableActionButtons">
            <div className="refreshContainer">
              {loading && <Spinner size="extra-tiny" />}
              {initialSetupOrganizationStore.hrSystem === 2 ? (
                <Tooltip
                  content={t("personio.employeeManagement.table.syncRefresh")}
                  withArrow
                  positioning="below"
                  appearance="inverted"
                  relationship={"label"}
                >
                  <button className="refreshButton" onClick={onRefresh}>
                    {t("employeesManagementOwnHr.refresh")}
                  </button>
                </Tooltip>
              ) : (
                <button className="refreshButton" onClick={onRefresh}>
                  {t("employeesManagementOwnHr.refresh")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner style={{ margin: 100 }} />
      ) : (
        <>
          <div className="employeesTableContainer">
            <Table
              ref={tableRef}
              sortable
              {...columnSizing_unstable.getTableProps()}
              noNativeElements={true}
              className="employeesTable"
            >
              <TableHeader className="employeesHeaderRow">
                <TableRow>
                  {columns.map((column) => (
                    <Menu openOnContext key={column.columnId}>
                      <MenuTrigger>
                        <TableHeaderCell
                          key={column.columnId}
                          {...headerSortProps(column.columnId)}
                          {...columnSizing_unstable.getTableHeaderCellProps(
                            column.columnId
                          )}
                        >
                          {column.renderHeaderCell()}
                        </TableHeaderCell>
                      </MenuTrigger>
                      <MenuPopover>
                        <MenuList>
                          <MenuItem
                            onClick={columnSizing_unstable.enableKeyboardMode(
                              column.columnId
                            )}
                          >
                            Keyboard Column Resizing
                          </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {getCurrentPageRows().map(({ item }) => (
                  <TableRow key={item.Id}>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("name")}
                    >
                      <TableCellLayout
                        truncate
                        media={<Avatar name={item.Name} />}
                      >
                        <div className="employeesNameContainer">
                          <div className="employeesLabelSection">
                            {item.Name}
                          </div>
                          <div className="employeesDepartmentSection">
                            {item.DepartmentName}
                          </div>
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("position")}
                    >
                      <TableCellLayout truncate>
                        <div className="employeesLabelSection">
                          {item.Position}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps(
                        "contractType"
                      )}
                    >
                      <TableCellLayout truncate>
                        <div className="employeesLabelSection">
                          {item.ContractType
                            ? item.ContractType[0].toUpperCase() +
                              item.ContractType.slice(1)
                            : null}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("status")}
                    >
                      <TableCellLayout truncate>
                        <div className="employeesLabelSection">
                          {item.Status
                            ? item.Status[0].toUpperCase() +
                              item?.Status?.slice(1)
                            : null}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("worksite")}
                    >
                      <TableCellLayout truncate>
                        <div className="employeesLabelSection">
                          {item.Worksite
                            ? item?.Worksite[0].toUpperCase() +
                              item?.Worksite.slice(1)
                            : null}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("hireDate")}
                    >
                      <TableCellLayout truncate>
                        <div className="employeesLabelSection">
                          {item.HireDate
                            ? moment(item.HireDate).format("DD/MM/YYYY")
                            : null}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps(
                        "terminationDate"
                      )}
                    >
                      <TableCellLayout truncate>
                        <div className="employeesLabelSection">
                          {item?.TerminationDate
                            ? moment(item.TerminationDate).format("DD/MM/YYYY")
                            : null}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("dailyHours")}
                    >
                      <TableCellLayout truncate>
                        <div className="employeesLabelSection">
                          {item.DailyHours}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps(
                        "annualBalance"
                      )}
                    >
                      <TableCellLayout truncate>
                        <div className="employeesLabelSection">
                          {item.Annual}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    {initialSetupOrganizationStore?.hrSystem === 2 ? (
                      <>
                        {item.personioBalances?.map((personioBalance) => {
                          const cells = [];
                          item.personioEntitlements?.forEach(
                            (personioEntitlement) => {
                              if (
                                personioBalance.TimeOffTypeId ===
                                personioEntitlement.TimeOffTypeId
                              ) {
                                cells.push(
                                  <TableCell
                                    key={`${personioEntitlement.TimeOffTypeName}-balance`}
                                    {...columnSizing_unstable.getTableCellProps(
                                      `${personioEntitlement.TimeOffTypeName} balance`
                                    )}
                                  >
                                    <TableCellLayout truncate>
                                      <div className="employeesLabelSection">
                                        {(initialSetupOrganizationStore?.hrSystem ===
                                        2
                                          ? personioEntitlement.TimeOffBalance
                                          : Math.round(
                                              personioEntitlement.TimeOffBalance
                                            )) ?? 0}
                                      </div>
                                    </TableCellLayout>
                                  </TableCell>
                                );
                              }
                            }
                          );
                          cells.push(
                            <TableCell
                              key={`${personioBalance.TimeOffTypeName}-taken`}
                              {...columnSizing_unstable.getTableCellProps(
                                `${personioBalance.TimeOffTypeName} taken`
                              )}
                            >
                              <TableCellLayout truncate>
                                <div className="employeesLabelSection">
                                  {personioBalance.TimeOffBalance ?? 0}
                                </div>
                              </TableCellLayout>
                            </TableCell>
                          );
                          return cells;
                        })}
                      </>
                    ) : (
                      <>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps(
                            "annualLeaveUsed"
                          )}
                        >
                          <TableCellLayout truncate>
                            <div className="employeesLabelSection">
                              {item.AnnualBalance ?? 0}
                            </div>
                          </TableCellLayout>
                        </TableCell>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps(
                            "annualLeaveRemained"
                          )}
                        >
                          <TableCellLayout truncate>
                            <div className="employeesLabelSection">
                              {(item.Annual ?? 0) - (item.AnnualBalance ?? 0)}
                            </div>
                          </TableCellLayout>
                        </TableCell>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps(
                            "sickBalance"
                          )}
                        >
                          <TableCellLayout truncate>
                            <div className="employeesLabelSection">
                              {item.SickBalance}
                            </div>
                          </TableCellLayout>
                        </TableCell>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps(
                            "parentalBalance"
                          )}
                        >
                          <TableCellLayout truncate>
                            <div className="employeesLabelSection">
                              {item.ParentalBalance}
                            </div>
                          </TableCellLayout>
                        </TableCell>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps(
                            "unpaidBalance"
                          )}
                        >
                          <TableCellLayout truncate>
                            <div className="employeesLabelSection">
                              <span>{item.UnpaidBalance}</span>
                            </div>
                          </TableCellLayout>
                        </TableCell>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps(
                            "bereavementBalance"
                          )}
                        >
                          <TableCellLayout truncate>
                            <div className="employeesLabelSection">
                              <span>{item.BereavementBalance}</span>
                            </div>
                          </TableCellLayout>
                        </TableCell>
                      </>
                    )}

                    {helpers.checkHasPermissions(
                      userStore?.user?.Permissions,
                      "Employees Management",
                      "Can Edit"
                    ) ? (
                      <TableCell
                        {...columnSizing_unstable.getTableCellProps("actions")}
                      >
                        <TableCellLayout className="employeesActionsCell">
                          <div className="employeesActionsSection">
                            <Menu>
                              <MenuTrigger disableButtonEnhancement>
                                <Tooltip
                                  content="Menu action"
                                  withArrow
                                  positioning="below"
                                  appearance="inverted"
                                  relationship={"label"}
                                >
                                  <Image
                                    className="actionIcon"
                                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/horizontalMenu.svg`}
                                    alt="horizontalMenu"
                                  />
                                </Tooltip>
                              </MenuTrigger>

                              <MenuPopover>
                                <MenuList>
                                  <MenuItem
                                    onClick={() => editEmployeeModal(item?.Id)}
                                  >
                                    <span>
                                      {t(
                                        "attendanceOwnHr.detailedAttendanceTable.edit"
                                      )}
                                    </span>
                                  </MenuItem>
                                </MenuList>
                              </MenuPopover>
                            </Menu>
                          </div>
                        </TableCellLayout>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* Add employee modal */}
            {initialSetupOrganizationStore?.hrSystem === 2 ? (
              <AddEmployeePersonio
                isModalOpen={
                  selectedEditEmployee
                    ? editEmployeeModalOpen
                    : addEmployeeModalOpen
                }
                closeModal={
                  selectedEditEmployee
                    ? closeEditEmployeeModal
                    : closeAddEmployeeModal
                }
                employeeId={selectedEditEmployee}
                // initialData={selectedRowData}
              />
            ) : (
              <AddEmployee
                isModalOpen={
                  selectedEditEmployee
                    ? editEmployeeModalOpen
                    : addEmployeeModalOpen
                }
                closeModal={
                  selectedEditEmployee
                    ? closeEditEmployeeModal
                    : closeAddEmployeeModal
                }
                employeeId={selectedEditEmployee}
                // initialData={selectedRowData}
              />
            )}
          </div>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </>
  );
};
