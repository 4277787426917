import { useState } from "react";
import "./AttendancesTable.css";
import {
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { DetailedAttendanceTable } from "./DetailedAttendanceTable";
import { SummarizedAttendanceTable } from "./SummarizedAttendanceTable";
import { useTranslation } from "react-i18next";
import { useFiltersContext } from "../../../../contexts/FiltersContext";

export default function AttendancesTable() {
  const { t } = useTranslation();

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedAttendanceTab(data.value);
  };
  const { selectedAttendanceTab, setSelectedAttendanceTab } =
    useFiltersContext();

  return (
    <div className="attendancesTableContainer">
      <span className="attendancesTableTitle">
        {t("attendanceOwnHr.attendancesTable.title")}
      </span>
      <span className="attendancesTableSubtitle">
        {t("attendanceOwnHr.attendancesTable.info")}
      </span>
      <TabList
        selectedValue={selectedAttendanceTab}
        onTabSelect={onTabSelect}
        className="tabListContainer"
      >
        <Tab
          id="Arrivals"
          value="detailedAttendance"
          className={`tabName ${
            selectedAttendanceTab === "detailedAttendance" ? "selectedTab" : ""
          }`}
        >
          {t("attendanceOwnHr.attendancesTable.detailedAttendance")}
        </Tab>
        <Tab
          id="Departures"
          value="summarizedAttendance"
          className={`tabName ${
            selectedAttendanceTab === "summarizedAttendance"
              ? "selectedTab"
              : ""
          }`}
        >
          {t("attendanceOwnHr.attendancesTable.summarizedAttendance")}
        </Tab>
      </TabList>
      <div className="panels">
        {selectedAttendanceTab === "detailedAttendance" ? (
          <DetailedAttendanceTable />
        ) : selectedAttendanceTab === "summarizedAttendance" ? (
          <SummarizedAttendanceTable />
        ) : null}
      </div>
    </div>
  );
}
