import { ErrorMessage, Form, Formik } from "formik";
import { toast } from "react-toastify";
import Modal from "../../../Modal";
import "./AddEmployeeAttendance.css";
import * as Yup from "yup";
import { Button, Image, Select } from "@fluentui/react-components";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { useAttendancesStore } from "../../../../store/attendancesStore";
import moment from "moment";
import attendanceService from "../../../../services/attendance";
import { useInitialSetupOrganizationStore } from "../../../../store/initialSetupOrganizationStore";

interface IAddEmployeeAttendance {
  isModalOpen: boolean;
  closeModal: () => void;
  attendanceId: any | null;
  refreshSumarized?: () => void;
}

const AddEmployeeAttendance: React.FC<IAddEmployeeAttendance> = ({
  isModalOpen,
  closeModal,
  attendanceId,
  refreshSumarized,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const [fromHour, setFromHour] = useState(new Date());
  const [toHour, setToHour] = useState(
    new Date(new Date().setHours(new Date().getHours() + 1))
  );
  const [initialValues, setInitialValues] = useState<any>({
    date: new Date(),
    type: "",
    location: "",
    assignedTo: "",
    from: new Date(),
    to: new Date(new Date().setHours(new Date().getHours() + 1)),
  });
  const attendancesStore = useStore(useAttendancesStore);
  const [calculatedDifference, setCalculatedDifference] = useState<string>("");
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  useEffect(() => {
    if (attendanceId) {
      const attendance = attendancesStore?.attendances?.find(
        (att) => att?.Id === attendanceId
      );
      if (attendance) {
        setInitialValues({
          date: new Date(attendance?.Date.toDate()),
          type: attendance?.Type.toLowerCase(),
          location: attendance?.Location.toLowerCase(),
          assignedTo: attendancesStore.users.find(
            (user) => user?.EmployeeId === attendance?.EmployeeId
          )?.Id,
          from: new Date(attendance?.StartTime.toDate()),
          to: new Date(attendance?.EndTime.toDate()),
        });
        setFromHour(new Date(attendance?.StartTime.toDate()));
        setToHour(new Date(attendance?.EndTime.toDate()));
      }
    } else {
      setInitialValues({
        date: new Date(),
        type: "",
        location: "",
        assignedTo: "",
        from: new Date(),
        to: new Date(new Date().setHours(new Date().getHours() + 1)),
      });
      setFromHour(new Date());
      setToHour(new Date(new Date().setHours(new Date().getHours() + 1)));
    }
  }, [attendanceId]);

  useEffect(() => {
    calculateHourDifference();
  }, [toHour, fromHour]);

  const validationSchema = Yup.object().shape({
    date: Yup.date().required(
      t("attendanceOwnHr.addEmployeeAttendance.dateRequired")
    ),
    type: Yup.string().required(
      t("attendanceOwnHr.addEmployeeAttendance.typeRequired")
    ),
    location: Yup.string().required(
      t("attendanceOwnHr.addEmployeeAttendance.locationRequired")
    ),
    assignedTo: Yup.string().required(
      t("attendanceOwnHr.addEmployeeAttendance.assignedToRequired")
    ),
    from: Yup.string().required(
      t("attendanceOwnHr.addEmployeeAttendance.startTimeRequired")
    ),
    to: Yup.string()
      .required(t("attendanceOwnHr.addEmployeeAttendance.endTimeRequired"))
      .test(
        "is-after",
        t("attendanceOwnHr.attendanceLogHours.toHourAfterFromHour"),
        function (value) {
          const { from } = this.parent;
          return from && value && new Date(value) >= new Date(from);
        }
      ),
  });

  const calculateHourDifference = () => {
    const difference = moment(toHour).diff(moment(fromHour), "seconds");
    const hours = Math.floor(difference / 3600);
    const minutes = Math.floor((difference % 3600) / 60);
    setCalculatedDifference(`${hours}h${minutes < 10 ? "0" : ""}${minutes}`);
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      if (attendanceId) {
        const result = await attendanceService.editAttendance(
          attendanceId,
          moment(values.date).format("YYYY-MM-DD"),
          values.type,
          values.location,
          values.assignedTo,
          values.from,
          values.to,
          initialSetupOrganizationStore?.hrSystem ?? 1
        );
        if ("attendance" in result) {
          attendancesStore.replaceAttendanceById(result?.attendance);
          if (refreshSumarized) {
            refreshSumarized();
          }
          closeModal();
          toast.success(t("attendanceOwnHr.addEmployeeAttendance.success"), {
            position: "bottom-right",
          });
        } else if ("status" in result && result?.status === "error") {
          toast.error(t(`attendanceOwnHr.modals.errors.${result?.message}`), {
            position: "bottom-right",
            progressStyle: { color: "rgb(196, 49, 75)" },
          });
        }
      } else {
        const result = await attendanceService.addAttendance(
          moment(values.date).format("YYYY-MM-DD"),
          values.type,
          values.location,
          values.assignedTo,
          values.from,
          values.to,
          initialSetupOrganizationStore?.hrSystem ?? 1
        );
        if ("attendance" in result) {
          attendancesStore.addAttendance(result.attendance);
          closeModal();
          toast.success(t("attendanceOwnHr.addEmployeeAttendance.success"), {
            position: "bottom-right",
          });
        } else if ("status" in result && result?.status === "error") {
          toast.error(t(`attendanceOwnHr.modals.errors.${result?.message}`), {
            position: "bottom-right",
            progressStyle: { color: "rgb(196, 49, 75)" },
          });
        }
      }
    } catch (e) {
      toast.error(t("attendanceOwnHr.modals.errors.errorSending"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={t("attendanceOwnHr.addEmployeeAttendance.title")}
        subtitle={t("attendanceOwnHr.addEmployeeAttendance.subtitle")}
        contentTitle={
          attendanceId
            ? t("attendanceOwnHr.addEmployeeAttendance.editEmployeeAttendance")
            : t("attendanceOwnHr.addEmployeeAttendance.addEmployeeAttendance")
        }
        headerStyle="addEmployeeModalHeader"
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, handleChange, handleBlur, values }) => (
            <Form className="addEmployeeAttendanceForm">
              <div className="formFirstLine">
                <div className="addEmployeeAttendanceInputContainer">
                  <label className="addEmployeeAttendanceInputLabel">
                    {t("attendanceOwnHr.addEmployeeAttendance.date")}
                  </label>
                  <DatePicker
                    name="date"
                    value={values.date}
                    selected={date}
                    isClearable
                    className="addEmployeeAttendanceDatePickerInput addEmployeeAttendanceInputStyle"
                    onChange={(date) => {
                      setDate(date!);
                      handleChange({
                        target: { name: "date", value: date },
                      });
                    }}
                    dateFormat="dd/MM/yyyy"
                  />
                  <ErrorMessage
                    className="error-message"
                    name="date"
                    component="div"
                  />
                </div>
              </div>
              <div className="formSecondLine">
                <div className="addEmployeeAttendanceInputContainer">
                  <label className="addEmployeeAttendanceInputLabel">
                    {t("attendanceOwnHr.addEmployeeAttendance.type")}
                  </label>
                  <Select
                    name="type"
                    value={values.type}
                    className="addAttendanceSelectInput addEmployeeInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">
                      {t("attendanceOwnHr.addEmployeeAttendance.selectType")}
                    </option>
                    <option value="work">
                      {" "}
                      {t("attendanceOwnHr.addEmployeeAttendance.work")}
                    </option>
                    <option value="break">
                      {" "}
                      {t("attendanceOwnHr.addEmployeeAttendance.break")}
                    </option>
                  </Select>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="addEmployeeAttendanceInputContainer">
                  <label className="addEmployeeAttendanceInputLabel">
                    {t("attendanceOwnHr.addEmployeeAttendance.location")}
                  </label>
                  <Select
                    name="location"
                    value={values.location}
                    className="addAttendanceSelectInput addEmployeeInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">
                      {t(
                        "attendanceOwnHr.addEmployeeAttendance.selectLocation"
                      )}
                    </option>
                    <option value="remote">
                      {t("attendanceOwnHr.addEmployeeAttendance.remote")}
                    </option>
                    <option value="office">
                      {t("attendanceOwnHr.addEmployeeAttendance.office")}
                    </option>
                  </Select>
                  <ErrorMessage
                    name="location"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="formThirdLine">
                <div className="addEmployeeAttendanceInputContainer">
                  <label className="addEmployeeAttendanceInputLabel">
                    {t("attendanceOwnHr.addEmployeeAttendance.assignedTo")}
                  </label>
                  <Select
                    name="assignedTo"
                    value={values.assignedTo}
                    className="addAttendanceSelectLocationInput addEmployeeInputStyle"
                    appearance="filled-lighter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">
                      {t("attendanceOwnHr.addEmployeeAttendance.assignedTo")}
                    </option>
                    {attendancesStore.users.map((user) => (
                      <option key={user?.Id} value={user?.Id}>
                        {user?.FirstName} {user?.LastName}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    name="assignedTo"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="formFourthLine">
                <div className="addEmployeeAttendanceInputContainer">
                  <label className="addEmployeeAttendanceInputLabel">
                    {t("attendanceOwnHr.addEmployeeAttendance.from")}
                  </label>
                  <div className="datePickerContainer">
                    <DatePicker
                      name="from"
                      className="addEmployeeAttendanceDateInput"
                      selected={fromHour}
                      onChange={(date) => {
                        setFromHour(date!);
                        handleChange({
                          target: { name: "from", value: date },
                        });
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                    <Image
                      className="inputClock"
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
                      alt="inputClock"
                    />
                  </div>
                  <ErrorMessage
                    name="from"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="addEmployeeAttendanceInputContainer">
                  <label className="addEmployeeAttendanceInputLabel">
                    {t("attendanceOwnHr.addEmployeeAttendance.to")}
                  </label>
                  <div className="datePickerContainer">
                    <DatePicker
                      name="to"
                      className="addEmployeeAttendanceDateInput"
                      selected={toHour}
                      onChange={(date) => {
                        setToHour(date!);
                        handleChange({
                          target: { name: "to", value: date },
                        });
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                    <Image
                      className="inputClock"
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
                      alt="inputClock"
                    />
                  </div>
                  <ErrorMessage
                    name="to"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="formFifthLine">
                <div className="addEmployeeAttendanceInputContainer">
                  <div className="hourDifference">
                    <span className="hourDifferenceText">
                      {calculatedDifference}
                    </span>
                  </div>
                </div>
              </div>
              <div className="formSixthLine">
                {attendanceId ? (
                  <Button
                    appearance="primary"
                    className="addEmployeeAttendanceButton"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("attendanceOwnHr.addEmployeeAttendance.saveChange")}
                  </Button>
                ) : (
                  <Button
                    appearance="primary"
                    className="addEmployeeAttendanceButton"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("attendanceOwnHr.addEmployeeAttendance.add")}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default AddEmployeeAttendance;
