import { create } from "zustand";
import { EmployeeStoreState } from "../types";

export const useEmployeeStore = create<EmployeeStoreState>((set) => ({
  employees: [],
  managers: [],
  timeOffTypes: [],
  offices: [],
  teams: [],
  loading: false,
  setTimeOffTypes: (timeOffTypes) => set({ timeOffTypes }),
  setEmployees: (employees) => set({ employees }),
  setManagers: (managers) => set({ managers }),
  setOffices: (offices) => set({ offices }),
  setTeams: (teams) => set({ teams }),
  updateEmployee: (updatedEmployee) => {
    set((state) => ({
      employees: state.employees.map((employee) =>
        employee.Id === updatedEmployee.Id ? updatedEmployee : employee
      ),
    }));
  },
  checkManager: (currentUser) =>
    set((state) => ({
      employees: state.employees.filter(
        (employee) =>
          employee.SupervisorId === currentUser.Id ||
          employee.Id === currentUser.EmployeeId ||
          (currentUser?.Role &&
            currentUser?.Role[0]?.Type === "builtIn" &&
            currentUser?.Role[0]?.Id === 2)
      ),
    })),
  setLoading: (loading) => set({ loading }),
}));
