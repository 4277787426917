import { useState } from "react";
import {
  Button,
  Image,
  Select,
  SelectProps,
  Input,
} from "@fluentui/react-components";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./AttendanceLogHours.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import TrackLogHourComponentPersonio from "./TrackLogHourComponentPersonio";
import { useTranslation } from "react-i18next";
import attendanceService from "../../../services/attendance";
import { useAttendancesStore } from "../../../store/attendancesStore";
import { useStore } from "zustand";
import { toast } from "react-toastify";
import { Track } from "../../../types";
import { useInitialSetupOrganizationStore } from "../../../store/initialSetupOrganizationStore";

export default function AttendanceLogHoursPersonio(props: SelectProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [date, setDate] = useState(new Date());
  const [fromHour, setFromHour] = useState(new Date());
  const [toHour, setToHour] = useState(
    new Date(new Date().setHours(new Date().getHours() + 1))
  );
  const [tracks, setTracks] = useState<Track[]>([]);
  const { t } = useTranslation();
  const attendancesStore = useStore(useAttendancesStore);
  const [loading, setLoading] = useState<boolean>(false);
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCollapse = () => {
    setIsExpanded(false);
    setTracks([]);
  };

  const calculateHourDifference = () => {
    const difference = Math.abs(toHour.getTime() - fromHour.getTime());
    const hours = Math.floor(difference / (1000 * 3600));
    const minutes = Math.floor((difference % (1000 * 3600)) / (1000 * 60));
    return `${hours}h${minutes < 10 ? "0" : ""}${minutes}`;
  };

  const initialValues = {
    date: new Date(),
    fromHour: new Date(),
    toHour: new Date(new Date().setHours(new Date().getHours() + 1)),
    break: "",
    project: "",
    comment: "",
    duration: calculateHourDifference(),
  };

  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .nullable()
      .required(t("attendanceOwnHr.attendanceLogHours.dateIsRequired")),
    fromHour: Yup.string().required(
      t("attendanceOwnHr.attendanceLogHours.hourIsRequired")
    ),
    toHour: Yup.string()
      .required(t("attendanceOwnHr.attendanceLogHours.hourIsRequired"))
      .test(
        "is-after",
        t("attendanceOwnHr.attendanceLogHours.toHourAfterFromHour"),
        function (value) {
          const { fromHour } = this.parent;
          return fromHour && value && new Date(value) >= new Date(fromHour);
        }
      ),
  });

  const handleSubmit = (values: any, { setSubmitting, resetForm }: any) => {
    const duration = calculateHourDifference();
    const updatedValues = {
      ...values,
      duration: duration,
      id: tracks.length + 1,
    };
    setTracks([...tracks, updatedValues] as any);
    setSubmitting(false);
    resetForm({
      values: {
        date: new Date(),
        fromHour: new Date(),
        toHour: new Date(new Date().setHours(new Date().getHours() + 1)),
        duration: calculateHourDifference(),
        break: "",
        project: "",
        comment: "",
      },
    });
    setFromHour(new Date());
    setToHour(new Date(new Date().setHours(new Date().getHours() + 1)));
    setDate(new Date());
  };

  const handleDeleteTrack = (id: number) => {
    setTracks(tracks?.filter((track) => track?.id !== id));
  };

  const sendTracks = async () => {
    setLoading(true);
    try {
      const result = await attendanceService.sendTracks(
        tracks,
        initialSetupOrganizationStore?.hrSystem ?? 1
      );
      if ("status" in result && result?.status === "error") {
        toast.error(
          t(`attendanceOwnHr.attendanceLogHours.errors.${result?.message}`),
          {
            position: "bottom-right",
            progressStyle: { color: "rgb(196, 49, 75)" },
          }
        );
      }
      if ("attendances" in result) {
        attendancesStore.addAttendances(result?.attendances);
        setTracks([]);
        setIsExpanded(false);
        toast.success(t("attendanceOwnHr.attendanceLogHours.success"), {
          position: "bottom-right",
        });
      }
    } catch (e) {
      toast.error(t("attendanceOwnHr.attendanceLogHours.errors.sending"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`attendanceLogHoursContainer ${isExpanded ? "expanded" : ""}`}
    >
      <div className="attendanceLogHoursContent">
        <div
          className={`attendanceLogHoursTitle ${
            isExpanded ? "expandedAttendanceLogHoursTitle" : ""
          }`}
        >
          {t("attendanceOwnHr.attendanceLogHours.attendance")}
        </div>
        <div className="attendanceLogHoursActions">
          {!isExpanded && (
            <>
              <Button
                className="attendanceLogHoursButton"
                appearance="primary"
                onClick={handleExpand}
              >
                {t("attendanceOwnHr.attendanceLogHours.logHours")}
              </Button>
              <Image
                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/arrowDown.svg`}
                alt="arrowDown"
                onClick={handleExpand}
              />
            </>
          )}
          {isExpanded && (
            <Image
              src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/arrowUp.svg`}
              alt="arrowUp"
              onClick={handleCollapse}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
          }) => (
            <Form className="attendanceLogHoursForm">
              <div className="attendanceLogHoursFormContainer">
                <div
                  className="expandedContent"
                  style={{ flexDirection: "column" }}
                >
                  <div className="personioRowContainer">
                    <div className="attendanceLogHoursInputContainer">
                      <label className="defaultAttendanceLogHoursLabel">
                        {t("attendanceOwnHr.attendanceLogHours.date")}
                      </label>

                      <DatePicker
                        name="date"
                        selected={date}
                        className="attendanceLogHoursDatePickerInput"
                        isClearable
                        onChange={(date) => {
                          setDate(date!);
                          handleChange({
                            target: { name: "date", value: date },
                          });
                        }}
                      />
                      <ErrorMessage
                        name="date"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="attendanceLogHoursInputContainer">
                      <label className="defaultAttendanceLogHoursLabel">
                        {t("attendanceOwnHr.attendanceLogHours.from")}
                      </label>
                      <div className="datePickerContainer">
                        <DatePicker
                          name="fromHour"
                          className="attendanceLogHoursDateInput"
                          selected={fromHour}
                          onChange={(date) => {
                            setFromHour(date!);
                            handleChange({
                              target: { name: "fromHour", value: date },
                            });
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                        <Image
                          className="inputClock"
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
                          alt="inputClock"
                        />
                      </div>
                      <ErrorMessage
                        name="fromHour"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="attendanceLogHoursInputContainer">
                      <label className="defaultAttendanceLogHoursLabel">
                        {t("attendanceOwnHr.attendanceLogHours.to")}
                      </label>
                      <div className="datePickerContainer">
                        <DatePicker
                          name="toHour"
                          className="attendanceLogHoursDateInput"
                          selected={toHour}
                          onChange={(date) => {
                            setToHour(date!);
                            handleChange({
                              target: { name: "toHour", value: date },
                            });
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                        <Image
                          className="inputClock"
                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/attendanceOwnHr/clock.svg`}
                          alt="inputClock"
                        />
                      </div>
                      <ErrorMessage
                        name="toHour"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="attendanceLogHoursInputContainer">
                      <label className="defaultAttendanceLogHoursLabel">
                        {t("personio.attendances.request.breakInMinutes")}
                      </label>
                      <Input
                        name="break"
                        type="number"
                        className="absencesInput"
                        style={{ width: "80px" }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="break"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="attendanceLogHoursInputContainer attendanceHoursInputDurationContainer">
                      <div className="hourDifference">
                        <span className="hourDifferenceText">
                          {calculateHourDifference()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="personioRowContainer">
                    <div
                      className="attendanceLogHoursInputContainer"
                      style={{ width: "50%" }}
                    >
                      <label className="defaultAttendanceLogHoursLabel">
                        {t("personio.attendances.request.comment")}
                      </label>
                      <Input
                        name="comment"
                        className="absencesInput"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="comment"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div
                      className="attendanceLogHoursInputContainer"
                      style={{ width: "50%" }}
                    >
                      <label className="defaultAttendanceLogHoursLabel">
                        {t("personio.attendances.request.project")}
                      </label>
                      <Select
                        name="project"
                        className="attendanceLogHoursInput"
                        appearance="filled-lighter"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.project}
                      >
                        <option value="">
                          {t("personio.attendances.request.selectProject")}
                        </option>
                        {attendancesStore.projects.map((project) => (
                          <option value={project.PersonioId} key={project.Id}>
                            {project.Name}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="project"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                </div>
                <div className="addTimeContainer">
                  <Button
                    appearance="primary"
                    type="submit"
                    className="addTimeButton"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? t("attendanceOwnHr.attendanceLogHours.submitting")
                      : t("attendanceOwnHr.attendanceLogHours.addTime")}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      <div>
        {tracks.map((data, index) => (
          <div key={data?.id}>
            <TrackLogHourComponentPersonio
              data={data}
              index={index}
              onDelete={handleDeleteTrack}
            />
          </div>
        ))}
      </div>
      {tracks && tracks.length > 0 && (
        <div className="saveTrackLogHours">
          <Button
            appearance="primary"
            className="addTimeButton"
            onClick={sendTracks}
            disabled={loading}
          >
            {t("attendanceOwnHr.attendanceLogHours.save")}
          </Button>
        </div>
      )}
    </div>
  );
}
