import { Divider, Button, Spinner, Image } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Personio.css";
import { useState } from "react";
import { useStore } from "zustand";
import { useUserStore } from "../../../store/userStore";
import Error from "../../Error";
import helpers from "../../../helpers/helpers";
import settingsService from "../../../services/settings";
import { toast } from "react-toastify";
import { useInitialSetupOrganizationStore } from "../../../store/initialSetupOrganizationStore";

export default function PersonioSettings() {
  const { t } = useTranslation();
  const [syncing, setSyncing] = useState(false);
  const [disconnecting, setDisconnecting] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<string>("1");
  const [selectedDataType, setSelectedDataType] = useState("");
  const userStore = useStore(useUserStore);
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );
  const navigate = useNavigate();

  const handleSyncNow = async () => {
    setSyncing(true);
    try {
      const result = await settingsService.syncPersonio();
      if ("status" in result && result?.status === "success") {
        toast.success(t("settings.manageUsers.success"), {
          position: "bottom-right",
        });
      } else if ("status" in result && result?.status === "error") {
        toast.error(t("personio.settings.integrations.errors.sync"), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      }
    } catch (e) {
      toast.error(t("personio.settings.integrations.errors.sync"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSyncing(false);
    }
  };

  const handleSelectProfile = (event: any) => {
    setSelectedProfile(event.target.value);
  };

  const handleDisconnect = () => {};

  const handleConnect = () => {};

  const handleSelectData = (event: any) => {
    setSelectedDataType(event.target.value);
  };

  const destroyPersonio = async () => {
    setDisconnecting(true);
    try {
      if (selectedDataType === "") {
        toast.error(t("personio.settings.integrations.errors.selectDataType"), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      } else {
        const result = await settingsService.disconnectAccount(
          selectedDataType
        );
        if ("status" in result && result?.status === "success") {
          toast.success(t("personio.settings.integrations.disconnected"), {
            position: "bottom-right",
          });
          initialSetupOrganizationStore.setSetupCompleted(false);
          navigate("/welcome");
        } else {
          toast.error(t("personio.settings.integrations.errors.disconnect"), {
            position: "bottom-right",
            progressStyle: { color: "rgb(196, 49, 75)" },
          });
        }
      }
    } catch (e) {
      toast.error(t("personio.settings.integrations.errors.disconnect"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setDisconnecting(false);
    }
  };

  return (
    <>
      {helpers.checkHasPermissions(
        userStore?.user?.Permissions,
        "Settings Integrations",
        "Can Read"
      ) ? (
        <div className="personioSettingsPage">
          <div className="personioSettingsInfoContainer">
            <div className="title">{t("settings.personio.title")}</div>
            <div className="detailsContainer">
              <span className="details">
                {t("settings.personio.description")}
              </span>
            </div>
          </div>
          <Divider appearance="strong" />
          <div className="personioSettingsInfoContainer">
            <div className="subtitle">
              {t("settings.personio.integrationStatus")}
            </div>
            <div className="subdescription">
              {t(
                "settings.personio.hereSAnExampleOfHowASectionCanBeUsedToGroupInputs"
              )}
            </div>
            <Button
              type="button"
              onClick={handleSyncNow}
              className="settingsSyncNowBtn"
              appearance="primary"
              disabled={
                !helpers.checkHasPermissions(
                  userStore?.user?.Permissions,
                  "Settings Integrations",
                  "Can Edit"
                )
              }
            >
              {syncing ? (
                <span>
                  <Spinner size="extra-tiny" />
                </span>
              ) : null}

              {t("settings.personio.syncNow")}
            </Button>
          </div>
          <Divider appearance="strong" />
          <div className="personioSettingsInfoContainer">
            {/* <div className="subtitle">
              {t("settings.personio.reconnectWithAnotherAccount")}
            </div>
            <div className="subdescription">
              {t("settings.personio.reconnectWithAnotherAccountDescription")}
            </div>
            <div className="personioProfilesSection">
              <div className="personioProfileContainer">
                <div
                  className={`personioProfileCard ${
                    selectedProfile === "1" ? "selected" : ""
                  }`}
                >
                  <div className="personioProfileCardDetailsSection">
                    <Image
                      shape="rounded"
                      height={32}
                      width={32}
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/settings/Medium.png`}
                      alt="profile-pic"
                    />
                    <div className="personioProfileCardDetailsContainer">
                      <div className="personioProfileCardTitle">
                        Personio Profile 1
                      </div>
                      <div className="personioProfileCardEmail">
                        ndjcbhgbc@gmail.com
                      </div>
                    </div>
                  </div>
                </div>
                <div className="personioRadioContainer">
                  <input
                    id="personioProfile1"
                    className="personioProfileRadioInput"
                    type="radio"
                    value={1}
                    name="profiles"
                    checked={selectedProfile === "1"}
                    onChange={handleSelectProfile}
                  />
                  <label
                    htmlFor="personioProfile1"
                    className="personioProfileRadioLabel"
                  >
                    Profile 1
                  </label>
                </div>
              </div>
              <div className="personioProfileContainer">
                <div
                  className={`personioProfileCard ${
                    selectedProfile === "2" ? "selected" : ""
                  }`}
                >
                  <div className="personioProfileCardDetailsSection">
                    <Image
                      shape="rounded"
                      height={32}
                      width={32}
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/settings/Medium.png`}
                      alt="profile-pic"
                    />
                    <div className="personioProfileCardDetailsContainer">
                      <div className="personioProfileCardTitle">
                        Personio Profile 2
                      </div>
                      <div className="personioProfileCardEmail">
                        ndjcbhgbc@gmail.com
                      </div>
                    </div>
                  </div>
                </div>
                <div className="personioRadioContainer">
                  <input
                    id="personioProfile2"
                    className="personioProfileRadioInput"
                    type="radio"
                    value={2}
                    name="profiles"
                    checked={selectedProfile === "2"}
                    onChange={handleSelectProfile}
                  />
                  <label
                    htmlFor="personioProfile2"
                    className="personioProfileRadioLabel"
                  >
                    Profile 2
                  </label>
                </div>
              </div>
              <div className="personioProfileContainer">
                <div
                  className={`personioProfileCard ${
                    selectedProfile === "3" ? "selected" : ""
                  }`}
                >
                  <div className="personioProfileCardDetailsSection">
                    <Image
                      shape="rounded"
                      height={32}
                      width={32}
                      src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/settings/Medium.png`}
                      alt="profile-pic"
                    />
                    <div className="personioProfileCardDetailsContainer">
                      <div className="personioProfileCardTitle">
                        Personio Profile 3
                      </div>
                      <div className="personioProfileCardEmail">
                        ndjcbhgbc@gmail.com
                      </div>
                    </div>
                  </div>
                  <div className="personioProfileCardButtons">
                    <Button
                      className="personioProfileActionDisconnect"
                      type="button"
                      onClick={handleDisconnect}
                      disabled={
                        !helpers.checkHasPermissions(
                          userStore?.user?.Permissions,
                          "Settings Integrations",
                          "Can Delete"
                        )
                      }
                    >
                      {t("settings.personio.disconnect")}
                    </Button>
                    <Button
                      className="personioProfileActionConnect"
                      appearance="primary"
                      type="button"
                      onClick={handleConnect}
                      disabled={
                        !helpers.checkHasPermissions(
                          userStore?.user?.Permissions,
                          "Settings Integrations",
                          "Can Edit"
                        )
                      }
                    >
                      {t("settings.personio.connect")}
                    </Button>
                  </div>
                </div>
                <div className="personioRadioContainer">
                  <input
                    id="personioProfile3"
                    className="personioProfileRadioInput"
                    type="radio"
                    value={3}
                    name="profiles"
                    checked={selectedProfile === "3"}
                    onChange={handleSelectProfile}
                  />
                  <label
                    htmlFor="personioProfile3"
                    className="personioProfileRadioLabe3"
                  >
                    Profile 3
                  </label>
                </div>
              </div>
            </div> */}
            <div className="personioDataSection">
              <div className="personioDataTitle">
                {t("settings.personio.data")}
              </div>
              <div className="personioDataRadioSection">
                <div className="personioDataRadioContainer">
                  <input
                    id="keepData"
                    value="keep-data"
                    name="data"
                    onChange={handleSelectData}
                    checked={selectedDataType === "keep-data"}
                    type="radio"
                    className="personioDataRadioInput"
                  />
                  <label htmlFor="keepData" className="personioDataRadioLabel">
                    {t("settings.personio.keepData")}
                  </label>
                </div>
                <div className="personioDataRadioContainer">
                  <input
                    id="deleteAllData"
                    value="delete-data"
                    name="data"
                    onChange={handleSelectData}
                    checked={selectedDataType === "delete-data"}
                    type="radio"
                    className="personioDataRadioInput"
                  />
                  <label
                    htmlFor="deleteAllData"
                    className="personioDataRadioLabel"
                  >
                    {t("settings.personio.deleteAllData")}
                  </label>
                </div>
              </div>
              <div className="personioDataActionsSection">
                <Button
                  onClick={destroyPersonio}
                  className="personioDataActionDisconnect"
                  type="button"
                  disabled={
                    !helpers.checkHasPermissions(
                      userStore?.user?.Permissions,
                      "Settings Integrations",
                      "Can Delete"
                    )
                  }
                >
                  {disconnecting ? (
                    <span>
                      <Spinner size="extra-tiny" />
                    </span>
                  ) : null}
                  {t("settings.personio.disconnect")}
                </Button>
                {/* <Button
                  className="personioDataActionConnect"
                  appearance="primary"
                  type="button"
                  disabled={
                    !helpers.checkHasPermissions(
                      userStore?.user?.Permissions,
                      "Settings Integrations",
                      "Can Edit"
                    )
                  }
                >
                  {t("settings.personio.connect")}
                </Button> */}
              </div>
            </div>
          </div>
          <Divider appearance="strong" />
        </div>
      ) : (
        <Error message={t("settings.general.notAuthorized")} />
      )}
    </>
  );
}
