import { create } from "zustand";
import { ITimeOffStore } from "../types";

export const useTimeOffsStore = create<ITimeOffStore>((set) => ({
  timeOffTypes: [],
  userBalance: {},
  timeOffs: [],
  entitlements: [],
  personioBalances: [],
  setTimeOffTypes: (timeOffTypes) => set({ timeOffTypes }),
  setUserBalance: (userBalance) => set({ userBalance }),
  setTimeOffs: (timeOffs) => set({ timeOffs }),
  setTimeOffStatus: (status, timeOffId) => {
    set((state) => ({
      timeOffs: state.timeOffs.map((timeOff) =>
        timeOff.Id === timeOffId
          ? { ...timeOff, RequestStatus: status }
          : timeOff
      ),
    }));
  },
  setEntitlements: (entitlements) => set({ entitlements }),
  setPersonioBalances: (personioBalances) => set({ personioBalances }),
  addTimeOff: (newTimeOff) => {
    set((state) => ({ timeOffs: [newTimeOff, ...state.timeOffs] }));
  },
  replaceTimeOffById: (updatedTimeOff) => {
    set((state) => ({
      timeOffs: state.timeOffs.map((timeOff) =>
        timeOff.Id === updatedTimeOff.Id ? updatedTimeOff : timeOff
      ),
    }));
  },
  deleteTimeOff: (timeOffId) => {
    set((state) => ({
      timeOffs: state.timeOffs.filter((timeOff) => timeOff?.Id !== timeOffId),
    }));
  },
}));
