import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableHeader,
  TableHeaderCell,
  TableRow,
  createTableColumn,
  useTableColumnSizing_unstable,
  useTableFeatures,
  Avatar,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Image,
  Spinner,
  useTableSort,
  TableColumnId,
  Tooltip,
} from "@fluentui/react-components";
import Pagination from "../../InitialSetupWizard/Pagination";
import "./AbsenceTable.css";
import OverlapsModal from "./Modals/OverlapsModal";
import AddEmployeeAbsence from "./Modals/AddEmployeeAbsence";
import AddEmployeePersonioAbsence from "./Modals/AddEmployeePersonioAbsence";
import { useTimeOffsStore } from "../../../store/timeOffsStore";
import { useStore } from "zustand";
import { TimeOff } from "../../../types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useUserStore } from "../../../store/userStore";
import timeOffService from "../../../services/timeOff";
import { useHomeStore } from "../../../store/homeStore";
import { DeleteDialog } from "./DeleteDialog";
import { useSearchField } from "../../../contexts/SearchContext";
import { useFiltersContext } from "../../../contexts/FiltersContext";
import helpers from "../../../helpers/helpers";
import { useInitialSetupOrganizationStore } from "../../../store/initialSetupOrganizationStore";

const columnSizingOptions = {
  EmployeeName: {
    minWidth: 164,
    defaultWidth: 164,
  },
  SupervisorName: {
    minWidth: 190,
    defaultWidth: 190,
  },
  StartDate: {
    minWidth: 240,
    defaultWidth: 240,
  },
  FirstDay: { minWidth: 130, defaultWidth: 130 },
  LastDay: { minWidth: 130, defaultWidth: 130 },
  LeaveType: {
    minWidth: 190,
    defaultWidth: 190,
  },
  RequestDate: {
    minWidth: 118,
    defaultWidth: 118,
  },
  UpdatedDate: {
    minWidth: 123,
    defaultWidth: 123,
  },
  Overlaps: {
    minWidth: 90,
    defaultWidth: 90,
  },
  actions: {
    minWidth: 92,
    defaultWidth: 92,
  },
};

interface IAbsenceTable {
  acceptRequest: (timeOffId: number) => void;
  denyRequest: (timeOffId: number) => void;
}

export const AbsenceTable: React.FC<IAbsenceTable> = ({
  acceptRequest,
  denyRequest,
}) => {
  const { t } = useTranslation();
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );
  const columnsDef: TableColumnDefinition<TimeOff>[] = [
    createTableColumn<TimeOff>({
      columnId: "EmployeeName",
      compare: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
      renderHeaderCell: () => <>{t("absencesOwnHr.table.name")}</>,
    }),
    createTableColumn<TimeOff>({
      columnId: "SupervisorName",
      compare: (a, b) => a.SupervisorName.localeCompare(b.SupervisorName),
      renderHeaderCell: () => <>{t("absencesOwnHr.table.managerName")}</>,
    }),
    createTableColumn<TimeOff>({
      columnId: "StartDate",
      compare: (a, b) => {
        return moment(a.StartDate).isAfter(b.StartDate) ? 1 : -1;
      },
      renderHeaderCell: () => <>{t("absencesOwnHr.table.period")}</>,
    }),
    createTableColumn<TimeOff>({
      columnId: "LeaveType",
      compare: (a, b) => {
        if (!a?.LeaveType?.Name) return -1;
        if (!b?.LeaveType?.Name) return 1;
        return a.LeaveType.Name.localeCompare(b.LeaveType.Name);
      },
      renderHeaderCell: () => <>{t("absencesOwnHr.table.leaveType")}</>,
    }),

    createTableColumn<TimeOff>({
      columnId: "RequestDate",
      compare: (a, b) => {
        return moment(a.RequestDate).isAfter(b.RequestDate) ? 1 : -1;
      },
      renderHeaderCell: () => <>{t("absencesOwnHr.table.requestDate")}</>,
    }),
    createTableColumn<TimeOff>({
      columnId: "UpdatedDate",
      compare: (a, b) => {
        return moment(a.UpdatedDate).isAfter(b.UpdatedDate) ? 1 : -1;
      },
      renderHeaderCell: () => <>{t("absencesOwnHr.table.updatedDate")}</>,
    }),
    createTableColumn<TimeOff>({
      columnId: "Overlaps",
      renderHeaderCell: () => <>{t("absencesOwnHr.table.overlap")}</>,
    }),
    createTableColumn<TimeOff>({
      columnId: "actions",
      renderHeaderCell: () => <></>,
    }),
  ];
  if (initialSetupOrganizationStore?.hrSystem === 2) {
    columnsDef.splice(
      3,
      0,
      createTableColumn<TimeOff>({
        columnId: "TimeOffHalfDayStart",
        compare: (a, b) => {
          const boolA = a?.TimeOffHalfDayStart;
          const boolB = b?.TimeOffHalfDayStart;

          if (boolA === boolB) {
            return 0;
          } else if (boolA && !boolB) {
            return 1;
          } else {
            return -1;
          }
        },
        renderHeaderCell: () => (
          <>{t("personio.absences.table.timeOffHalfDayStart")}</>
        ),
      })
    );
    columnsDef.splice(
      4,
      0,
      createTableColumn<TimeOff>({
        columnId: "TimeOffHalfDayEnd",
        compare: (a, b) => {
          const boolA = a?.TimeOffHalfDayEnd;
          const boolB = b?.TimeOffHalfDayEnd;

          if (boolA === boolB) {
            return 0;
          } else if (boolA && !boolB) {
            return 1;
          } else {
            return -1;
          }
        },
        renderHeaderCell: () => (
          <>{t("personio.absences.table.timeOffHalfDayEnd")}</>
        ),
      })
    );
  }
  const searchField = useSearchField();

  const userStore = useStore(useUserStore);
  const [loading, setLoading] = useState(false);
  const [columns] = useState<TableColumnDefinition<TimeOff>[]>(columnsDef);
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [overlapsModalOpen, setOverlapsModalOpen] = useState(false);
  const [selectedOverlapsId, setSelectedOverlapsId] = useState<number | null>(
    null
  );
  const [addEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);
  const [editEmployeeModalOpen, setEditEmployeeModalOpen] = useState(false);
  const [selectedEditTimeOff, setSelectedEditTimeOff] = useState<number | null>(
    null
  );
  const timeOffStore = useStore(useTimeOffsStore);
  const [items, setItems] = useState<TimeOff[]>([]);
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const homeStore = useStore(useHomeStore);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [deleteAbsenceId, setDeleteAbsenceId] = useState<number | null>(null);
  const {
    isSelectingFilters,
    selectedNameFilters,
    selectedSupervisorFilters,
    selectedTimeOffTypeFilters,
    selectedTimeOffStatusFilters,
    dateRangeFilterDate,
  } = useFiltersContext();

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      if (addEmployeeModalOpen || editEmployeeModalOpen || overlapsModalOpen) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }, [addEmployeeModalOpen, editEmployeeModalOpen, overlapsModalOpen]);

  useEffect(() => {
    if (!isSelectingFilters) {
      let filteredItems = timeOffStore.timeOffs;
      if (searchField !== "") {
        filteredItems = filteredItems.filter(
          (tO) =>
            tO?.EmployeeName?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            ) ||
            tO?.SupervisorName?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            ) ||
            tO?.DepartmentName?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            ) ||
            tO?.RequestStatus?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            ) ||
            tO?.LeaveType?.Name?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            )
        );
      }
      if (selectedNameFilters.length > 0) {
        filteredItems = filteredItems.filter((tO) =>
          selectedNameFilters.includes(tO?.EmployeeName)
        );
      }
      if (selectedSupervisorFilters.length > 0) {
        filteredItems = filteredItems.filter((tO) =>
          selectedSupervisorFilters.includes(tO?.SupervisorName)
        );
      }
      if (selectedTimeOffTypeFilters.length > 0) {
        filteredItems = filteredItems.filter(
          (tO) =>
            tO?.LeaveType?.Name &&
            selectedTimeOffTypeFilters.includes(tO?.LeaveType?.Name)
        );
      }
      if (selectedTimeOffStatusFilters.length > 0) {
        filteredItems = filteredItems.filter((tO) => {
          let requestStatus =
            tO?.RequestStatus[0].toUpperCase() + tO?.RequestStatus.slice(1);
          return selectedTimeOffStatusFilters.includes(requestStatus);
        });
      }
      if (dateRangeFilterDate.length === 2) {
        if (dateRangeFilterDate[0] && dateRangeFilterDate[1]) {
          const startDate = moment(dateRangeFilterDate[0]);
          const endDate = moment(dateRangeFilterDate[1]);
          filteredItems = filteredItems.filter((tO) => {
            return (
              moment(tO?.StartDate).isSameOrAfter(startDate) &&
              moment(tO?.EndDate).isSameOrBefore(endDate)
            );
          });
        }
      }
      setItems(filteredItems);
      setCurrentPage(1);
    }
  }, [searchField, isSelectingFilters, timeOffStore.timeOffs]);

  const getImageForAction = (status: string) => {
    switch (status) {
      case "approved":
        return `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/acceptedStatus.svg`;
      case "rejected":
        return `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/canceledStatus.svg`;
      default:
        return `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/pendingStatus.svg`;
    }
  };

  const overlapsOpenModal = (timeOffId: number) => {
    setOverlapsModalOpen(true);
    setSelectedOverlapsId(timeOffId);
  };

  const overlapsCloseModal = () => {
    setOverlapsModalOpen(false);
  };

  const addEmpolyeeOpenModal = () => {
    setAddEmployeeModalOpen(true);
  };

  const addEmployeeCloseModal = () => {
    setAddEmployeeModalOpen(false);
  };

  const editEmployeeOpenModal = (timeOffId: number) => {
    setEditEmployeeModalOpen(true);
    setSelectedEditTimeOff(timeOffId);
  };

  const editEmployeeCloseModal = () => {
    setEditEmployeeModalOpen(false);
    setSelectedEditTimeOff(null);
  };

  const onRefresh = async () => {
    setLoading(true);
    try {
      const result = await timeOffService.getTimeOffsData(
        initialSetupOrganizationStore?.hrSystem ?? 1,
        initialSetupOrganizationStore?.hrSystem === 2 ? true : false
      );
      timeOffStore.setUserBalance(result?.userBalance);
      timeOffStore.setTimeOffs(result?.timeOffs);
      timeOffStore.setTimeOffTypes(result?.timeOffTypes);
      homeStore.setOwnChartData({
        AnnualDays: result?.annualDays,
        TakenDays: result?.balanceDays,
      });
      homeStore.setTeamChartData({
        AnnualDays: result?.teamAnnualDays,
        TakenDays: result?.teamBalanceDays,
      });
      toast.success(t("absencesOwnHr.table.success"), {
        position: "bottom-right",
      });
    } catch (e) {
      toast.error(t("absencesOwnHr.table.refreshGeneralError"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const {
    getRows,
    columnSizing_unstable,
    tableRef,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: false,
      }),
      useTableSort({
        defaultSortState: {
          sortColumn: "UpdatedDate",
          sortDirection: "descending",
        },
      }),
    ]
  );

  const currentPageRows = sort(
    getRows((row) => {
      return {
        ...row,
      };
    })
  );
  const getCurrentPageRows = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return currentPageRows.slice(startIndex, endIndex);
  };

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const deleteAbsence = async () => {
    try {
      if (deleteAbsenceId) {
        const result = await timeOffService.deleteTimeOff(
          deleteAbsenceId,
          initialSetupOrganizationStore?.hrSystem ?? 1
        );
        if ("status" in result && result?.status === "error") {
          toast.error(t(`absencesOwnHr.dialog.errors.${result?.message}`), {
            position: "bottom-right",
            progressStyle: { color: "rgb(196, 49, 75)" },
          });
        } else {
          timeOffStore.setUserBalance(result?.userBalance);
          timeOffStore.deleteTimeOff(deleteAbsenceId);
          toast.success(t("absencesOwnHr.table.success"), {
            position: "bottom-right",
          });
        }
      }
    } catch (e) {
      toast.error(t("absencesOwnHr.dialog.errors.delete"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setDeleteAbsenceId(null);
      setIsDeleteDialogOpen(false);
    }
  };

  return (
    <>
      <div className="infoContainer">
        <span className="title">{t("absencesOwnHr.table.absenceTable")}</span>
        <div className="detailsContainer">
          <span className="details">
            {t("absencesOwnHr.table.description")}
          </span>

          <div className="absenceTableActionButtons">
            <div className="refreshContainer">
              {loading && <Spinner size="extra-tiny" />}
              {initialSetupOrganizationStore.hrSystem === 2 ? (
                <Tooltip
                  content={t("personio.absences.table.syncRefresh")}
                  withArrow
                  positioning="below"
                  appearance="inverted"
                  relationship={"label"}
                >
                  <button className="refreshButton" onClick={onRefresh}>
                    {t("absencesOwnHr.table.refresh")}
                  </button>
                </Tooltip>
              ) : (
                <button className="refreshButton" onClick={onRefresh}>
                  {t("absencesOwnHr.table.refresh")}
                </button>
              )}
            </div>
            {helpers.checkHasPermissions(
              userStore?.user?.Permissions,
              "Absences",
              "Can Request"
            ) ? (
              <button className="addButton" onClick={addEmpolyeeOpenModal}>
                <Image
                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/add.svg`}
                  alt="add"
                />
                {t("absencesOwnHr.table.add")}
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner style={{ margin: 100 }} />
      ) : (
        <>
          <div className="absenceTableContainer">
            <Table
              ref={tableRef}
              sortable
              {...columnSizing_unstable.getTableProps()}
              noNativeElements={true}
              className="absenceTable"
            >
              <TableHeader className="absenceTableHeaderRow">
                <TableRow>
                  {columns.map((column) => (
                    <Menu openOnContext key={column.columnId}>
                      <MenuTrigger>
                        <TableHeaderCell
                          key={column.columnId}
                          {...headerSortProps(column.columnId)}
                          {...columnSizing_unstable.getTableHeaderCellProps(
                            column.columnId
                          )}
                        >
                          {column.renderHeaderCell()}
                        </TableHeaderCell>
                      </MenuTrigger>
                      <MenuPopover>
                        <MenuList>
                          <MenuItem
                            onClick={columnSizing_unstable.enableKeyboardMode(
                              column.columnId
                            )}
                          >
                            Keyboard Column Resizing
                          </MenuItem>
                        </MenuList>
                      </MenuPopover>
                    </Menu>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {getCurrentPageRows().map(({ item }) => (
                  <TableRow key={item.Id}>
                    <Image
                      className="acceptedStatus"
                      src={getImageForAction(item.RequestStatus)}
                      alt="status"
                    />
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps(
                        "EmployeeName"
                      )}
                    >
                      <TableCellLayout
                        truncate
                        media={<Avatar name={item.EmployeeName} />}
                      >
                        <div className="absenceTableNameContainer">
                          <div className="absenceTableLabelSection">
                            {item.EmployeeName}
                          </div>
                          <div className="absenceTableDepartmentSection">
                            {item.DepartmentName}
                          </div>
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps(
                        "SupervisorName"
                      )}
                    >
                      <TableCellLayout
                        truncate
                        media={
                          item?.SupervisorName !== "Unassigned" ? (
                            <Avatar name={item.SupervisorName} />
                          ) : null
                        }
                      >
                        <div className="absenceTableLabelSection">
                          {item.SupervisorName}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("StartDate")}
                    >
                      <TableCellLayout truncate>
                        <div className="absenceTablePeriodContainer">
                          <div className="absenceTableLabelSection">
                            {`${moment
                              .utc(item.StartDate)
                              .format("DD/MM/YYYY")} - ${moment
                              .utc(item.EndDate)
                              .format("DD/MM/YYYY")}`}
                          </div>
                          <div className="absenceTablePeriodDaysContainer">
                            <span className="absenceTablePeriodDays">
                              {item?.DaysNumber}{" "}
                              {`${
                                item?.DaysNumber === 1
                                  ? t("absencesOwnHr.table.day")
                                  : t("absencesOwnHr.table.days")
                              }`}
                            </span>
                          </div>
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    {initialSetupOrganizationStore?.hrSystem === 2 ? (
                      <>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps(
                            "TimeOffHalfDayStart"
                          )}
                        >
                          <TableCellLayout truncate>
                            <div className="absenceTableLabelSection">
                              {item?.TimeOffHalfDayStart
                                ? t("personio.absences.table.secondPart")
                                : t("personio.absences.table.allDay")}
                            </div>
                          </TableCellLayout>
                        </TableCell>
                        <TableCell
                          {...columnSizing_unstable.getTableCellProps(
                            "TimeOffHalfDayEnd"
                          )}
                        >
                          <TableCellLayout truncate>
                            <div className="absenceTableLabelSection">
                              {item?.TimeOffHalfDayEnd
                                ? t("personio.absences.table.firstPart")
                                : t("personio.absences.table.allDay")}
                            </div>
                          </TableCellLayout>
                        </TableCell>
                      </>
                    ) : null}
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("LeaveType")}
                    >
                      <TableCellLayout truncate>
                        <div className="absenceTableLabelSection">
                          {item?.LeaveType?.Name}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps(
                        "RequestDate"
                      )}
                    >
                      <TableCellLayout truncate>
                        <div className="absenceTableLabelSection">
                          {`${
                            item.RequestDate
                              ? moment
                                  .utc(item.RequestDate)
                                  .format("DD/MM/YYYY")
                              : ""
                          }`}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps(
                        "UpdatedDate"
                      )}
                    >
                      <TableCellLayout truncate>
                        <div className="absenceTableLabelSection">
                          {`${
                            item.UpdatedDate
                              ? moment
                                  .utc(item.UpdatedDate)
                                  .format("DD/MM/YYYY")
                              : ""
                          }`}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("Overlaps")}
                    >
                      <TableCellLayout truncate>
                        <div className="absenceTableOverlapSection">
                          <div className="absenceTableLabelSection">
                            <span>{item?.Overlaps?.length}</span>
                          </div>
                          <Image
                            className="overlapIcon"
                            src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/info.svg`}
                            alt="info"
                            onClick={() => overlapsOpenModal(item.Id)}
                          />
                        </div>
                      </TableCellLayout>
                    </TableCell>
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("actions")}
                    >
                      <TableCellLayout className="absencesActionsCell">
                        <div className="absenceTableActionsSection">
                          {item?.RequestStatus === "pending" &&
                          userStore?.user?.EmployeeId !== item?.EmployeeId &&
                          helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Absences",
                            "Can Approve/Reject"
                          ) ? (
                            <>
                              <Tooltip
                                content="Check action"
                                withArrow
                                positioning="below"
                                appearance="inverted"
                                relationship={"label"}
                              >
                                <Image
                                  className="actionIcon"
                                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/emptyCheck.svg`}
                                  alt="emptyCheck"
                                  onClick={() => acceptRequest(item.Id)}
                                />
                              </Tooltip>
                              <Tooltip
                                content="Close action"
                                withArrow
                                positioning="below"
                                appearance="inverted"
                                relationship={"label"}
                              >
                                <Image
                                  className="actionIcon"
                                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/emptyClose.svg`}
                                  alt="emptyClose"
                                  onClick={() => denyRequest(item.Id)}
                                />
                              </Tooltip>
                            </>
                          ) : null}
                          {helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Absences",
                            "Can Edit"
                          ) ||
                          helpers.checkHasPermissions(
                            userStore?.user?.Permissions,
                            "Absences",
                            "Can Delete"
                          ) ? (
                            <Menu>
                              <MenuTrigger disableButtonEnhancement>
                                <Tooltip
                                  content="Menu action"
                                  withArrow
                                  positioning="below"
                                  appearance="inverted"
                                  relationship={"label"}
                                >
                                  <Image
                                    className="actionIcon"
                                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/horizontalMenu.svg`}
                                    alt="horizontalMenu"
                                  />
                                </Tooltip>
                              </MenuTrigger>

                              <MenuPopover>
                                <MenuList>
                                  {helpers.checkHasPermissions(
                                    userStore?.user?.Permissions,
                                    "Absences",
                                    "Can Edit"
                                  ) ? (
                                    <MenuItem
                                      onClick={() =>
                                        editEmployeeOpenModal(item?.Id)
                                      }
                                    >
                                      <span>
                                        {t("absencesOwnHr.table.edit")}
                                      </span>
                                    </MenuItem>
                                  ) : null}
                                  {helpers.checkHasPermissions(
                                    userStore?.user?.Permissions,
                                    "Absences",
                                    "Can Delete"
                                  ) ? (
                                    <MenuItem
                                      onClick={() => {
                                        setIsDeleteDialogOpen(true);
                                        setDeleteAbsenceId(item?.Id);
                                      }}
                                    >
                                      <span>
                                        {t("absencesOwnHr.table.delete")}
                                      </span>
                                    </MenuItem>
                                  ) : null}
                                </MenuList>
                              </MenuPopover>
                            </Menu>
                          ) : null}
                        </div>
                      </TableCellLayout>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* Overlaps Modal */}
            <OverlapsModal
              isModalOpen={overlapsModalOpen}
              closeModal={overlapsCloseModal}
              timeOffId={selectedOverlapsId}
              getImageForAction={getImageForAction}
            />

            {/* Add Employee Absence Modal */}
            {initialSetupOrganizationStore.hrSystem === 2 ? (
              <AddEmployeePersonioAbsence
                isModalOpen={
                  selectedEditTimeOff
                    ? editEmployeeModalOpen
                    : addEmployeeModalOpen
                }
                closeModal={
                  selectedEditTimeOff
                    ? editEmployeeCloseModal
                    : addEmployeeCloseModal
                }
                timeOffId={selectedEditTimeOff}
              />
            ) : (
              <AddEmployeeAbsence
                isModalOpen={
                  selectedEditTimeOff
                    ? editEmployeeModalOpen
                    : addEmployeeModalOpen
                }
                closeModal={
                  selectedEditTimeOff
                    ? editEmployeeCloseModal
                    : addEmployeeCloseModal
                }
                timeOffId={selectedEditTimeOff}
              />
            )}

            <DeleteDialog
              open={isDeleteDialogOpen}
              closeDialog={() => setIsDeleteDialogOpen(false)}
              deleteF={deleteAbsence}
            />
          </div>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </>
  );
};
