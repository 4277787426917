import { useStore } from "zustand";
import { useQuery } from "@tanstack/react-query";
import AttendanceLogHours from "../components/AttendanceOwnHr/AttendanceComponents/AttendanceLogHours";
import AttendanceLogHoursPersonio from "../components/AttendanceOwnHr/AttendanceComponents/AttendanceLogHoursPersonio";
import AttendancesTable from "../components/AttendanceOwnHr/AttendanceComponents/AttendancesTable/AttendancesTable";
import { useAttendancesStore } from "../store/attendancesStore";
import attendanceService from "../services/attendance";
import Error from "../components/Error";
import { useTranslation } from "react-i18next";
import FullScreenSpinner from "../components/FullScreenSpinner";
import "./Attendance.css";
import TimerComponent from "../components/AttendanceOwnHr/AttendanceComponents/TimerComponent";
import { useInitialSetupOrganizationStore } from "../store/initialSetupOrganizationStore";

export default function Attendance() {
  const { t } = useTranslation();
  const attendancesStore = useStore(useAttendancesStore);
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  const getAttendancesData = async () => {
    const result = await attendanceService.getAttendancesData(
      initialSetupOrganizationStore?.hrSystem ?? 1,
      false
    );
    attendancesStore.setAttendances(result.attendances);
    attendancesStore.setUsers(result.users);
    if (initialSetupOrganizationStore?.hrSystem === 2) {
      attendancesStore.setProjects(result?.projects);
    }
    return result;
  };

  const { isPending, error, isFetching } = useQuery({
    queryKey: ["attendancesData"],
    queryFn: getAttendancesData,
  });

  if (isFetching || isPending) return <FullScreenSpinner />;
  if (error)
    return <Error message={t("attendanceOwnHr.errors.generalError")} />;

  return (
    <div className="attendancePageContainer">
      {initialSetupOrganizationStore?.hrSystem === 2 ? (
        <AttendanceLogHoursPersonio />
      ) : (
        <>
          <TimerComponent />
          <AttendanceLogHours />
        </>
      )}
      <AttendancesTable />
    </div>
  );
}
