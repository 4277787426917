import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableHeader,
  TableHeaderCell,
  TableRow,
  createTableColumn,
  useTableColumnSizing_unstable,
  useTableFeatures,
  Avatar,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
  useTableSort,
  TableColumnId,
  Tooltip,
  Image,
} from "@fluentui/react-components";
import { useQuery } from "@tanstack/react-query";
import "./SummarizedAttendanceTable.css";
import Pagination from "../../../InitialSetupWizard/Pagination";
import { t } from "i18next";
import { AttendanceSummarized } from "../../../../types";
import attendanceService from "../../../../services/attendance";
import Error from "../../../Error";
import { useStore } from "zustand";
import { useAttendancesStore } from "../../../../store/attendancesStore";
import { toast } from "react-toastify";
import { useSearchField } from "../../../../contexts/SearchContext";
import { useFiltersContext } from "../../../../contexts/FiltersContext";
import moment, { Moment } from "moment";
import SummarizedAttendances from "../Modals/SummarizedAttendances";
import { DeleteDialog } from "./DeleteDialog";
import AddEmployeeAttendance from "../Modals/AddEmployeeAttendance";
import AddEmployeeAttendancePersonio from "../Modals/AddEmployeeAttendancePersonio";
import helpers from "../../../../helpers/helpers";
import { useUserStore } from "../../../../store/userStore";
import { useInitialSetupOrganizationStore } from "../../../../store/initialSetupOrganizationStore";

const columnsDef: TableColumnDefinition<AttendanceSummarized>[] = [
  createTableColumn<AttendanceSummarized>({
    columnId: "EmployeeName",
    compare: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
    renderHeaderCell: () => (
      <>{t("attendanceOwnHr.summarizedAttendanceTable.name")}</>
    ),
  }),
  createTableColumn<AttendanceSummarized>({
    columnId: "SupervisorName",
    compare: (a, b) => a.SupervisorName.localeCompare(b.SupervisorName),
    renderHeaderCell: () => (
      <>{t("attendanceOwnHr.summarizedAttendanceTable.managerName")}</>
    ),
  }),
  createTableColumn<AttendanceSummarized>({
    columnId: "Date",
    compare: (a, b) => (a.Date.isBefore(b.Date) ? 1 : -1),
    renderHeaderCell: () => (
      <>{t("attendanceOwnHr.summarizedAttendanceTable.date")}</>
    ),
  }),

  createTableColumn<AttendanceSummarized>({
    columnId: "WorkTime",
    compare: (a, b) => (a.WorkTime >= b.WorkTime ? 1 : -1),
    renderHeaderCell: () => (
      <>{t("attendanceOwnHr.summarizedAttendanceTable.totalWorkTime")}</>
    ),
  }),
  createTableColumn<AttendanceSummarized>({
    columnId: "BreakTime",
    compare: (a, b) => (a.BreakTime >= b.BreakTime ? 1 : 1),
    renderHeaderCell: () => (
      <>{t("attendanceOwnHr.summarizedAttendanceTable.totalBreakTime")}</>
    ),
  }),
  createTableColumn<AttendanceSummarized>({
    columnId: "actions",
    renderHeaderCell: () => <></>,
  }),
];

const columnSizingOptions = {
  EmployeeName: {
    minWidth: 243,
    defaultWidth: 243,
  },
  SupervisorName: {
    minWidth: 243,
    defaultWidth: 243,
  },
  Date: {
    minWidth: 150,
    defaultWidth: 150,
  },
  WorkTime: {
    minWidth: 150,
    defaultWidth: 150,
  },
  BreakTime: {
    minWidth: 150,
    defaultWidth: 150,
  },
  Location: {
    minWidth: 150,
    defaultWidth: 150,
  },
  actions: {
    minWidth: 44,
    defaultWidth: 44,
  },
};

export const SummarizedAttendanceTable = () => {
  const searchField = useSearchField();
  const [loading, setLoading] = useState(false);
  const [columns] =
    useState<TableColumnDefinition<AttendanceSummarized>[]>(columnsDef);
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const attendancesStore = useStore(useAttendancesStore);
  const [items, setItems] = useState<AttendanceSummarized[]>([]);
  const userStore = useStore(useUserStore);
  const totalPages = Math.ceil(items.length / itemsPerPage);
  const {
    isSelectingFilters,
    selectedNameFilters,
    selectedSupervisorFilters,
    selectedDepartmentFilters,
    selectedLocationFilters,
    dateRangeFilterDate,
  } = useFiltersContext();
  const [selectedSummarizedAttendance, setSelectedSummarizedAttendance] =
    useState<AttendanceSummarized | null>(null);
  const [isSummarizedModalOpen, setIsSummarizedModalOpen] =
    useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [selectedAttendanceId, setSelectedAttendanceId] = useState<
    number | null
  >(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteSumDialogOpen, setIsDeleteSumDialogOpen] =
    useState<boolean>(false);
  const [selectedSumDateToDelete, setSelectedSumDateToDelete] =
    useState<Moment | null>(null);
  const [selectedEmployeeIdToDelete, setSelectedEmployeeIdToDelete] = useState<
    number | null
  >(null);
  const [selectedLocationToDelete, setSelectedLocationToDelete] = useState<
    string | null
  >(null);
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  if (initialSetupOrganizationStore.hrSystem !== 2) {
    const columnId = "Location";
    const columnExists = columnsDef.some((col) => col.columnId === columnId);
    if (!columnExists) {
      columnsDef.splice(
        5,
        0,
        createTableColumn<AttendanceSummarized>({
          columnId: columnId,
          compare: (a, b) => a.Location.localeCompare(b.Location),
          renderHeaderCell: () => (
            <>{t("attendanceOwnHr.summarizedAttendanceTable.location")}</>
          ),
        })
      );
    }
  }

  useEffect(() => {
    if (!isSelectingFilters) {
      let filteredItems = attendancesStore.summarizedAttendances;

      if (searchField !== "") {
        filteredItems = filteredItems.filter(
          (att) =>
            att?.EmployeeName?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            ) ||
            att?.SupervisorName?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            ) ||
            att?.DepartmentName?.toLowerCase()?.includes(
              searchField?.toLowerCase()
            ) ||
            att?.Location?.toLowerCase()?.includes(searchField?.toLowerCase())
        );
      }
      if (selectedNameFilters.length > 0) {
        filteredItems = filteredItems.filter((att) =>
          selectedNameFilters.includes(att?.EmployeeName)
        );
      }
      if (selectedSupervisorFilters.length > 0) {
        filteredItems = filteredItems.filter((att) =>
          selectedSupervisorFilters.includes(att?.SupervisorName)
        );
      }
      if (selectedDepartmentFilters.length > 0) {
        filteredItems = filteredItems.filter((att) =>
          selectedDepartmentFilters.includes(att?.DepartmentName)
        );
      }
      if (selectedLocationFilters.length > 0) {
        filteredItems = filteredItems.filter((att) =>
          selectedLocationFilters.includes(att?.Location)
        );
      }
      if (dateRangeFilterDate.length === 2) {
        if (dateRangeFilterDate[0] && dateRangeFilterDate[1]) {
          const startDate = moment(dateRangeFilterDate[0]);
          const endDate = moment(dateRangeFilterDate[1]);
          filteredItems = filteredItems.filter((att) => {
            return (
              att?.Date.isSameOrAfter(startDate) &&
              att?.Date.isSameOrBefore(endDate)
            );
          });
        }
      }
      setItems(filteredItems);
      setCurrentPage(1);
    }
  }, [searchField, isSelectingFilters, attendancesStore.summarizedAttendances]);

  const getSummarizedAttendances = async () => {
    const result = await attendanceService.getSummarizedAttendances(
      initialSetupOrganizationStore?.hrSystem ?? 1
    );
    attendancesStore.setSummarizedAttendances(result?.attendances);
    return result;
  };

  const onRefresh = async () => {
    setLoading(true);
    try {
      const result = await attendanceService.getSummarizedAttendances(
        initialSetupOrganizationStore?.hrSystem ?? 1
      );
      attendancesStore.setSummarizedAttendances(result?.attendances);
    } catch (e) {
      toast.error(t("attendanceOwnHr.detailedAttendanceTable.errors.refresh"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };
  const {
    getRows,
    columnSizing_unstable,
    tableRef,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: false,
      }),
      useTableSort({
        defaultSortState: { sortColumn: "file", sortDirection: "ascending" },
      }),
    ]
  );

  const currentPageRows = sort(
    getRows((row) => {
      return {
        ...row,
      };
    })
  );
  const getCurrentPageRows = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return currentPageRows.slice(startIndex, endIndex);
  };

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const deleteAttendance = async () => {
    try {
      if (selectedAttendanceId) {
        const result = await attendanceService.deleteAttendance(
          selectedAttendanceId,
          initialSetupOrganizationStore?.hrSystem ?? 1
        );
        if ("status" in result && result?.status === "success") {
          attendancesStore.deleteAttendanceById(selectedAttendanceId);
          toast.success(t("attendanceOwnHr.detailedAttendanceTable.success"), {
            position: "bottom-right",
          });
        } else if ("status" in result && result?.status === "error") {
          toast.error(
            t(
              `attendanceOwnHr.detailedAttendanceTable.errors.${result?.message}`
            ),
            {
              position: "bottom-right",
              progressStyle: { color: "rgb(196, 49, 75)" },
            }
          );
        }
      }
    } catch (e) {
      toast.error(t("attendanceOwnHr.detailedAttendanceTable.errors.delete"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSelectedAttendanceId(null);
      setIsDeleteDialogOpen(false);
    }
  };

  const deleteSumAttendances = async () => {
    try {
      if (
        selectedSumDateToDelete &&
        selectedEmployeeIdToDelete &&
        (initialSetupOrganizationStore.hrSystem === 2 ||
          selectedLocationToDelete)
      ) {
        const result = await attendanceService.deleteSumAttendances(
          selectedSumDateToDelete.format("DD-MM-YYYY"),
          selectedEmployeeIdToDelete,
          selectedLocationToDelete ?? "office",
          initialSetupOrganizationStore?.hrSystem ?? 1
        );
        if ("status" in result && result?.status === "success") {
          attendancesStore.deleteAttendancesSummarized(
            selectedSumDateToDelete,
            selectedEmployeeIdToDelete,
            selectedLocationToDelete ?? "office",
            initialSetupOrganizationStore.hrSystem ?? 1
          );
          toast.success(t("attendanceOwnHr.detailedAttendanceTable.success"), {
            position: "bottom-right",
          });
        } else if ("status" in result && result?.status === "error") {
          toast.error(
            t(
              `attendanceOwnHr.detailedAttendanceTable.errors.${result?.message}`
            ),
            {
              position: "bottom-right",
              progressStyle: { color: "rgb(196, 49, 75)" },
            }
          );
        }
      }
    } catch (e) {
      toast.error(t("attendanceOwnHr.detailedAttendanceTable.errors.delete"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    } finally {
      setSelectedSumDateToDelete(null);
      setSelectedEmployeeIdToDelete(null);
      setSelectedLocationToDelete(null);
      setIsDeleteSumDialogOpen(false);
    }
  };

  const { isPending, error, isFetching } = useQuery({
    queryKey: ["summarizedAttendances"],
    queryFn: getSummarizedAttendances,
  });
  if (isFetching || isPending) return <Spinner style={{ margin: 100 }} />;
  if (error)
    return <Error message={t("attendanceOwnHr.errors.summarizedError")} />;

  return (
    <>
      <div className="detailedAttendanceTableActionButtons">
        <div className="refreshContainer">
          {loading && <Spinner size="extra-tiny" />}
          <button className="refreshButton" onClick={onRefresh}>
            {t("attendanceOwnHr.summarizedAttendanceTable.refresh")}
          </button>
        </div>
      </div>
      <div className="detailedAttendanceTableContainer">
        {loading ? (
          <Spinner style={{ margin: 100 }} />
        ) : (
          <Table
            ref={tableRef}
            sortable
            {...columnSizing_unstable.getTableProps()}
            noNativeElements={true}
            className="detailedAttendanceTable"
          >
            <TableHeader className="detailedAttendanceHeaderRow">
              <TableRow>
                {columns.map((column, index) => (
                  <Menu openOnContext key={index}>
                    <MenuTrigger>
                      <TableHeaderCell
                        key={index}
                        {...headerSortProps(column.columnId)}
                        {...columnSizing_unstable.getTableHeaderCellProps(
                          column.columnId
                        )}
                      >
                        {column.renderHeaderCell()}
                      </TableHeaderCell>
                    </MenuTrigger>
                    <MenuPopover>
                      <MenuList>
                        <MenuItem
                          onClick={columnSizing_unstable.enableKeyboardMode(
                            column.columnId
                          )}
                        >
                          Keyboard Column Resizing
                        </MenuItem>
                      </MenuList>
                    </MenuPopover>
                  </Menu>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {getCurrentPageRows().map(({ item }, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("EmployeeName")}
                  >
                    <TableCellLayout
                      truncate
                      media={<Avatar name={item.EmployeeName} />}
                    >
                      <div className="detailedAttendanceNameContainer">
                        <div className="detailedAttendanceLabelSection">
                          {item.EmployeeName}
                        </div>
                        <div className="detailedAttendanceDepartmentSection">
                          {item.DepartmentName}
                        </div>
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps(
                      "SupervisorName"
                    )}
                  >
                    <TableCellLayout
                      truncate
                      media={
                        item?.SupervisorName !== "Unassigned" ? (
                          <Avatar name={item.SupervisorName} />
                        ) : null
                      }
                    >
                      <div className="detailedAttendanceLabelSection">
                        {item.SupervisorName}
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("Date")}
                  >
                    <TableCellLayout truncate>
                      <div className="detailedAttendancePeriodContainer">
                        <div className="detailedAttendanceLabelSection">
                          {item.Date.format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </TableCellLayout>
                  </TableCell>

                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("WorkTime")}
                  >
                    <TableCellLayout truncate>
                      <div className="detailedAttendanceLabelSection">
                        {item.WorkTime}
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("BreakTime")}
                  >
                    <TableCellLayout truncate>
                      <div className="detailedAttendanceLabelSection">
                        {item.BreakTime}
                      </div>
                    </TableCellLayout>
                  </TableCell>
                  {initialSetupOrganizationStore?.hrSystem !== 2 ? (
                    <TableCell
                      {...columnSizing_unstable.getTableCellProps("Location")}
                    >
                      <TableCellLayout truncate>
                        <div className="detailedAttendanceLabelSection">
                          <span>{item.Location}</span>
                        </div>
                      </TableCellLayout>
                    </TableCell>
                  ) : null}
                  <TableCell
                    {...columnSizing_unstable.getTableCellProps("actions")}
                  >
                    <TableCellLayout className="detailedAttendanceActionsCell">
                      <div className="detailedAttendanceActionsSection">
                        <Menu>
                          <MenuTrigger disableButtonEnhancement>
                            <Tooltip
                              content="Menu action"
                              withArrow
                              positioning="below"
                              appearance="inverted"
                              relationship="label"
                            >
                              <Image
                                className="actionIcon"
                                src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/horizontalMenu.svg`}
                                alt="horizontalMenu"
                              />
                            </Tooltip>
                          </MenuTrigger>
                          <MenuPopover>
                            <MenuList>
                              {helpers.checkHasPermissions(
                                userStore?.user?.Permissions,
                                "Attendances",
                                "Can Edit"
                              ) ? (
                                <MenuItem
                                  onClick={() => {
                                    setIsSummarizedModalOpen(true);
                                    setSelectedSummarizedAttendance(item);
                                  }}
                                >
                                  <span>
                                    {t(
                                      "attendanceOwnHr.detailedAttendanceTable.edit"
                                    )}
                                  </span>
                                </MenuItem>
                              ) : null}
                              {helpers.checkHasPermissions(
                                userStore?.user?.Permissions,
                                "Attendances",
                                "Can Delete"
                              ) ? (
                                <MenuItem
                                  onClick={() => {
                                    setIsDeleteSumDialogOpen(true);
                                    setSelectedSumDateToDelete(item.Date);
                                    setSelectedEmployeeIdToDelete(
                                      item.EmployeeId
                                    );
                                    setSelectedLocationToDelete(item.Location);
                                  }}
                                >
                                  <span>
                                    {t(
                                      "attendanceOwnHr.detailedAttendanceTable.delete"
                                    )}
                                  </span>
                                </MenuItem>
                              ) : null}
                            </MenuList>
                          </MenuPopover>
                        </Menu>
                      </div>
                    </TableCellLayout>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <SummarizedAttendances
          isModalOpen={isSummarizedModalOpen}
          closeModal={() => {
            setIsSummarizedModalOpen(false);
            setSelectedSummarizedAttendance(null);
            setSelectedAttendanceId(null);
          }}
          selectedSummarizedAttendance={selectedSummarizedAttendance}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          setSelectedAttendanceId={setSelectedAttendanceId}
          setIsEditModalOpen={setIsEditModalOpen}
        />
        {initialSetupOrganizationStore?.hrSystem === 2 ? (
          <AddEmployeeAttendancePersonio
            isModalOpen={isEditModalOpen}
            closeModal={() => {
              setIsEditModalOpen(false);
              setSelectedAttendanceId(null);
            }}
            attendanceId={selectedAttendanceId}
            refreshSumarized={onRefresh}
          />
        ) : (
          <AddEmployeeAttendance
            isModalOpen={isEditModalOpen}
            closeModal={() => {
              setIsEditModalOpen(false);
              setSelectedAttendanceId(null);
            }}
            attendanceId={selectedAttendanceId}
            refreshSumarized={onRefresh}
          />
        )}

        <DeleteDialog
          open={isDeleteSumDialogOpen}
          closeDialog={() => {
            setIsDeleteSumDialogOpen(false);
            setSelectedSumDateToDelete(null);
            setSelectedEmployeeIdToDelete(null);
            setSelectedLocationToDelete(null);
          }}
          deleteF={deleteSumAttendances}
          question={t(
            "attendanceOwnHr.detailedAttendanceTable.dialog.areYouSureYouWantToDeleteTheseUsers"
          )}
          description={t(
            "attendanceOwnHr.detailedAttendanceTable.dialog.theDataAssociatedWithTheseUsersWillBeRemoved"
          )}
        />
        <DeleteDialog
          open={isDeleteDialogOpen}
          closeDialog={() => {
            setIsDeleteDialogOpen(false);
            setSelectedAttendanceId(null);
          }}
          deleteF={deleteAttendance}
          question={t(
            "attendanceOwnHr.detailedAttendanceTable.dialog.areYouSureYouWantToDeleteTheseUsers"
          )}
          description={t(
            "attendanceOwnHr.detailedAttendanceTable.dialog.theDataAssociatedWithTheseUsersWillBeRemoved"
          )}
        />
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};
