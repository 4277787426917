import { useStore } from "zustand";
import { EmployeesTable } from "../components/EmployeesManagementOwnHr/EmployeesTable";
import { useEmployeeStore } from "../store/ownEmployeeManagementStore";
import employeeManagementService from "../services/employeesManagement";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "@fluentui/react-components";
import Error from "../components/Error";
import { useTranslation } from "react-i18next";
import { useInitialSetupOrganizationStore } from "../store/initialSetupOrganizationStore";

export default function EmployeesManagement() {
  const { t } = useTranslation();
  const employeeManagementStore = useStore(useEmployeeStore);
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  const getEmployeeManagementData = async () => {
    const result = await employeeManagementService.getEmployeesManagementData(
      initialSetupOrganizationStore?.hrSystem ?? 1,
      false
    );
    employeeManagementStore.setManagers(result?.managers);
    employeeManagementStore.setEmployees(result?.employees);
    employeeManagementStore.setTimeOffTypes(result?.timeOffTypes);
    employeeManagementStore.setOffices(result?.offices);
    employeeManagementStore.setTeams(result?.teams);
    return result;
  };

  const { isPending, error, isFetching } = useQuery({
    queryKey: ["employeeManagementData"],
    queryFn: getEmployeeManagementData,
  });

  if (isFetching || isPending) return <Spinner style={{ margin: 100 }} />;
  if (error)
    return (
      <Error message={t("employeesManagementOwnHr.errors.generalError")} />
    );

  return (
    <div className="employeesManagementPageContainer">
      <EmployeesTable />
    </div>
  );
}
