import Modal from "./Modal";
import { HomeModal, TimeOff } from "../types";
import { useTranslation } from "react-i18next";
import helpers from "../helpers/helpers";
import { useStore } from "zustand";
import { useUserStore } from "../store/userStore";
import { useState, useEffect } from "react";
import { useHomeStore } from "../store/homeStore";
import { toast } from "react-toastify";
import {
  DataVizPalette,
  GaugeChart,
  GaugeValueFormat,
  GaugeChartVariant,
} from "@fluentui/react-charting";
import {
  Image,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableRow,
  Tooltip,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useTableColumnSizing_unstable,
  useTableFeatures,
  useTableSort,
  TableColumnDefinition,
  createTableColumn,
} from "@fluentui/react-components";
import { Link } from "react-router-dom";
import { useTimeOffsStore } from "../store/timeOffsStore";
import moment from "moment";
import timeOffService from "../services/timeOff";
import AddEmployeeAbsence from "./AbsencesOwnHr/AbsencesComponents/Modals/AddEmployeeAbsence";
import { useInitialSetupOrganizationStore } from "../store/initialSetupOrganizationStore";

const columnSizingOptions = {
  EmployeeName: {
    minWidth: 230,
    defaultWidth: 230,
  },
  StartDate: {
    minWidth: 240,
    defaultWidth: 240,
  },
  actions: {
    minWidth: 92,
    defaultWidth: 92,
  },
};

const columnsDef: TableColumnDefinition<TimeOff>[] = [
  createTableColumn<TimeOff>({
    columnId: "EmployeeName",
    compare: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
    renderHeaderCell: () => <>Employee Name</>,
  }),
  createTableColumn<TimeOff>({
    columnId: "StartDate",
    compare: (a, b) => {
      return moment(a.StartDate).isAfter(b.StartDate) ? 1 : -1;
    },
    renderHeaderCell: () => <>Period</>,
  }),
  createTableColumn<TimeOff>({
    columnId: "actions",
    renderHeaderCell: () => <></>,
  }),
];

const HomePageModal: React.FC<HomeModal> = ({ isModalOpen, closeModal }) => {
  const { t } = useTranslation();
  const userStore = useStore(useUserStore);
  const [selected, setSelected] = useState<string>("own");
  const homeStore = useStore(useHomeStore);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [items, setItems] = useState<TimeOff[]>([]);
  const timeOffsStore = useStore(useTimeOffsStore);
  const [columns] = useState<TableColumnDefinition<TimeOff>[]>(columnsDef);
  const [editEmployeeModalOpen, setEditEmployeeModalOpen] =
    useState<boolean>(false);
  const [selectedEditTimeOff, setSelectedEditTimeOff] = useState<number | null>(
    null
  );
  const initialSetupOrganizationStore = useStore(
    useInitialSetupOrganizationStore
  );

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (selected === "team") {
      setItems(
        timeOffsStore?.timeOffs
          .filter((timeOff) => timeOff?.RequestStatus === "pending")
          .slice(0, 5)
      );
    } else {
      setItems(
        timeOffsStore?.timeOffs
          .filter(
            (timeOff) =>
              timeOff?.RequestStatus === "pending" &&
              timeOff?.EmployeeId === userStore?.user?.EmployeeId
          )
          .slice(0, 5)
      );
    }
  }, [timeOffsStore?.timeOffs, selected, userStore?.user?.EmployeeId]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (body) {
      if (editEmployeeModalOpen) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    }
  }, [editEmployeeModalOpen]);

  const { columnSizing_unstable, tableRef } = useTableFeatures(
    { columns, items },
    [
      useTableColumnSizing_unstable({
        columnSizingOptions,
        autoFitColumns: false,
      }),
      useTableSort({
        defaultSortState: {
          sortColumn: "UpdatedDate",
          sortDirection: "descending",
        },
      }),
    ]
  );

  const handleToggle = () => {
    if (selected === "own") {
      setSelected("team");
    } else {
      setSelected("own");
    }
  };

  const getImageForAction = (status: string) => {
    switch (status) {
      case "approved":
        return `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/acceptedStatus.svg`;
      case "rejected":
        return `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/canceledStatus.svg`;
      default:
        return `${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/pendingStatus.svg`;
    }
  };

  const acceptRequest = async (timeOffId: number) => {
    try {
      const result = await timeOffService.approveTimeOff(
        timeOffId,
        initialSetupOrganizationStore.hrSystem ?? 1
      );
      console.log("result", result);
      if (result?.status === "success") {
        timeOffsStore.setTimeOffStatus("approved", timeOffId);
        toast.success(t("absencesOwnHr.approve.success"), {
          position: "bottom-right",
        });
      } else if (result?.status === "error") {
        toast.error(t(`absencesOwnHr.errors.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      }
    } catch (e) {
      toast.error(t("absencesOwnHr.approve.generalError"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    }
  };

  const denyRequest = async (timeOffId: number) => {
    try {
      const result = await timeOffService.denyTimeOff(
        timeOffId,
        initialSetupOrganizationStore.hrSystem ?? 1
      );
      if (result?.status === "success") {
        timeOffsStore.setTimeOffStatus("rejected", timeOffId);
        homeStore.setOwnChartData({
          AnnualDays: result?.annualDays,
          TakenDays: result?.balanceDays,
        });
        homeStore.setTeamChartData({
          AnnualDays: result?.teamAnnualDays,
          TakenDays: result?.teamBalanceDays,
        });
        toast.success(t("absencesOwnHr.approve.success"), {
          position: "bottom-right",
        });
      } else if (result?.status === "error") {
        toast.error(t(`absencesOwnHr.errors.${result?.message}`), {
          position: "bottom-right",
          progressStyle: { color: "rgb(196, 49, 75)" },
        });
      }
    } catch (e) {
      toast.error(t("absencesOwnHr.deny.generalError"), {
        position: "bottom-right",
        progressStyle: { color: "rgb(196, 49, 75)" },
      });
    }
  };

  const editEmployeeOpenModal = (timeOffId: number) => {
    setEditEmployeeModalOpen(true);
    setSelectedEditTimeOff(timeOffId);
  };

  const editEmployeeCloseModal = () => {
    setEditEmployeeModalOpen(false);
    setSelectedEditTimeOff(null);
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title="Targo360"
        subtitle={t("home.statistics")}
        contentTitle=""
        headerStyle="addEmployeeModalHeader"
      >
        <div className="homeToggleContainer">
          <div>{t("home.own")}</div>
          <button
            disabled={
              !(
                helpers.checkHasPermissions(
                  userStore?.user?.Permissions,
                  "Absences",
                  "Can See All Data"
                ) ||
                helpers.checkHasPermissions(
                  userStore?.user?.Permissions,
                  "Absences",
                  "Can See Team Data"
                )
              )
            }
            className={`homeToggleBtn ${selected === "team" ? "toggled" : ""} ${
              !(
                helpers.checkHasPermissions(
                  userStore?.user?.Permissions,
                  "Absences",
                  "Can See All Data"
                ) ||
                helpers.checkHasPermissions(
                  userStore?.user?.Permissions,
                  "Absences",
                  "Can See Team Data"
                )
              )
                ? "unactive"
                : ""
            }`}
            onClick={handleToggle}
          >
            <div className="thumb"></div>
          </button>
          <div>{t("home.team")}</div>
        </div>
        <div className="homePageDetailsContainer">
          <div className="homePageChartContaier">
            <div className="homePageChartTitle">
              {t("home.totalAnnualLeave")}
            </div>
            <div className="homePageChartContent">
              {(selected === "own" &&
                (homeStore?.ownChartData?.AnnualDays !== 0 ||
                  homeStore?.ownChartData?.TakenDays !== 0)) ||
              (selected === "team" &&
                (homeStore?.teamChartData?.AnnualDays !== 0 ||
                  homeStore?.teamChartData?.TakenDays !== 0)) ? (
                <GaugeChart
                  width={screenWidth > 400 ? 335 : undefined}
                  height={246}
                  segments={[
                    {
                      size:
                        selected === "own"
                          ? homeStore?.ownChartData?.TakenDays ?? 0
                          : homeStore?.teamChartData?.TakenDays ?? 0,
                      color: "#3AB3DB",
                      legend: `${t("home.taken")}`,
                    },
                    {
                      size:
                        selected === "own"
                          ? (homeStore?.ownChartData?.AnnualDays ?? 0) -
                            (homeStore?.ownChartData?.TakenDays ?? 0)
                          : (homeStore?.teamChartData?.AnnualDays ?? 0) -
                            (homeStore?.teamChartData?.TakenDays ?? 0),
                      color: DataVizPalette.disabled,
                      legend: `${t("home.left")}`,
                    },
                  ]}
                  chartTitle={`${
                    selected === "own"
                      ? homeStore?.ownChartData?.TakenDays ?? 0
                      : homeStore?.teamChartData?.TakenDays ?? 0
                  } ${t("home.days")}`}
                  chartValue={
                    selected === "own"
                      ? homeStore?.ownChartData?.TakenDays ?? 0
                      : homeStore?.teamChartData?.TakenDays ?? 0
                  }
                  sublabel={t("home.balance")}
                  chartValueFormat={GaugeValueFormat.Percentage}
                  variant={GaugeChartVariant.SingleSegment}
                />
              ) : (
                <div className="homeTimeOffTableNoApproval">
                  {t("home.missingData")}
                  <br />
                  {t("home.missingData2")}
                </div>
              )}
            </div>
            {helpers.checkHasPermissions(
              userStore?.user?.Permissions,
              "Employees Management",
              "Can Read"
            ) ? (
              <Link
                to="/dashboard/employeesManagement"
                className="homePageChartButton"
              >
                <span>{t("home.gotoManagement")}</span>
                <Image
                  src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/home/arrow.svg`}
                />
              </Link>
            ) : null}
          </div>
          {helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Absences",
            "Can See Own Data"
          ) ||
          helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Absences",
            "Can See Team Data"
          ) ||
          helpers.checkHasPermissions(
            userStore?.user?.Permissions,
            "Absences",
            "Can See All Data"
          ) ? (
            <div className="homePageAbsencesContainer">
              <div className="homePageChartTitle">
                {t("home.absencesPendingApproval")}
              </div>
              <div className="homeTimeOffTableContainer">
                {items.length === 0 ? (
                  <div className="homeTimeOffTableNoApproval">
                    {t("home.missingApprovals")}
                  </div>
                ) : (
                  <>
                    <Table
                      ref={tableRef}
                      sortable
                      {...columnSizing_unstable.getTableProps()}
                      noNativeElements={true}
                      className="homeTimeOffTable"
                    >
                      <TableBody>
                        {items.map((item) => (
                          <TableRow key={item?.Id} className="homePageTableRow">
                            <Image
                              className="acceptedStatus"
                              src={getImageForAction(item.RequestStatus)}
                              alt="status"
                            />
                            <TableCell
                              {...columnSizing_unstable.getTableCellProps(
                                "EmployeeName"
                              )}
                            >
                              <TableCellLayout
                                truncate
                                media={<Avatar name={item.EmployeeName} />}
                              >
                                <div className="homeTimeOffNameContainer">
                                  <div className="homeTimeOffLabelSection">
                                    {item?.EmployeeName}
                                  </div>
                                  <div className="homeTimeOffDepartmentSection">
                                    {item.DepartmentName}
                                  </div>
                                </div>
                              </TableCellLayout>
                            </TableCell>
                            <TableCell
                              {...columnSizing_unstable.getTableCellProps(
                                "StartDate"
                              )}
                            >
                              <TableCellLayout truncate>
                                <div className="homeTimeOffPeriodSection">
                                  <div className="homeTimeOffLabelSection">{`${moment(
                                    item?.StartDate
                                  ).format("DD/MM/YYYY")} - ${moment(
                                    item?.EndDate
                                  ).format("DD/MM/YYYY")}`}</div>
                                  <div className="homeTimeOffDaysContainer">
                                    {item?.DaysNumber}{" "}
                                    {item?.DaysNumber === 1
                                      ? t("home.day")
                                      : t("home.days")}
                                  </div>
                                </div>
                              </TableCellLayout>
                            </TableCell>
                            <TableCell
                              {...columnSizing_unstable.getTableCellProps(
                                "actions"
                              )}
                            >
                              <TableCellLayout className="absencesActionsCell">
                                <div className="absenceTableActionsSection">
                                  {item?.RequestStatus === "pending" &&
                                  userStore?.user?.EmployeeId !==
                                    item?.EmployeeId &&
                                  helpers.checkHasPermissions(
                                    userStore?.user?.Permissions,
                                    "Absences",
                                    "Can Approve/Reject"
                                  ) ? (
                                    <>
                                      <Tooltip
                                        content="Check action"
                                        withArrow
                                        positioning="below"
                                        appearance="inverted"
                                        relationship={"label"}
                                      >
                                        <Image
                                          className="actionIcon"
                                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/emptyCheck.svg`}
                                          alt="emptyCheck"
                                          onClick={() => acceptRequest(item.Id)}
                                        />
                                      </Tooltip>
                                      <Tooltip
                                        content="Close action"
                                        withArrow
                                        positioning="below"
                                        appearance="inverted"
                                        relationship={"label"}
                                      >
                                        <Image
                                          className="actionIcon"
                                          src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/emptyClose.svg`}
                                          alt="emptyClose"
                                          onClick={() => denyRequest(item.Id)}
                                        />
                                      </Tooltip>
                                    </>
                                  ) : null}
                                  {helpers.checkHasPermissions(
                                    userStore?.user?.Permissions,
                                    "Absences",
                                    "Can Edit"
                                  ) ? (
                                    <Menu>
                                      <MenuTrigger disableButtonEnhancement>
                                        <Tooltip
                                          content="Menu action"
                                          withArrow
                                          positioning="below"
                                          appearance="inverted"
                                          relationship={"label"}
                                        >
                                          <Image
                                            className="actionIcon"
                                            src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/absencesOwnHr/horizontalMenu.svg`}
                                            alt="horizontalMenu"
                                          />
                                        </Tooltip>
                                      </MenuTrigger>

                                      <MenuPopover>
                                        <MenuList>
                                          <MenuItem
                                            onClick={() =>
                                              editEmployeeOpenModal(item?.Id)
                                            }
                                          >
                                            <span>
                                              {t("absencesOwnHr.table.edit")}
                                            </span>
                                          </MenuItem>
                                        </MenuList>
                                      </MenuPopover>
                                    </Menu>
                                  ) : null}
                                </div>
                              </TableCellLayout>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <AddEmployeeAbsence
                      isModalOpen={editEmployeeModalOpen}
                      closeModal={editEmployeeCloseModal}
                      timeOffId={selectedEditTimeOff}
                    />
                  </>
                )}
              </div>
              {helpers.checkHasPermissions(
                userStore?.user?.Permissions,
                "Absences",
                "Can Read"
              ) ? (
                <Link
                  to="/dashboard/absencesOwnHr"
                  className="homePageChartButton"
                >
                  <span>{t("home.viewMore")}</span>
                  <Image
                    src={`${process.env.REACT_APP_TARGO_TAB_ENDPOINT}/assets/images/home/arrow.svg`}
                  />
                </Link>
              ) : null}
            </div>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default HomePageModal;
