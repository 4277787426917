import {
  User,
  EmployeeManagement,
  TimeOffType,
  PersonioOffice,
  PersonioTeam,
} from "../types";
import httpService from "./http";

const getEmployeesManagementData = async (
  hrSystemId: number,
  isRefresh: boolean
) => {
  const response = await httpService.get(
    `employee-management/${hrSystemId}/${isRefresh}`
  );
  const timeOffTypes: TimeOffType[] = [];
  const offices: PersonioOffice[] = [];
  const teams: PersonioTeam[] = [];
  if ("offices" in response?.data) {
    response?.data?.offices?.forEach((off: any) =>
      offices.push({
        Id: off.OfficeId,
        Name: off.OfficeName,
        PersonioId: off.OfficePersonioId,
      })
    );
  }
  if ("teams" in response?.data) {
    response?.data?.teams?.forEach((tm: any) =>
      teams.push({
        Id: tm.TeamId,
        Name: tm.TeamName,
        PersonioId: tm.TeamPersonioId,
      })
    );
  }
  response?.data?.timeOffTypes.forEach((timeOffType: any) => {
    let tot: TimeOffType = {
      Id: timeOffType?.TimeOffTypeId,
      Name: timeOffType?.TimeOffTypeName,
    };
    if (hrSystemId === 2) {
      tot["TimeOffTypeCategory"] = timeOffType?.TimeOffTypeCategory;
      tot["TimeOffTypeApprovalRequired"] =
        timeOffType?.TimeOffTypeApprovalRequired;
      tot["TimeOffTypeCertificationRequired"] =
        timeOffType?.TimeOffTypeCertificationRequired;
      tot["TimeOffTypeCertificationSubmissionTimeFrame"] =
        timeOffType?.TimeOffTypeCertificationSubmissionTimeFrame;
      tot["TimeOffTypeHalfDayRequestsEnabled"] =
        timeOffType?.TimeOffTypeHalfDayRequestsEnabled;
      tot["TimeOffTypeLegacyCategory"] = timeOffType?.TimeOffTypeLegacyCategory;
      tot["TimeOffTypePersonioId"] = timeOffType?.TimeOffTypePersonioId;
      tot["TimeOffTypeSubstituteOption"] =
        timeOffType?.TimeOffTypeSubstituteOption;
      tot["TimeOffTypeUnit"] = timeOffType?.TimeOffTypeUnit;
    } else {
      tot["Description"] = timeOffType?.TimeOffTypeDescription;
    }
    timeOffTypes.push(tot);
  });
  const managers: User[] = response?.data?.managers?.map((manager: any) => ({
    Id: manager.UserId,
    EmployeeId:
      hrSystemId === 2
        ? manager?.PersonioEmployee?.EmployeeId
        : manager.Employee?.EmployeeId,
    FirstName: manager.FirstName,
    LastName: manager.LastName,
    Email: manager.Email,
    Role:
      manager.Roles?.length > 0
        ? {
            Id: manager.Roles[0].RoleId,
            RoleName: manager.Roles[0].RoleName,
          }
        : null,
  }));
  let employees: EmployeeManagement[] = [];
  if (hrSystemId === 2) {
    response?.data?.employees?.forEach((emp: any) => employees.push(emp));
  } else {
    response?.data?.employees?.forEach((emp: any) => employees.push(emp));
  }
  return { employees, managers, timeOffTypes, offices, teams };
};

const editEmployee = async (
  employeeId: number,
  annualDays: number,
  contractType: string,
  dailyHours: string,
  hireDate: string,
  managerUserId: number,
  employeeName: string,
  position: string,
  status: string,
  terminationDate: string,
  worksite: string,
  hrSystemId: number,
  teamId?: number,
  officeId?: number
) => {
  const response = await httpService.patch(
    `/employee-management/edit-employee/${hrSystemId}`,
    {
      employeeId,
      annualDays,
      contractType,
      dailyHours,
      hireDate,
      managerUserId,
      employeeName,
      position,
      status,
      terminationDate,
      worksite,
      teamId,
      officeId,
    }
  );
  return response.data;
};

const importCsv = async (csvData: any) => {
  const response = await httpService.post("/employee-management/import", {
    csvData,
  });
  return response.data;
};

const employeeManagementService = {
  getEmployeesManagementData,
  editEmployee,
  importCsv,
};
export default employeeManagementService;
